import {get_list_teams} from "api/zero-api";
import useDataLoader from "hooks/shared/useDataLoader";
import { joinTeamsAndMembers } from "other/Helper";
import { useCallback } from "react";


export function useTeamsLoader() {
    const postFetchAction = useCallback((data) => {
        return {teams: joinTeamsAndMembers(data)};
    }, []);

    return useDataLoader(get_list_teams, {postFetchAction});
}