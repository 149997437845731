import React from 'react';
import {connect} from 'react-redux'

import {mainContentContainerClass, isProduction, safe_get, isIE} from '../other/Helper';
import whale from '../assets/css/img/whale-img.png';

import {Result} from 'antd';

import Rollbar from "rollbar";
import { getErrorHandler } from 'other/errorReporting';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        let rollbar = getErrorHandler(this.props.user);

        this.state = {
            error: null,
            errorInfo: null,
            rollbar
        };
    }

    componentDidMount() {
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message

        if (!window.location.hostname.includes("localhost") && !isIE()) {
            this.state.rollbar.info(error);
        }

        this.setState({
            error: error,
            errorInfo: errorInfo
        });

    }

    render() {
        if (this.state.errorInfo) {
            return (
                // <div id="container" className={"error-screen"}>
                //   <div style={{textAlign: "center", paddingTop: "75px"}}>
                //     <h3 className="zero-blue error-screen-msg" style={{ margin: "0 auto"}}>Whale, this is awkward. An unexpected error occured. Click below to reload</h3>
                //     <button className="btn btn-primary" style={{marginTop: "8px"}} onClick={() => { window.location ="/"; }}>Reload</button>
                //   </div>
                // </div>
                <div id="content-container" className="container-with-header">
                    <div className={mainContentContainerClass()} style={{marginLeft: "0px"}}>
                        <div id="page-content" style={{paddingTop: "10px"}}>
                            <div className="panel bulletin-post-border">
                                <Result
                                    status="404"
                                    title="Error"
                                    subTitle="Sorry, something went wrong."
                                    extra={
                                        <button className="btn btn-primary" style={{
                                            marginTop: "8px",
                                            display: "block",
                                            margin: "auto",
                                            marginBottom: "10px"
                                        }} onClick={() => {
                                            window.location = "/";
                                        }}>Reload</button>
                                    }
                                />
                                {/* <img alt="whale error img" src={whale} style={{width: "350px", display: "block", margin: "auto", paddingTop: "10px"}}/>
                <h4 className="mar-btm zero-blue text-center">Whale, this is awkward. An unexpected error occured. Click below to reload.</h4> */}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", ""),
    }
}

export default connect(mapStateToProps)(ErrorBoundary);