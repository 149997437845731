// @ts-check
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import ZeroDataGrid from "components/Shared/ZeroDataGrid";
import { useZeroContext, useZeroEventListener } from "components/ZeroContext";
import { useCurrentOrganization } from "hooks/reduxHooks";
import useZeroSelector from "hooks/useZeroSelector";
import { isEqual } from "lodash-es";
import { objectIsEmpty } from "other/Helper";
import { generatePostQueryParams } from "other/posts";
import { useFeedDataGridContext } from "./FeedDataGrid.context";
import { EventNames, getColumnDefs, getEmbeddedForm, loadPosts } from "./FeedDataGrid.utils";
import { FeedDataGridModals } from "./FeedDataGrid.modals";
import { getFormFieldsForDataGrid } from "other/agGridHelper";

const POSTS_PER_PAGE = 30;

export default function FeedDataGrid() {
    const location = useLocation();
    const {gridRef, settings, setSettings, rowCount} = useFeedDataGridContext();
    const organization = useCurrentOrganization();
    const { kv } = useZeroContext();
    const feedQuery = useZeroSelector(state => state.feed_helper.query);
    const dateQuery = useZeroSelector(state => state.feed_helper.date_query);
    const feedView = useZeroSelector(state => state.feed_helper.feed_view);
    const [embeddedForm, setEmbeddedForm] = useState(undefined);

    useEffect(() => {
        const loadEmbeddedForm = async () => {
            const ef = await getEmbeddedForm(organization, kv);
            setEmbeddedForm(ef);
        }

        loadEmbeddedForm();
    }, [organization, kv]);

    const formId = embeddedForm?.form_uuid;
    const formFields = useMemo(() => getFormFieldsForDataGrid(embeddedForm), [embeddedForm]);
    const colDefs = useMemo(() => getColumnDefs(organization, formFields?.headers), [organization, formFields]);

    const getRows = useCallback(async (/** @type {AgGrid.IGetRowsParams} */params) => {
        const perPage = POSTS_PER_PAGE;
        const currentPage = Math.floor((params.startRow ?? 0) / perPage) + 1;

        const queryParams = generatePostQueryParams(
            feedQuery,
            dateQuery,
            {
                perPage
            }
        )
        queryParams.set("page", String(currentPage));

        params.sortModel.forEach(sortModel => {
            let col = sortModel.colId;
            if (sortModel.sort === 'desc') {
                col = `-${col}`;
            }
            queryParams.append('sort_by', col);
        });

        if (!queryParams.has('sort_by')) {
            queryParams.set('sort_by', '-revised_at');
        }

        if (!objectIsEmpty(params.filterModel)) {
            queryParams.set('ag_filter', JSON.stringify(params.filterModel));
        }

        const gridApi = gridRef.current?.api;
        if (rowCount === null) {
            gridApi?.setGridOption("loading", true);
        }
        const {posts, totalPosts} = await loadPosts(queryParams, kv, formId, formFields);
        params.successCallback(posts, totalPosts);
        if (rowCount === null) {
            gridApi?.setGridOption("loading", false);
        }
    }, [kv, formId, formFields, feedQuery, dateQuery, rowCount]);

    const onGridReady = useCallback((/** @type {AgGrid.GridReadyEvent} */ event) => {
        /** @type {AgGrid.IDatasource} */
        const dataSource = {
            rowCount: undefined,
            getRows,
        };
        event.api.setGridOption("datasource", dataSource);
    }, [getRows]);

    useEffect(() => {
        if (gridRef.current?.api && feedView === "table") {
            const agApi = gridRef.current.api;
            const dataSource = {
                rowCount: undefined,
                getRows,
            }
            agApi.setGridOption("datasource", dataSource);
        }
    }, [feedQuery, dateQuery, gridRef, feedView]);

    const onStateUpdated = useCallback((/** @type {AgGrid.StateUpdatedEvent} */ event) => {
        if (event.sources.includes("columnOrder")) {
            // @ts-ignore
            const columnDefsOrder = colDefs.map(def => def.field);
            const newColumnOrder = event.state.columnOrder.orderedColIds;
            const isDefaultOrder = isEqual(columnDefsOrder, newColumnOrder);
            if (isDefaultOrder) {
                setSettings('columnOrder', null);
            } else {
                setSettings('columnOrder', event.state.columnOrder);
            }
        }
        
        if (event.sources.includes("sort")) {
            setSettings('sort', event.state.sort);
        }
    }, [setSettings]);

    const resetTable = useCallback(() => {
        const grid = gridRef.current;
        if (!grid) {
            return;
        }
        setSettings('columnOrder', null);
        grid.api.resetColumnState();
    }, [setSettings]);

    const refreshData = useCallback(() => {
        const grid = gridRef.current;
        if (!grid) {
            return;
        }
        grid.api.refreshInfiniteCache();
    }, []);

    useZeroEventListener(EventNames.RESET_TABLE, resetTable);
    useZeroEventListener(EventNames.REFRESH_DATA, refreshData);

    const loading = embeddedForm === undefined;

    if (loading) {
        return null;
    }

    return (
        <>
            <FeedDataGridModals />
            <ZeroDataGrid
                gridRef={gridRef}
                columnDefs={colDefs}
                defaultColDef={{
                    cellStyle: {
                        display: 'flex',
                        alignItems: 'center',
                    }
                }}
                initialState={location.state?.gridState ?? settings}
                onGridReady={onGridReady}
                onStateUpdated={onStateUpdated}
                cacheBlockSize={POSTS_PER_PAGE}
                cacheOverflowSize={2}
                maxConcurrentDatasourceRequests={2}
                infiniteInitialRowCount={rowCount ?? POSTS_PER_PAGE}
                pagination={true}
                paginationPageSizeSelector={false}
                paginationPageSize={rowCount ?? POSTS_PER_PAGE}
                tooltipShowDelay={500}
                tooltipShowMode="standard"
                suppressCellFocus
            />
        </>
    );
}
