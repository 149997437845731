// @ts-check

import { extractErrorMessages } from './Helper';

/**
 * @typedef {string | Error | Response} ErrorDetailsInput
 * @typedef {string | ResponseErrorMessage} ServerErrorMessage
 */

export class ErrorDetails {
    /** @param {ErrorDetailsInput} error */
    constructor(error) {
        /** @type {ErrorDetailsInput} */
        this.originalError = error;
        /** @type {boolean} */
        this.parsed = false;
        /** @type {string} */
        this.message = '';
        /** @type {ServerErrorMessage[]} */
        this.originalServerErrors = [];
        /** @type {{global: string[], specific: ResponseErrorMessage[]}} */
        this.serverErrors = {global: [], specific: []};
        /** @type {boolean} */
        this.isServerError = false;
        /** @type {Error} */
        this.internalError = null;
    }

    /**
     * @param {string} fieldName 
     */
    getMessagesForField(fieldName) {
        return this.serverErrors.specific.filter(e => e.field === fieldName).map(e => e.message);
    }

    /**
     * @param {string} fieldName 
     */
    getFirstMessageForField(fieldName) {
        return this.getMessagesForField(fieldName)[0] ?? '';
    }

    /**
     * @param {ErrorDetailsInput} error 
     * @param {string} defaultErrorMessage 
     */
    static async parseError(error, defaultErrorMessage) {
        const details = new this(error);

        if (typeof(error) === 'string') {
            details.message = error;
        } else if (error instanceof Response) {
            try {
                details.isServerError = true;
                const messages = await extractErrorMessages(error);
                details.originalServerErrors = messages;
    
                if (messages.length > 0) {
                    details.message = typeof(messages[0]) === 'string' ? messages[0] : messages[0].message;
                    details.serverErrors = {
                        global: messages.filter(m => typeof(m) === 'string'),
                        specific: messages.filter(m => typeof(m) !== 'string'),
                    };
                }
            } catch (err) {
                details.internalError = err;
            }
        } else if (error instanceof Error) {
            details.message = error.message;
        }

        if (!details.message) {
            details.message = defaultErrorMessage;
        }

        details.parsed = true;
        return details;
    }
}