import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import GenerateReport from '../AdminPages/Reports/GenerateReport.js';

import IncidentsTable from './IncidentsTable.js';
import IncidentsTotalsAnalytics from './Analytics/IncidentsTotalsAnalytics.js';
import InjuryTypeAnalytics from './Analytics/InjuryTypeAnalytics.js';
import IllnessTypeAnaltyics from './Analytics/IllnessTypeAnaltyics.js';
import IncidentCauseAnalytics from './Analytics/IncidentCauseAnalytics.js';
import EventTypeAnalytics from './Analytics/EventTypeAnalytics.js';
import BodyPartsAnalytics from './Analytics/BodyPartsAnalytics.js';
import IncidentsDateChart from './Analytics/IncidentsDateChart.js';
import IncidentsSearchFilter from './IncidentsSearchFilter.js';

import DeleteLocationModal from './DeleteLocationModal.js';
import SelectLocationModal from './SelectLocationModal.js';
import DraftsModal from './DraftsModal.js';

import InfoIconComponent from '../InfoIconComponent.js';

import error_img from '../../assets/css/img/dog_error.png'

import {Pagination, Tabs} from 'antd'
import {PlusCircleOutlined, ReloadOutlined} from '@ant-design/icons';

import {
    get_location,
    get_locations,
    get_incidents,
    get_incident_options,
    export_incident_analytics,
    export_incident_body_chart,
    get_incident_drafts
} from '../../api/zero-api.js'
import {
    safe_get,
    isMobileApp,
    scrollToTop,
    mainContentContainerClass,
    floatingButtonMargin,
    isAdmin,
    isTeamLead,
    isViewer
} from '../../other/Helper.js'

import * as incidentsActions from '../../store/actions/IncidentsActions.js';
import ButtonLoading from "../Shared/ButtonLoading";
import NotificationAlert from "../../other/NotificationAlert";
import LocationModal from './LocationModal.js';
import IncidentsHourlyChart from './Analytics/IncidentsHourlyChart.js';

class Incidents extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loading_location: true,
            loading_incidents: true,
            all_incidents: [],
            filtered_incidents: [],
            location_uuid: "",
            max_results: 0,
            total_incidents: 0,
            isExportingAnalytics: false,
        };

        this.getIncidents = this.getIncidents.bind(this);

        this.goToNewReport = this.goToNewReport.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.filterQuery !== prevProps.filterQuery || this.props.periodQuery !== prevProps.periodQuery) {
            scrollToTop("page-head");
            this.onChangePage(1);
        }

        if (prevProps.match.params.tab !== this.props.match.params.tab) {
            scrollToTop("page-head");
            this.props.dispatch(incidentsActions.updateIncidentsTab(this.props.match.params.tab));
        }
    }

    componentDidMount() {
        this._isMounted = true;
        scrollToTop("page-head");

        let tab = safe_get(this.props, "match.params.tab", "incidents");
        if (tab !== this.props.activeTab) {
            this.props.dispatch(incidentsActions.updateIncidentsTab(this.props.match.params.tab));
        }

        if (tab === 'analytics' || tab === 'body') {
            const query = window.location.search;
            if (query.includes('export=true')) {
                const [filterQuery, dateQuery] = this.splitExportQuery(query);
                this.props.dispatch(incidentsActions.updateIncidentsFilterQuery(filterQuery));
                this.props.dispatch(incidentsActions.updateIncidentsPeriodQuery(dateQuery));
            }
        }

        this.getLocations();
        this.getDrafts();

        if (!this.props.filterQuery.includes("all_locations")) {
            this.getIncidents();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        // var location = { "location_uuid": "all_locations" };
        // this.props.dispatch(incidentsActions.update_current_location(location));
        // this.props.dispatch(incidentsActions.updateIncidentsFilterQuery("location_uuid=all_locations"));
        this.props.dispatch(incidentsActions.updateIncidentsPagePosition(window.pageYOffset));
    }

    getLocations = () => {
        if (this._isMounted) {
            let self = this;
            get_locations().then(function (success) {
                success.json().then(success => {
                    let locations = safe_get(success, "locations", []).sort(function (a, b) {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    });
                    self.props.dispatch(incidentsActions.update_incidents_locations(locations));
                });
            }, function (error) {
                console.error(error)
            });
        }
    }

    getDrafts = () => {
        let self = this;
        get_incident_drafts().then(success => {
            success.json().then(data => {
                const drafts = safe_get(data, 'incidents', []).sort(function (a, b) {
                    return a.edited_at > b.edited_at ? -1 : 1
                });
                this.setState({drafts: drafts});
            }).catch(err => {
                NotificationAlert("error", "", "Unable to load your drafts.");
            });
        });
    }

    getIncidents() {
        var self = this;
        if (this._isMounted) {
            this.setState({loading_incidents: true});
            var query = "?" + this.props.filterQuery + this.props.periodQuery + '&page=' + this.props.pagination;
            get_incidents(query).then(function (success) {
                success.json().then(success => {
                    if (self._isMounted) {

                        var incidents = safe_get(success, "incidents", []);
                        let maxResults = safe_get(success, 'max_results', 0);
                        let totalIncidents = safe_get(success, 'total_incidents', 0);

                        self.setState({
                            loading_incidents: false,
                            all_incidents: incidents,
                            max_results: maxResults,
                            total_incidents: totalIncidents
                        });
                        window.scrollTo(0, self.props.pagePosition);
                    }
                });
            }, function (error) {
                console.error(error);
                self.setState({loading_incidents: false, location_error: true});
            });
        }
    }

    onChangePage = (page) => {
        this.props.dispatch(incidentsActions.updateIncidentsPagination(page));
        this.props.dispatch(incidentsActions.updateIncidentsPagePosition(0));

        setTimeout(() => {
            this.getIncidents();
        }, 100);
    }

    updateIncidentsTab = (tab) => {
        if (tab === this.props.activeTab) return;

        const tabInPath = this.props.match.params.tab;
        let newPath = '';

        if (tabInPath === undefined) {
            newPath = `${this.props.location.pathname}/${tab}`;
        } else {
            const pathSegments = this.props.location.pathname.split('/').slice(0, -1);
            pathSegments.push(tab);
            newPath = pathSegments.join('/');
        }

        this.props.history.push(newPath + this.props.location.search);
    }


    goToNewReport(location_uuid) {
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/incidents/location/" + location_uuid + "/new_incident/1")
    }

    editDraft = (incident) => {
        this.props.dispatch(incidentsActions.update_current_incident(incident));
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/incidents/location/" + this.props.current_location.location_uuid + "/edit_incident/" + incident.incident_uuid + "/1");
    }

    splitExportQuery(query) {
        let filters = query.substring(1).split("&");
        filters = filters.filter(function (n) {
            return n !== "" && n
        });

        const dateFilters = ['period', 'year', 'quarter', 'month', 'from_date', 'to_date']
        let dateQuery = '&';
        let filterQuery = '?';

        for (const i in filters) {
            const filter = filters[i].split("=");
            const filter_type = filter[0];
            const filter_value = filter[1] || "";

            if (dateFilters.includes(filter_type)) {
                dateQuery += `${filter_type}=${filter_value}&`;
            } else {
                filterQuery += `${filter_type}=${filter_value}&`;
            }
        }

        // remove trailing '&' and leading '?'
        dateQuery = dateQuery.substring(0, dateQuery.length - 1);
        filterQuery = filterQuery.substring(1, filterQuery.length - 1);

        return [filterQuery, dateQuery];
    }

    exportAnalytics = () => {
        this.setState({isExportingAnalytics: true});

        let query = this.props.filterQuery + this.props.periodQuery;

        if (query.length > 0 && query[0] === '&') {
            query = '?' + query.substring(1, query.length);
        } else if (query.length > 0 && query[0] !== '?') {
            query = '?' + query;
        }

        const body = JSON.stringify({query});

        if (window.location.hostname.includes('localhost')) {
            setTimeout(() => {
                this.setState({isExportingAnalytics: false});
            }, 2000);
        }

        export_incident_analytics(body).then(success => {
            success.json().then(success => {
                if (success.data.public_url !== null) {
                    window.location = success.data.public_url;
                } else {
                    // only happens when running against a local running back-end
                    console.log('export query', success.data.query);
                }
                this.setState({isExportingAnalytics: false});
            });
        }, error => {
            NotificationAlert('error', '', 'Could not export incident analytics.');
            this.setState({isExportingAnalytics: false});
        });
    }

    exportBodyChart = () => {
        this.setState({isExportingBodyChart: true});

        let query = this.props.filterQuery + this.props.periodQuery;

        if (query.length > 0 && query[0] === '&') {
            query = '?' + query.substring(1, query.length);
        } else if (query.length > 0 && query[0] !== '?') {
            query = '?' + query;
        }

        const body = JSON.stringify({query});

        if (window.location.hostname.includes('localhost')) {
            setTimeout(() => {
                this.setState({isExportingBodyChart: false});
            }, 2000);
        }

        export_incident_body_chart(body).then(success => {
            success.json().then(success => {
                if (success.data.public_url !== null) {
                    window.location = success.data.public_url;
                } else {
                    // only happens when running against a local running back-end
                    console.log('export query', success.data.query);
                }
                this.setState({isExportingBodyChart: false});
            });
        }, error => {
            NotificationAlert('error', '', 'Could not export incident body chart.');
            this.setState({isExportingBodyChart: false});
        });
    }

    render() {
        return (
            <div className={mainContentContainerClass() + ' print-7in'}>
                {
                    this.state.showLocationModal &&
                    <SelectLocationModal
                        show={this.state.showLocationModal}
                        cancel={() => {
                            this.setState({showLocationModal: false});
                        }}
                        locations={this.props.incidents_locations}
                        confirm={this.goToNewReport}
                        showDraftsModal={() => this.setState({showLocationModal: false, showDraftsModal: true})}
                    />
                }
                {
                    this.state.showDraftsModal &&
                    <DraftsModal
                        show={this.state.showDraftsModal}
                        cancel={() => {
                            this.getDrafts();
                            this.setState({showDraftsModal: false});
                        }}
                        editDraft={this.editDraft}
                    />
                }

                <div id="page-head" className="no-padding-mobile no-padding-print">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{marginBottom: "0px"}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles team-name-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                Incidents
                                                <InfoIconComponent
                                                    position={"bottom"}
                                                    width={"170px"}
                                                    text={<span>Anyone can log an incident, but only Admins and Team Leads can view all incidents.</span>}
                                                />
                                            </h3>
                                            {
                                                !isMobileApp() && !isViewer(this.props.user) &&
                                                <button className="btn btn-primary pull-right dont-print" style={{
                                                    textAlign: 'center',
                                                    display: 'inline-block',
                                                }} onClick={() => {
                                                    this.setState({showLocationModal: true})
                                                }}>
                                                    <span className="">New Incident</span>
                                                </button>
                                            }
                                            {
                                                safe_get(this.state, "drafts", []).length > 0 &&
                                                <span className="blue-link mar-top-5 mar-rgt-10 pull-right"
                                                      onClick={() => {
                                                          this.setState({showDraftsModal: true});
                                                      }}>
                                                My Drafts ({safe_get(this.state, "drafts", []).length})
                                              </span>
                                            }
                                        </div>
                                        <p style={{marginBottom: "0px", color: "#505050", paddingTop: "3px"}}>Work-related injury and illness reporting.</p>
                                        <IncidentsSearchFilter/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="page-content" className="no-padding-mobile no-padding-print"
                     style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px"}}>

                    {
                        this.state.location_error &&
                        <div className="panel bulletin-post-border">
                            <img src={error_img} alt="error-img"
                                 style={{width: "150px", display: "block", margin: "auto", paddingTop: "10px"}}/>
                            <h4 className="mar-btm zero-blue text-center">Sorry, this location no longer exists.</h4>
                            <button className="btn btn-primary"
                                    style={{marginTop: "8px", display: "block", margin: "auto", marginBottom: "10px"}}
                                    onClick={() => {
                                        window.location = "/"
                                    }}>Back to ZERO
                            </button>
                        </div>
                    }

                 


                    {
                        !this.state.location_error &&
                        <div className="row" style={{margin: 0}}>
                            <div style={{marginBottom: "10px"}}>
                                <div className="tab-content"
                                     style={{border: "1px solid #e2e2e2", background: "rgb(255, 255, 255)", padding: '0 1.5rem 1.5rem'}}>
                                           <Tabs
                                                activeKey={this.props.activeTab}
                                                destroyInactiveTabPane={true}
                                                onChange={activeTab => {
                                                    this.updateIncidentsTab(activeTab)                            
                                                }}
                                                items={[
                                                    {
                                                        key: 'incidents',
                                                        label: 'Incidents',
                                                        children: <>
                                                            <div id="incidents-tab" className={"tab-pane fade active in"}
                                             style={{paddingBottom: 35}}>
                                            <IncidentsTable loading={this.state.loading_incidents}
                                                            incidents={this.state.all_incidents}
                                                            getIncidents={this.getIncidents}/>
                                                            <div style={{float: "left"}}>
                                                                <Pagination
                                                                    size={"small"}
                                                                    showSizeChanger={false}
                                                                    hideOnSinglePage={true}
                                                                    pageSize={this.state.max_results}
                                                                    total={this.state.total_incidents}
                                                                    current={this.props.pagination}
                                                                    onChange={this.onChangePage}
                                                                />
                                                            </div>
                                                        </div>
                                                        </>
                                                    },
                                                    {
                                                        key: 'analytics',
                                                        label: 'Analytics',
                                                        children: <>
                                                            <div id="analytics-tab" className="tab-pane fade active in">
                                                                <IncidentsTotalsAnalytics
                                                                    query={this.props.filterQuery + this.props.periodQuery}
                                                                    location_uuid={this.props.current_location_uuid}/>
                                                            </div>
                                                        </>
                                                    },
                                                    {
                                                        key: 'body',
                                                        label: 'Body Chart',
                                                        children: <>
                                                           <div id="body-tab" className="tab-pane fade active in">
                                                                <BodyPartsAnalytics query={this.props.filterQuery + this.props.periodQuery}
                                                                                    location_uuid={this.props.current_location_uuid}/>
                                                            </div>
                                                        </>
                                                    },
                                                    {
                                                        key: 'reports',
                                                        label: 'Reports',
                                                        children: <>
                                                            <div id="body-tab" className="tab-pane fade active in">
                                                                <GenerateReport isIncidentsReport={true} isFormsReport={false}/>
                                                            </div>
                                                        </>
                                                    }
                                                ]}
                                                renderTabBar={(props, DefaultTabBar) => (
                                                    <>
                                                        <DefaultTabBar {...props} />
                                                    </>
                                                )}
                                            />
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.props.activeTab === "analytics" &&
                        <div style={{margin: "0 5px"}}>
                            <div className="row">
                                <InjuryTypeAnalytics query={this.props.filterQuery + this.props.periodQuery}
                                                     location_uuid={this.props.current_location_uuid}/>
                                <IllnessTypeAnaltyics query={this.props.filterQuery + this.props.periodQuery}
                                                      location_uuid={this.props.current_location_uuid}/>
                            </div>
                            <div className="row">
                                <IncidentsDateChart query={this.props.filterQuery + this.props.periodQuery}
                                                    location_uuid={this.props.current_location_uuid}/>
                            </div>
                            <div className="row">
                                <IncidentsHourlyChart query={this.props.filterQuery + this.props.periodQuery}
                                                      location_uuid={this.props.current_location_uuid}/>
                            </div>
                            <div className="row">
                                <IncidentCauseAnalytics query={this.props.filterQuery + this.props.periodQuery}
                                                        location_uuid={this.props.current_location_uuid}/>
                                {
                                    this.props.event_types.length > 0 &&
                                    <EventTypeAnalytics query={this.props.filterQuery + this.props.periodQuery}
                                                        location_uuid={this.props.current_location_uuid}/>
                                }
                            </div>
                        </div>
                    }

                    {
                        isMobileApp() && !isViewer(this.props.user) && this.props.incidents_locations.length > 0 &&
                        <div id="floating-button" style={{bottom: floatingButtonMargin()}} onClick={() => {
                            this.setState({showLocationModal: true})
                        }}>
                            <p className="plus-sign">+</p>
                        </div>

                    }

                </div>
            </div>
        )
    }

}


const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", {}),
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        current_location: safe_get(store, "incidents.location", {}),
        current_location_uuid: safe_get(store, "incidents.location.location_uuid", ""),
        incidents_locations: safe_get(store, "incidents.locations", []),
        filterQuery: safe_get(store, "incidents.filterQuery", ""),
        periodQuery: safe_get(store, "incidents.periodQuery", ""),
        pagination: safe_get(store, "incidents.pagination", 1),
        pagePosition: safe_get(store, "incidents.pagePosition", 0),
        incident_types: safe_get(store, "incidents.incidentTypes", []),
        event_types: safe_get(store, "incidents.eventTypes", []),
        activeTab: safe_get(store, "incidents.tab", "incidents")
    }
}

export default withRouter(connect(mapStateToProps)(Incidents));