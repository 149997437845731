import React, {Component} from 'react';

import ButtonLoading from './Shared/ButtonLoading';

import {Select, Modal, Upload} from 'antd'
import {PlusCircleOutlined, CloseCircleOutlined, InboxOutlined} from '@ant-design/icons';

import {safe_get} from '../other/Helper';
import {create_multuple_users, file_upload_users, getTeamsList} from '../api/zero-api.js'

import LoadingIndicator from './Shared/LoadingIndicator';

class CreateUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            teams_list: [],
            errors: [],
            users: [
                {
                    "first_name": "",
                    "last_name": "",
                    "phone_number": "",
                    "email": "",
                    "title": "",
                    "unique_data": "",
                    "password": "",
                    "role": null,
                    "team_uuids": []
                },
            ],
            fileList: [],
            uploading: false
        };

        this.teamSelect = this.teamSelect.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        if (this.props.teams) {
            this.setState({
                teams_list: this.props.teams,
            });
        } else {
            getTeamsList()
                .then((teams) => {
                    this.setState({teams_list: teams, loadingTeamOptions: false});
                })
                .catch((error) => {
                    this.setState({loadingTeamOptions: false});
                });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.teams !== this.props.teams) {
            this.setState({
                teams_list: this.props.teams,
            });
        }
    }

    addUserFields = () => {
        let users = [...this.state.users];
        let user = {
            "first_name": "",
            "last_name": "",
            "phone_number": "",
            "email": "",
            "title": "",
            "unique_data": "",
            "password": "",
            "role": null,
            "team_uuids": []
        }
        users.push(user);
        this.setState({users: users});
    }

    removeUser = (index) => {
        let users = [...this.state.users];
        users.splice(index, 1);

        let errors = [...this.state.errors];
        let errorIndex = [...this.state.errors].findIndex(error => error.index === index);
        errors.splice(errorIndex, 1)

        this.setState({
            users: users,
            errors: errors
        });

    }

    roleSelect = (value, userIndex) => {
        let users = [...this.state.users]
        let user = users[userIndex];
        user["role"] = value

        this.setState({users: users});
    }

    teamSelect(value, userIndex) {
        let users = [...this.state.users]
        let user = users[userIndex];
        user["team_uuids"] = value

        this.setState({users: users});
    }

    onChangeInput = (event, userIndex) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let users = [...this.state.users];
        let user = users[userIndex]
        user[name] = value

        users[userIndex] = user;

        this.setState({
            users: users
        });
    }

    checkErrors() {

        var newState = {};

        newState["positionTitleError"] = safe_get(this.state, "positionTitle", "").length === 0 ? "Invalid Title" : null;

        newState["firstNameError"] = safe_get(this.state, "firstname", "").length === 0 ? "Invalid Name" : null;

        newState["lastNameError"] = safe_get(this.state, "lastname", "").length === 0 ? "Invalid Name" : null;


        this.setState(newState);

        var allPassed = true;
        let keys = Object.keys(newState)
        for (var index in keys) {
            let error = keys[index];
            if (newState[error] != null) {
                allPassed = false;
            }
        }


        return allPassed && this.state.passwordCheck;
    }

    handleSubmit() {
        var self = this;
        if (!this.state.submitting) {

            this.setState({
                submitting: true,
                emailError: null,
                firstNameError: null,
                lastNameError: null,
                phoneError: null,
                pwError: null,
                employeeIDError: null,
                positionTitleError: null,
                teamsError: null
            });

            // var body = JSON.stringify({
            //   "first_name": safe_get(this.state, "firstname", ""),
            //   "last_name": safe_get(this.state, "lastname", ""),
            //   "email": safe_get(this.state, "email", ""),
            //   "phone_number": safe_get(this.state, "phone", ""),
            //   "title": safe_get(this.state, "positionTitle", ""),
            //   "unique_data": safe_get(this.state, "employeeID", ""),
            //   "password": safe_get(this.state, "password", ""),
            //   "team_uuids": this.state.selectedTeams,
            //   "role": this.state.selectedRole
            // });

            // kiosk_create_user(body).then(function (success) {
            //   self.setState({ submitting: false });
            //   self.props.userAddedCallBack();
            // }, function (error) {
            //   var newState = {};
            //   if (error.status === 422) {
            //     error.json().then(error => {
            //       self.handleError(error);
            //     });
            //   }
            //   else {
            //     newState["error_message"] = "An error occured. Could not add user."
            //     self.setState(newState);
            //   }
            //   self.setState({ submitting: false });
            // });

            var body = JSON.stringify({
                "users": this.state.users
            })

            create_multuple_users(body)
                .then(response => response.json())
                .then(data => {
                    const isAsync = safe_get(data, 'is_async', false);
                    self.setState({submitting: false});
                    self.props.userAddedCallBack(isAsync);
                })
                .catch(error => {
                    if (error.status === 422) {
                        error.json().then(error => {
                            let errors = safe_get(error, "error_messages", []);
                            self.setState({errors: errors});
                        })
                    }
                    self.setState({submitting: false});
                });
        } else {
            return false;
        }
    }

    handleUpload = (obj) => {
        let self = this;
        let file = obj.file;

        this.setState({
            loadingSkeleton: true
        });

        file.arrayBuffer().then(buffer => {
            file_upload_users(buffer, file.type).then(success => {
                success.json().then(success => {
                    self.setState({
                        errors: safe_get(success, "errors", []),
                        users: safe_get(success, "user_data", []),
                        importUsers: false,
                        loadingSkeleton: false
                    });
                })
            }, error => {
                self.setState({
                    uploadError: "Unable to upload file.",
                    loadingSkeleton: false
                });
            });
        });
    }

    render() {

        return (
            (<Modal
                title={this.state.importUsers ? "Import Users" : "Create Members"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                width={1250}
                footer={
                    <div>
                        {
                            !this.state.importUsers &&
                            <span className="blue-link pull-left" onClick={() => {
                                this.setState({importUsers: true});
                            }}>
                Import Users
              </span>
                        }
                        <button className="btn btn-discard" onClick={this.props.cancel}>Back</button>
                        <button className="btn btn-modal" disabled={this.state.importUsers} onClick={this.handleSubmit}>
                            {
                                this.state.submitting ? <ButtonLoading/> : "Create"
                            }
                        </button>
                    </div>
                }
            >
                {
                    !this.state.importUsers &&
                    <div>
                        <p className="zero-dark-grey">An email address OR an employee ID is required for each user. You
                            do not need to enter both. If no email is provided, the user will only be able to login
                            via <a rel="noopener noreferrer"
                                   onClick={() => FreshworksWidget('open', 'article', {'id': 70000416835})}
                                   className="blue-link">Kiosk Mode</a>.</p>
                        <div style={{overflow: "auto", overflowY: "hidden", paddingBottom: "15px"}}>
                            <div style={{whiteSpace: "nowrap", display: "inline-block", padding: "0 0 0 15px"}}>
                                <label className="control-label zero-dark-grey "
                                       style={{width: "150px", fontWeight: "600", fontSize: "12px"}}>
                                    Email *
                                </label>
                                <label className="control-label zero-dark-grey mar-lft-5"
                                       style={{width: "100px", fontWeight: "600", fontSize: "12px"}}>
                                    Employee ID
                                </label>
                                <label className="control-label zero-dark-grey mar-lft-5"
                                       style={{width: "100px", fontWeight: "600", fontSize: "12px"}}>
                                    First Name *
                                </label>
                                <label className="control-label zero-dark-grey mar-lft-5"
                                       style={{width: "100px", fontWeight: "600", fontSize: "12px"}}>
                                    Last Name *
                                </label>
                                <label className="control-label zero-dark-grey mar-lft-5"
                                       style={{width: "100px", fontWeight: "600", fontSize: "12px"}}>
                                    Password *
                                </label>
                                <label className="control-label zero-dark-grey mar-lft-5"
                                       style={{width: "150px", fontWeight: "600", fontSize: "12px"}}>
                                    Role *
                                </label>
                                <label className="control-label zero-dark-grey mar-lft-5"
                                       style={{width: "250px", fontWeight: "600", fontSize: "12px"}}>
                                    Teams *
                                </label>
                                <label className="control-label zero-dark-grey mar-lft-5"
                                       style={{width: "100px", fontWeight: "600", fontSize: "12px"}}>
                                    Phone
                                </label>
                                <label className="control-label zero-dark-grey mar-lft-5"
                                       style={{width: "85px", fontWeight: "600", fontSize: "12px"}}>
                                    Title
                                </label>
                            </div>
                            {
                                this.state.users.map((user, userIndex) => {
                                    let error = [...this.state.errors].find(error => error.index === userIndex);
                                    let errors = safe_get(error, "errors", []);

                                    let first_name_error = errors.find(x => x.field === "first_name");
                                    let last_name_error = errors.find(x => x.field === "last_name");
                                    let email_error = errors.find(x => x.field === "email");
                                    let unique_data_error = errors.find(x => x.field === "unique_data");
                                    let title_error = errors.find(x => x.field === "title");
                                    let phone_error = errors.find(x => x.field === "phone_number");
                                    let password_error = errors.find(x => x.field === "password");
                                    let role_error = errors.find(x => x.field === "role");
                                    let teams_error = errors.find(x => x.field === "team_uuids");

                                    return (
                                        <div key={userIndex} className="mar-btm-10"
                                             style={{whiteSpace: "nowrap", display: "inline-block",}}>
                                            <span>{userIndex}  </span>
                                            <input type="email" placeholder="Email" name="email"
                                                   className={"form-control custom-placeholder " + (email_error ? "error" : "")}
                                                   style={{maxWidth: '150px', display: "inline-block"}}
                                                   onChange={(e) => {
                                                       this.onChangeInput(e, userIndex)
                                                   }} value={user.email}/>
                                            <input type="text" placeholder="ID" name="unique_data"
                                                   className={"form-control custom-placeholder mar-lft-5 " + (unique_data_error ? "error" : "")}
                                                   style={{maxWidth: '100px', display: "inline-block"}}
                                                   onChange={(e) => {
                                                       this.onChangeInput(e, userIndex)
                                                   }} value={user.unique_data}/>
                                            <input type="text" placeholder="First Name" name="first_name"
                                                   className={"form-control custom-placeholder mar-lft-5 " + (first_name_error ? "error" : "")}
                                                   style={{maxWidth: '100px', display: "inline-block"}}
                                                   onChange={(e) => {
                                                       this.onChangeInput(e, userIndex)
                                                   }} value={user.first_name}/>
                                            <input type="text" placeholder="Last Name" name="last_name"
                                                   className={"form-control custom-placeholder mar-lft-5 " + (last_name_error ? "error" : "")}
                                                   style={{maxWidth: '100px', display: "inline-block"}}
                                                   onChange={(e) => {
                                                       this.onChangeInput(e, userIndex)
                                                   }} value={user.last_name}/>
                                            <input type="password" placeholder="Password" name="password"
                                                   className={"form-control custom-placeholder mar-lft-5 " + (password_error ? "error" : "")}
                                                   style={{maxWidth: '100px', display: "inline-block"}}
                                                   onChange={(e) => {
                                                       this.onChangeInput(e, userIndex)
                                                   }} value={user.password}/>
                                            <Select
                                                style={{width: "150px", marginLeft: "5px"}}
                                                className={(role_error ? "error" : "")}
                                                showArrow={true}
                                                placeholder="Select a role"
                                                dropdownStyle={{zIndex: "10000"}}
                                                onChange={(value) => {
                                                    this.roleSelect(value, userIndex);
                                                }}
                                                value={user.role}
                                                filterOption={false}
                                                virtual={false}
                                            >
                                                <Select.Option key={"admin"} value={"admin"}>
                                                    <span className="badge badge-success badge-icon "
                                                          style={{margin: "0px 5px 2px 0px"}}/>Admin
                                                </Select.Option>
                                                <Select.Option key={"team_lead"} value={"team_lead"}>
                                                    <span className="badge badge-info badge-icon "
                                                          style={{margin: "0px 5px 2px 0px"}}/>Team Lead
                                                </Select.Option>
                                                <Select.Option key={"user"} value={"user"}>
                                                    <span className="badge badge-purple badge-icon "
                                                          style={{margin: "0px 5px 2px 0px"}}/>Member
                                                </Select.Option>
                                                <Select.Option key={"contributor"} value={"user_restricted"}>
                                                    <span className="badge badge-warning badge-icon "
                                                          style={{margin: "0px 5px 2px 0px"}}/>Contributor
                                                </Select.Option>
                                                <Select.Option key={"viewer"} value={"viewer"}>
                                                    <span className="badge badge-danger badge-icon "
                                                          style={{margin: "0px 5px 2px 0px"}}/>Viewer
                                                </Select.Option>
                                            </Select>
                                            <Select
                                                mode={"multiple"}
                                                className={(teams_error ? "error" : "")}
                                                style={{width: "250px", marginLeft: "5px"}}
                                                showArrow={true}
                                                maxTagCount={"responsive"}
                                                placeholder="Select a team"
                                                dropdownStyle={{zIndex: "10000"}}
                                                onChange={(value) => {
                                                    this.teamSelect(value, userIndex)
                                                }}
                                                filterOption={(inputValue, option) => {
                                                    return option.name.toLowerCase().includes(inputValue.toLowerCase())
                                                }}
                                                value={user.team_uuids}
                                                virtual={false}
                                            >
                                                {
                                                    this.state.teams_list.map((team, index) => (
                                                        <Select.Option key={team.uuid} value={team.uuid}
                                                                       name={team.name}>{team.name}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                            <input type="text" placeholder="Phone" name="phone_number"
                                                   className={"form-control custom-placeholder mar-lft-5 " + (phone_error ? "error" : "")}
                                                   style={{maxWidth: '100px', display: "inline-block"}}
                                                   onChange={(e) => {
                                                       this.onChangeInput(e, userIndex)
                                                   }} value={user.phone_number}/>
                                            <input type="text" placeholder="Title" name="title"
                                                   className={"form-control custom-placeholder mar-lft-5 " + (title_error ? "error" : "")}
                                                   style={{maxWidth: '85px', display: "inline-block"}}
                                                   onChange={(e) => {
                                                       this.onChangeInput(e, userIndex)
                                                   }} value={user.title}/>
                                            <CloseCircleOutlined className="zero-delete-red hover-cursor mar-lft-5"
                                                                 onClick={() => {
                                                                     this.removeUser(userIndex)
                                                                 }}/>

                                            <br/>

                                            <span style={{position: "relative"}}>
                        {
                            safe_get(email_error, "message", "") &&
                            <small className="error">{safe_get(email_error, "message", "")}</small>
                        }
                                                {
                                                    safe_get(unique_data_error, "message", "") === "ID already exists." &&
                                                    <small className="error"
                                                           style={{position: "absolute", left: "155px"}}>ID already
                                                        exists.</small>
                                                }
                                                {
                                                    safe_get(password_error, "message", "") &&
                                                    <small className="error" style={{
                                                        position: "absolute",
                                                        left: "470px"
                                                    }}>{safe_get(password_error, "message", "")}</small>
                                                }
                      </span>

                                        </div>
                                    )
                                })
                            }
                            <span className="blue-link mar-top-5" onClick={this.addUserFields}>
                <PlusCircleOutlined className="mar-rgt-5"/>Add
            </span>
                            {this.state.errors.length > 0 &&

                                <span class="error"><br/><br/>Errors to fix on row(s):
                                    {this.state.errors.map((myerror) => (
                                        <span> {myerror.index}
                                   </span>
                                    ))}
                                    <div>Please resolve the errors and try again.</div>
                            </span>
                            }
                        </div>
                    </div>
                }
                {
                    this.state.importUsers && !this.state.loadingSkeleton &&
                    <div>
                        <p className="zero-dark-grey">Download <a className="zero-light-blue"
                                                                  href="/files/ZERO User Upload Sheet.xlsx">this
                            template</a> (.xlsx), enter your user data, and re-upload the file in the box below when
                            ready.</p>
                        <Upload.Dragger
                            action="/"
                            multiple={false}
                            accept={".xlsx, .xls"}
                            customRequest={this.handleUpload}
                            fileList={this.state.fileList}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Upload.Dragger>
                        {
                            this.state.uploadError &&
                            <small className="error">{this.state.uploadError}</small>
                        }
                    </div>
                }
                {
                    this.state.importUsers && this.state.loadingSkeleton &&
                    <div>
                        <LoadingIndicator/>
                    </div>
                }
            </Modal>)
        );
    }
}

export default CreateUserModal;