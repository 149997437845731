// @ts-check

import { useState } from "react";
import SubmissionFieldCommentModal from "./SubmissionFieldCommentModal";
import FailedFieldsModal from "./FailedFieldsModal";

export default function SubmissionAnalyticsBlock({ submission }) {
    const [showFailedFieldsModal, setShowFailedFieldsModal] = useState(false);
    const [showCommentsModal, setShowCommentsModal] = useState(false);

    return (
        <>
            {showCommentsModal && (
                <SubmissionFieldCommentModal
                    show
                    cancel={() => setShowCommentsModal(false)}
                    submission_uuid={submission.submission_uuid}
                />
            )}
            {showFailedFieldsModal && (
                <FailedFieldsModal
                    show
                    cancel={() => setShowFailedFieldsModal(false)}
                    submission_uuid={submission.submission_uuid}
                />
            )}
            <div className="panel thin-border" style={{ margin: "0 0 1rem 0" }}>
                <div className="panel no-padding-print top-margin-print" style={{ padding: "1rem", margin: 0 }}>
                    <div className="row justify-content-start">
                        <div className="col-xs-4 no-padding-print">
                            <p className="text-sm text-bold zero-blue text-center" style={{ margin: "0.5rem" }}>
                                Score
                            </p>
                            <div className="text-lg">
                                <p
                                    className="text-thin text-main zero-blue text-center mar-btm-0 "
                                    style={{ fontSize: "3em", paddingLeft: "1rem" }}
                                >
                                    {submission.score}
                                    {!isNaN(submission.score) && <span style={{ fontSize: "30%" }}>%</span>}
                                </p>
                            </div>
                        </div>

                        <div className="col-xs-4 no-padding-print">
                            <p className="text-sm text-bold zero-blue text-center" style={{ margin: "0.5rem" }}>
                                Failed Items
                            </p>
                            <div
                                className="text-lg"
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowFailedFieldsModal(true)}
                            >
                                <p
                                    className="text-thin text-main zero-blue text-center mar-btm-0"
                                    style={{ fontSize: "3em" }}
                                >
                                    {submission.failed_items_count ?? 0}
                                </p>
                            </div>
                        </div>

                        <div className="col-xs-4 no-padding-print">
                            <p className="text-sm text-bold zero-blue text-center" style={{ margin: "0.5rem" }}>
                                Comments
                            </p>
                            <div
                                className="text-lg"
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowCommentsModal(true)}
                            >
                                <p
                                    className="text-thin text-main zero-blue text-center mar-btm-0"
                                    style={{ fontSize: "3em" }}
                                >
                                    {submission.comment_count ?? 0}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
