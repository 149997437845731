import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import 'core-js/proposals/promise-with-resolvers';

import React from 'react';
// import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';

import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/lib/integration/react'

import 'react-loading-skeleton/dist/skeleton.css'
import './assets/css/index.scss';

// import './assets/fonts';

import HttpsRedirect from 'react-https-redirect';
import App from './App';

import 'ag-grid-setup';

import { getStore } from './store/store.js';

import dayjs from "dayjs";
import UTC from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(UTC);
dayjs.extend(isoWeek);

let {store, persistor} = getStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <HttpsRedirect>
                <App/>
            </HttpsRedirect>
        </PersistGate>
    </Provider>
);