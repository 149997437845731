// @ts-check

/**
 * @typedef {{color?: "blue" | "red"}} ButtonLinkProps
 * @param {import("react").PropsWithChildren<JSX.IntrinsicElements["button"] & ButtonLinkProps>} props 
 */
export default function ButtonLink({color = 'blue', children, className: propsClassName, ...props}) {
    const className = `ButtonLink ${color}-link ${propsClassName}`.trim();

    return (
        <button className={className} {...props}>{children}</button>
    )
}