import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom';

import {
    safe_get,
    isMobileApp,
    isViewer,
    isAdmin,
    isTeamLead,
    isUser,
    isContributor,
    isIE,
    isRestrictedTeamUser,
    canManageSpaces,
    isPublicPage
} from '../../other/Helper.js'

import * as feedHelperActions from '../../store/actions/FeedHelperActions';

import {
    QuestionCircleOutlined, SettingOutlined, BellOutlined,
    PlusCircleOutlined, LeftCircleOutlined, MenuOutlined,
    CloudServerOutlined
} from '@ant-design/icons';

import {Badge, Alert} from 'antd';

import SelectLocationModal from '../Incidents/SelectLocationModal.js';
import SelectFormModal from '../Forms/SelectFormModal.js';

import logo from 'assets/css/img/ZERO-(white)-1080.png';
import logoSmall from 'assets/css/img/ZERO-(white)-small.png'

import '../../assets/css/header.css';
import {WebSocketContext} from "../../other/WebSockets";
import * as notificationsActions from "../../store/actions/NotificationsActions";
import {current_user} from "../../api/zero-api";
import OfflineContext from 'components/Offline/OfflineContext.js';
import useOfflineMode from 'components/Offline/useOfflineMode.js';


function OfflineIcon({children}) {
    const {isOffline} = useOfflineMode();

    const divStyle = {
        color: '#F45F53',
        height: '59px',
        padding: '5px 6px 0',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: '600',
        gap: '0 0.8rem',
    }

    const iconStyle = {
        fontSize: '24px',
    }

    return (
        <>
            {
                isOffline ? (
                    <li>
                        <div style={divStyle}>
                            <div>Offline</div>
                            <CloudServerOutlined style={iconStyle}/>
                        </div>
                    </li>
                ) : (
                    <>{children}</>
                )
            }
        </>
    )
}

class Header extends Component {
    static contextType = WebSocketContext;

    constructor(props, context) {
        super(props, context);

        this.state = {
            isOpen: false,
            isHovered: false,
            previousPath: "",
            showButtonTitles: true,
        };

        this.toggleAside = this.toggleAside.bind(this);
        this.toggleAssignments = this.toggleAssignments.bind(this);
        this.toggleSettings = this.toggleSettings.bind(this);
        this.handleHover = this.handleHover.bind(this);
        this.goBack = this.goBack.bind(this);
        this.goToFeed = this.goToFeed.bind(this);
        this.goToNewPost = this.goToNewPost.bind(this);
        this.goToNewReport = this.goToNewReport.bind(this);
        this.goToNewForm = this.goToNewForm.bind(this);
        this.goToSubmitForm = this.goToSubmitForm.bind(this);
        this.goToNewDoc = this.goToNewDoc.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.enableFreshdesk = this.enableFreshdesk.bind(this);

    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        window.addEventListener("orientationchange", this.updateDimensions);
        window.addEventListener('scroll', this.listenToScroll)
        if (!this.props.forPublicView) {
            this.context.addMessageHandler('update_notification_count', this.handleNotificationCountUpdate);
        }

        if (!window.FreshworksWidget) {
            const script = document.createElement("script");
            script.async = true;
            script.src = "https://widget.freshworks.com/widgets/70000002508.js";
            window.fwSettings = {'widget_id': '70000002508', 'locale': 'en'};
            document.head.appendChild(script);
            script.onload = function () {
                !function () {
                    if ("function" != typeof window.FreshworksWidget) {
                        var n = function () {
                            n.q.push(arguments)
                        };
                        n.q = [], window.FreshworksWidget = n
                    }
                }();
                FreshworksWidget('hide', 'launcher');
            }
        } else {
            FreshworksWidget('hide', 'launcher');
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        window.removeEventListener("orientationchange", this.updateDimensions);
        window.removeEventListener('scroll', this.listenToScroll)
        if (!this.props.forPublicView) {
            this.context.removeMessageHandler('update_notification_count', this.handleNotificationCountUpdate);
        }
    }

    updateDimensions() {
        if (window.innerWidth < 736) {
            this.setState({showButtonTitles: false});
        } else {
            this.setState({showButtonTitles: true});
        }
    }

    listenToScroll = () => {
        if (window.scrollY >= 60) {
            this.setState({
                showButtonInNav: true
            })
        } else {
            this.setState({
                showButtonInNav: false
            })
        }
    }

    toggleAside(e) {
        this.props.openAside();
    }

    toggleAssignments(e) {
        this.props.openAssignments();
    }

    toggleSettings(e) {
        this.props.openSettings();
    }

    handleHover() {
        this.setState({
            isHovered: !this.state.isHovered
        });
    }

    showBackButton = (location) => {
        if (
            location.includes("article") || location.includes("new_post") ||
            location.includes("new_attachment") || location.includes("/feed/post/") || location.includes("/my_posts/post/") ||
            location.includes("onboarding") || location.includes("password-change") ||
            location.includes("edit") || location.includes("create_team") ||
            location.includes("/reports/success") || location.includes("users") ||
            location.includes("/people") || location.includes("/new_incident") ||
            location.includes("/new_location") || location.includes("/new_form") || (location.includes("/forms/") && location.includes("/submit")) || (location.includes("/courses/") && location.includes("/submit")) ||
            location.includes("/submission/") || location.includes("/submission_view") ||
            location.includes("/manage_schedules/") || location.includes("manage_forms_templates/") || location.includes("manage_courses/") ||
            location.includes("/checkin") || location.includes("/manage_post_templates")
        ) {
            return true;
        } else if (location.includes("myprofile")) {
            if (this.props.teams.length === 0) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    hideHeaderButtons() {
        var location = this.props.location.pathname;
        var blackListed = [
            "manage_admins", "manage_tags",
            "create_team",
            "users", "users-edit", "myprofile", "password-change",
            "no_team"
        ]

        for (var i in blackListed) {
            if (location.includes(blackListed[i])) {
                return true;
            }
        }
        return false
    }

    activeTab() {
        var location = this.props.location.pathname;
        if (location.includes("dashboard")) {
            return "dashboard";
        } else if (location.includes("library")) {
            return "library";
        } else if (location.includes("incidents")) {
            return "incidents";
        } else if (location.includes("forms") || location.includes("schedule")) {
            return "forms";
        } else if (location.includes("courses")) {
            return "courses"
        } else if (location.includes("feed") || location.includes("/home/my_posts")) {
            return "feed";
        } else {
            return ""
        }
    }

    goBack() {
        let pathname = this.props.location.pathname;
        if (pathname.includes("/manage_forms_templates/edit_form/")) {
            this.props.history.push("/" + this.props.organization.organization_uuid + "/home/manage_forms_templates");
        } else if (pathname.includes("/manage_incident_templates/edit_form/")) {
            this.props.history.push("/" + this.props.organization.organization_uuid + "/home/manage_incident_templates");
        } else if (pathname.includes("/manage_courses/edit_form/")) {
            this.props.history.push("/" + this.props.organization.organization_uuid + "/home/manage_courses");
        } else if (pathname.includes("/feed/users/")) {
            this.props.dispatch(feedHelperActions.update_feed_tab("members"));
            this.props.history.goBack();
        } else if (pathname.includes("/edit_incident/")) {
            this.props.history.push("/" + this.props.organization.organization_uuid + "/home/incidents/location/" + this.props.current_location.location_uuid)
        } else if (pathname.includes("/submission_view/")) {
            if (pathname.includes("/forms/")) {
                this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/forms")
            } else {
                this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/courses")
            }
        } else if (pathname.includes("/assignment/")) {
            this.props.history.push("/" + this.props.organization.organization_uuid + "/home/dashboard");
        } else if (this.props.history.length <= 8 && (this.props.location.pathname.includes("/feed/post/") || this.props.location.pathname.includes("/assignment/"))) {
            // FOR MSAL LOGIN USRS GOING DIRECTLY TO POST/ASSIGNMENT
            this.props.history.push("/");
        } else if (this.props.history.length >= 2) {
            this.props.history.goBack();
        } else {
            // new tab - no history, go back to dashboard
            this.props.history.push("/");
        }
    }

    goToFeed() {
        if (this.props.location.pathname.includes("/home/my_posts")) {
            return "/" + this.props.organization.organization_uuid + "/home/my_posts"
        } else {
            return "/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid;
        }
    }

    goToNewPost() {
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/feed/new_post");
    }

    goToNewReport(location_uuid) {
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/incidents/location/" + location_uuid + "/new_incident/1");
    }

    goToSubmitForm(team_uuid, form_uuid) {
        this.setState({showFormModalModal: false});
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/" + team_uuid + "/forms/" + form_uuid + "/submit");
    }

    goToNewForm() {
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/forms/new_form");
    }

    goToNewDoc() {
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/private_library/new_post");
    }

    enableFreshdesk() {
        FreshworksWidget('identify', 'ticketForm', {
            name: safe_get(this.props, "user.full_name", ""),
            // email: safe_get(this.props, "user.email", ""),
            email: safe_get(this.props, "user.email", "")
        });
        FreshworksWidget('prefill', 'ticketForm', {
            type: 'Question',
            source: 'Feedback Widget',
            custom_fields: {
                cf_org_number: safe_get(this.props, "organization.uuid", ""),
                cf_user_number: safe_get(this.props, "user.uuid", ""),
                cf_current_url: safe_get(this.props, "location.pathname", ""),
                cf_account_name: safe_get(this.props, "organization.name", ""),
            }
        });
        FreshworksWidget('disable', 'ticketForm', ['name', 'type', 'custom_fields.cf_account_name', 'custom_fields.cf_org_number', 'custom_fields.cf_user_number', 'custom_fields.cf_current_url',]);
        FreshworksWidget('open');
    }

    isFeed(location) {
        var blackListed = [
            "access_teams", "manage_admins", "manage_tags",
            "reports", "organization", "directory", "create_team",
            "users", "users-edit", "myprofile", "password-change",
            "no_team", "feed", "private_library", "edit_team", "people", "chat", "analytics"
        ]
        for (var i in blackListed) {
            if (location.includes(blackListed[i])) {
                return false;
            }
        }
        return true
    }

    isAdminPath = (location) => {
        var adminPages = [
            "manage", "report", "settings"
        ]

        for (var i in adminPages) {
            if (location.includes(adminPages[i])) {
                return true;
            }
        }
        return false
    }

    handleNotificationCountUpdate = message => {
        if (message.organization_uuid === this.props.organization.organization_uuid) {
            const unread_notifications_counter = safe_get(message, 'data.total_unread', null);
            if (unread_notifications_counter !== null) {
                this.props.dispatch(notificationsActions.update_unread_notifications_counter(unread_notifications_counter))
            }
        }
    }

    render() {
        var isApp = isMobileApp();
        var activeTab = this.activeTab();
        // var hideHeaderButtons = this.hideHeaderButtons() || isMobileApp();
        var showButtonTitles = this.state.showButtonTitles;
        var showBackButton = this.showBackButton(this.props.location.pathname)

        let isNewOrEditPage = (this.props.location.pathname.includes("new") || this.props.location.pathname.includes("edit"));

        var headerStyle = {
            zIndex: '1101',
            position: 'fixed',
            width: "100%",
            height: '60px'
        }

        if (this.props.forPublicView) {
            headerStyle.top = 0;
        }

        // var navButtonsStyle = {
        //     marginTop:  "0px",
        //     marginLeft: "5px",
        //     marginRight: "0px",
        //     display: hideHeaderButtons ? "none" : "",
        //     float: showButtonTitles? "unset" : "left",
        // }

        var centerDivStyle = showButtonTitles ? {display: "table"} : {float: "left"}

        let isAdminPage = this.isAdminPath(this.props.location.pathname);
        let userCanPost = this.props.teams.length > 0 && !isViewer(this.props.user);

        var count = 0
        if (this.props.current_team.uuid === "my_teams") {
            for (var i in this.props.teams) {
                let team = this.props.teams[i];
                if (!isRestrictedTeamUser(team, this.props.user)) {
                    count += 1;
                }
            }
            userCanPost = count > 0;
        } else {
            userCanPost = !isRestrictedTeamUser(this.props.current_team, this.props.user) && !isViewer(this.props.user)
        }

        return (
            <header id="ZERO-header" style={headerStyle}>
                {
                    !this.props.forPublicView && this.props.teams.length === 0 &&
                    <Alert
                        showIcon
                        // closable
                        type="info"
                        message={"You're currently not a member of any teams. Please contact an administrator to be added to a team."}
                    />
                }

                {
                    this.state.showLocationModal &&
                    <SelectLocationModal
                        show={this.state.showLocationModal}
                        cancel={() => {
                            this.setState({showLocationModal: false});
                        }}
                        locations={this.props.incidents_locations}
                        confirm={this.goToNewReport}
                    />
                }

                {
                    this.state.showFormModalModal &&
                    <SelectFormModal
                        show={this.state.showFormModalModal}
                        cancel={() => {
                            this.setState({showFormModalModal: false});
                        }}
                        current_team_uuid={this.props.current_team.uuid}
                        confirm={this.goToSubmitForm}
                        teams={this.props.teams}
                        page_type={safe_get(this.props, "location.pathname", "").includes("courses") ? "lms" : "regular"}
                        location={this.props.location.pathname}
                    />
                }
                <div id="navbar-container" className="boxed">
                    <div className={"navbar-content big"}>
                        <div className="row" style={{maxWidth: "1100px", margin: "0 auto"}}>
                            <div className={isApp ? "col-lg-2 col-md-3 col-xs-4" : ""} style={{paddingLeft: "8px"}}>
                                <ul className="nav navbar-top-links pull-left">
                                    {
                                        !showBackButton && !this.props.isDesktopSize &&
                                        <li className="">
                                            <button className="ButtonLink no-hover"
                                                    onClick={this.props.toggleSideMenu}
                                                    style={{height: "59px"}}>
                                                <MenuOutlined style={{color: "white", fontSize: "15px"}}/>
                                            </button>
                                        </li>
                                    }
                                    {
                                        showBackButton &&
                                        <li className="">
                                            <button className="ButtonLink no-hover" onClick={this.goBack}
                                                    style={{height: "59px"}}>
                                                <LeftCircleOutlined style={{color: "white", fontSize: "18px"}}/>
                                            </button>
                                        </li>
                                    }
                                    {
                                        this.props.isDesktopSize && !isApp &&
                                        <li className="" style={{marginLeft: (showBackButton ? "22px" : "54px")}}>
                                            <a className="no-hover" onClick={() => {
                                                if (isPublicPage()) {
                                                    return;
                                                }
                                                
                                                if (navigator.onLine) {
                                                    this.props.history.push("/" + this.props.organization.organization_uuid + "/home/dashboard")
                                                } else {
                                                    this.props.history.push("/" + this.props.organization.organization_uuid + "/offline/dashboard")
                                                }
                                            }}>
                                                <img src={logo} alt="Zerologo" className="brand-logo-sidebar"/>
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div className={isApp ? "col-lg-10 col-md-9 col-xs-8" : ""}
                                 style={{paddingLeft: "0px", paddingRight: "8px"}}>
                                <ul className="nav navbar-top-links right-side">
                                    <OfflineIcon>
                                        {
                                            !isApp && !isNewOrEditPage && activeTab === "feed" && userCanPost && this.state.showButtonInNav &&
                                            <li className="">
                                                <button className="ButtonLink no-hover" onClick={this.goToNewPost}
                                                        style={{paddingLeft: "0px"}}>
                                                    <span className="header-post-button">{showButtonTitles &&
                                                        showButtonTitles ? "Post" : "Post"}</span>
                                                </button>
                                            </li>
                                        }
                                        {
                                            !isApp && !isNewOrEditPage && !isAdminPage && activeTab === "incidents" && !isViewer(this.props.user) && this.props.teams.length > 0 && this.props.incidents_locations.length > 0 && this.state.showButtonInNav &&
                                            <li className="">
                                                <button className="ButtonLink no-hover" onClick={() => {
                                                    this.setState({showLocationModal: true})
                                                }} style={{paddingLeft: "0px"}}>
                                                    <span className="header-post-button">{showButtonTitles &&
                                                        showButtonTitles ? "New Incident" : "New"}</span>
                                                </button>
                                            </li>
                                        }
                                        {
                                            !isApp && !isNewOrEditPage && !this.props.location.pathname.includes("/submi") && !isAdminPage && (activeTab === "forms" || activeTab === "courses") && (isAdmin(this.props.user) || isTeamLead(this.props.user) || isUser(this.props.user) || isContributor(this.props.user)) && this.props.teams.length > 0 && this.state.showButtonInNav &&
                                            <li className="">
                                                <button className="ButtonLink no-hover" onClick={() => {
                                                    this.setState({showFormModalModal: true})
                                                }} style={{paddingLeft: "0px"}}>
                                                    <span className="header-post-button">{showButtonTitles &&
                                                        showButtonTitles ? "Submit" : "Submit"}</span>
                                                </button>
                                            </li>
                                        }
                                        {
                                            !isApp && !isNewOrEditPage && activeTab === "library" && (isAdmin(this.props.user) || isTeamLead(this.props.user)) && this.state.showButtonInNav &&
                                            <li className="">
                                                <button className="ButtonLink no-hover" onClick={this.goToNewDoc}
                                                        style={{paddingLeft: "0px"}}>
                                                    <span className="header-post-button">{showButtonTitles &&
                                                        showButtonTitles ? "New Article" : "New"}</span>
                                                </button>
                                            </li>
                                        }
                                        <li>
                                            <HeadwayWidget/>
                                        </li>
                                        <li className="">
                                            <button className="ButtonLink no-hover" onClick={this.enableFreshdesk}>
                                                <QuestionCircleOutlined
                                                    style={{color: "white", fontSize: "20px", marginTop: "5px"}}/>
                                            </button>
                                        </li>
                                        {
                                            (isAdmin(this.props.user) || isTeamLead(this.props.user) || canManageSpaces(this.props.user)) &&
                                            <li className="">
                                                <button className="ButtonLink no-hover" onClick={this.toggleSettings}>
                                                    <SettingOutlined
                                                        style={{color: 'white', fontSize: "20px", marginTop: "5px"}}/>
                                                </button>
                                            </li>
                                        }
                                        {
                                            this.props.teams.length !== 0 &&
                                            <li className="">
                                                <button className="ButtonLink no-hover" onClick={this.toggleAside}
                                                        onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}
                                                        style={{paddingRight: "12px"}}>
                                                    <Badge className="notification-badge"
                                                           count={this.props.unread_notifications_counter}
                                                           overflowCount={99}>
                                                        <BellOutlined
                                                            style={{
                                                                color: "white",
                                                                fontSize: "20px",
                                                                marginTop: "5px"
                                                            }}/>
                                                    </Badge>
                                                </button>
                                            </li>
                                        }
                                    </OfflineIcon>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </header>

        );

    }
}

class HeadwayWidget extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const HW_config = {
            selector: ".headway-badge",
            account: "7LAjKJ"
        };

        if (!window.Headway) {
            const script = document.createElement("script");
            script.async = true;
            script.src = "https://cdn.headwayapp.co/widget.js";
            document.head.appendChild(script);
            script.onload = function () {
                try {
                    window.Headway.init(HW_config);
                } catch (err) {
                    console.log('Unable to fetch Changelog.');
                }
            }
        } else {
            try {
                window.Headway.init(HW_config);
            } catch (err) {
                console.log('Unable to fetch Changelog2.');
            }
        }
    }

    render() {
        return (
            <button className="ButtonLink no-hover headway-badge " id="headway-badge" style={{position: "relative"}}>
                <img src={logoSmall} alt="Zerologo" style={{height: "21px", marginTop: "4px"}}/>
            </button>
        )
    }
}

const mapStateToProps = store => {
    return {
        unread_notifications: safe_get(store, "notifications.notifications", []),
        unread_notifications_counter: safe_get(store, "notifications.unread_notifications_counter", 0),
        assignments_count: safe_get(store, "dashboard.assignmentsCount", 0),
        organization: safe_get(store, "org_helper.organization", {}),
        organizations: safe_get(store, "org_helper.organizations", []),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        user: safe_get(store, "user.user", {}),
        incidents_locations: safe_get(store, "incidents.locations", []),
        current_location: safe_get(store, "incidents.location", {}),
        isDesktopSize: safe_get(store, "app.desktopSize", true),
    }
}

export default withRouter(connect(mapStateToProps)(Header));

