// @ts-check

import { FORM_ELEMENTS } from "./Constants";
import { reorderFormFieldsWithSection } from "./forms";

export const AgGridFilter = {
    text: {
        filter: true,
        filterParams: {
            maxNumConditions: 1,
        },
    },
    textNoBlankOptions: {
        filter: true,
        filterParams: {
            maxNumConditions: 1,
            filterOptions: [
                "contains",
                "notContains",
                "equals",
                "notEqual",
                "startsWith",
                "endsWith",
            ],
        },
    },
    textNullable: {
        filter: true,
        filterParams: {
            maxNumConditions: 1,
            filterOptions: [
                "contains",
                "notContains",
                "equals",
                "notEqual",
                "startsWith",
                "endsWith",
                {
                    displayKey: 'null',
                    displayName: 'Blank',
                    numberOfInputs: 0,
                    predicate: () => true,
                },
                {
                    displayKey: 'notNull',
                    displayName: 'Not blank',
                    numberOfInputs: 0,
                    predicate: () => true,
                },
            ],
        },
    },
    date: {
        filter: "agDateColumnFilter",
        filterParams: {
            maxNumConditions: 1,
        },
    },
    contains: {
        filter: true,
        filterParams: {
            maxNumConditions: 1,
            filterOptions: ["contains", "notContains"],
        },
    },
};

export function getFormFieldsForDataGrid(form) {
    if (!form) {
        return {
            fields: [],
            headers: [],
        };
    }
    const fields = reorderFormFieldsWithSection(form.fields)
        .filter(field => FORM_ELEMENTS.forTableView.includes(field.element));
    const headers = fields.map(field => {
        const isCentered = FORM_ELEMENTS.centeredElements.includes(field.element);
        const isFilterable = FORM_ELEMENTS.filterable.includes(field.element);
        /** @type {AgGrid.ColDef} */
        const def = {
            headerName: field.label,
            headerTooltip: field.label,
            field: field.id,
            cellClass: field.element === 'DatePicker' ? "dateFormatterNoTime" : undefined,
            width: isCentered ? undefined : 200,
            sortable: false,
            filter: isFilterable,
            filterParams: {
                maxNumConditions: 1,
                filterOptions: [
                    {
                        displayKey: "formField",
                        displayName: "Contains",
                        predicate: () => true,
                    }
                ]
            },
            tooltipValueGetter: (p) => {
                if (p.location === "cell") {
                    if (!p.value || p.value === '-') {
                        return null;
                    }
                    return p.value;
                }
            },
        };

        return def;
    });
    
    return {
        fields,
        headers,
    }
}