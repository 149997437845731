import {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'

import {create_form, get_form_templates, create_pre_made_form} from '../../api/zero-api';
import {CompleteFormBuilderItems} from '../../other/Config.js'
import {
    scrollToTop,
    mainContentContainerClass,
    safe_get,
    generateUUID,
    hideFooterMobileApp,
    showFooterMobileApp
} from '../../other/Helper.js';

import {GlobalOutlined, LeftCircleOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons'

import ButtonLoading from '../Shared/ButtonLoading.js';
import LoadingIndicator from '../Shared/LoadingIndicator.js';

import FormPreviewModal from '../AdminPages/ManageForms/FormPreviewModal.js';
import { ZeroContext } from 'components/ZeroContext';
import { FormType } from 'other/Constants';
import Button from 'components/Shared/Button';
import { Input } from 'antd';

function filterTemplates(templates, preMadeFilter) {
    let filteredTemplates = [...templates];
    if (preMadeFilter) {
        filteredTemplates = filteredTemplates.filter(
            form => form.name.toLowerCase().includes(preMadeFilter.toLowerCase())
        );
    }
    return filteredTemplates;
}

class NewForm extends Component {
    static contextType = ZeroContext;

    constructor(props) {
        super(props);

        /** @type {ZeroContext} */
        this.context;

        this.state = {
            preMadeFilter: "",
            preMadeLoading: false,
            loading: true,
            preMadePage: 1,
            maxPreMadePage: 100,
            form: {},
            templates: [],
            filteredTemplates: [],
        };

    }

    componentDidMount() {
        let self = this;

        scrollToTop("page-head");

        let form_type = 0

        if (this.props.location.search) {
            const params = new URLSearchParams(this.props.location.search);
            const form_title = unescape(params.get('form_title') || '');
            form_type = params.get('form_type');
            const selected_teams = params.get('selected_teams');
            let category = params.get('category_uuid');
            if (category === 'undefined') {
                category = null;
            }
            const admin_restricted = params.get('admin_restricted') === 'true';
            const restrict_user_edits = params.get('restrict_user_edits') === 'true';
            const show_scores_while_editing = params.get('show_scores_while_editing') === 'true';

            const formTypeInt = parseInt(form_type, 10);

            let baseFormTypeUrl = 'manage_forms_templates';
            if (formTypeInt === FormType.LMS) {
                baseFormTypeUrl = 'manage_courses';
            } else if (formTypeInt === FormType.INCIDENT) {
                baseFormTypeUrl = 'manage_incident_templates';
            } else if (formTypeInt === FormType.POST) {
                baseFormTypeUrl = 'manage_post_templates';
            }

            this.setState({
                form_title,
                form_title_edit: form_title,
                form_type: formTypeInt,
                selected_teams,
                category,
                admin_restricted,
                restrict_user_edits,
                baseFormTypeUrl,
                show_scores_while_editing,
            }, () => {
                this.getPreMadeTemplates();
            });
        }
    }
    
    getPreMadeTemplates = () => {
        let type = ""
        switch (this.state.form_type) {
            case FormType.REGULAR:
                type = "regular";
                break;
            case FormType.INCIDENT:
                type = "incident";
                break;
            case FormType.LMS:
                type = "lms";
                break;
            case FormType.POST:
                type = "post_embedded";
                break;
        }

        const queryParams = new URLSearchParams();
        queryParams.set('page', this.state.preMadePage);
        if (type) {
            queryParams.set('form_types', type);
        }

        get_form_templates(`?${queryParams}`)
            .then(res => res.json())
            .then(data => {
                const {forms, total, max_results: maxResults} = data;
                const templates = [...this.state.templates, ...forms];
                const filteredTemplates = filterTemplates(templates, this.state.preMadeFilter);
                this.setState({
                    templates,
                    filteredTemplates,
                    maxPreMadePage: Math.ceil(total / maxResults),
                    loading: false,
                    preMadeLoading: false,
                });
            });
    }

    setPreMadeFilter = (e) => {
        const preMadeFilter = e.currentTarget.value;
        const filteredTemplates = filterTemplates(this.state.templates, preMadeFilter);
        this.setState({preMadeFilter, filteredTemplates});
    }

    loadMorePreMadeTemplates = () => {
        this.setState({preMadePage: this.state.preMadePage + 1, preMadeLoading: true}, () => {
            this.getPreMadeTemplates();
        })
    }

    createForm = () => {
        let {form_title, form_type, selected_teams, category} = this.state;

        let schedule_only = false;
        if (form_type === 1) {
            form_type = 0;
            schedule_only = true;
        }

        let form_fields = []
        let toolbar_items = CompleteFormBuilderItems(form_type);

        let default_item = toolbar_items.find(item => item.element === "Header");
        default_item = {...default_item}
        default_item["id"] = generateUUID();
        delete default_item["icon"];
        form_fields.push(default_item);

        default_item = toolbar_items.find(item => item.element === "RadioButtons");
        default_item = {...default_item}
        default_item["id"] = generateUUID();
        delete default_item["icon"];
        form_fields.push(default_item);

        let team_uuids = selected_teams;
        if (typeof (team_uuids) === 'string') {
            team_uuids = team_uuids.split(',').map(uuid => uuid.trim());
        }

        var body = JSON.stringify({
            name: form_title,
            fields: form_fields,
            enabled: true,
            team_uuids: team_uuids,
            form_type: form_type,
            schedule_only: schedule_only,
            category_uuid: category,
            admin_restricted: this.state.admin_restricted,
            restrict_user_edits: this.state.restrict_user_edits,
            show_scores_while_editing: this.state.show_scores_while_editing,
        });

        create_form(body).then((success) => {
            success.json().then(success => {
                let form_uuid = safe_get(success, "form.form_uuid", "");
                this.context.services.forms.syncForm(form_uuid).finally(() => {
                    this.props.history.replace(`/${this.props.org_uuid}/home/${this.state.baseFormTypeUrl}/edit_form/${form_uuid}`)
                })
            });
        }, function (error) {
            console.log(error)
        });
    }

    createPreMade = (form_uuid) => {
        this.setState({creatingPreMade: true});

        let {form_title, selected_teams, form_type, category} = this.state;

        let schedule_only = false;
        if (form_type === 1) {
            form_type = 0;
            schedule_only = true;
        }

        let team_uuids = selected_teams;
        if (typeof (team_uuids) === 'string') {
            team_uuids = team_uuids.split(',').map(uuid => uuid.trim());
        }

        let body = JSON.stringify({
            name: form_title,
            team_uuids: team_uuids,
            form_type: form_type,
            schedule_only: schedule_only,
            category_uuid: category,
            admin_restricted: this.state.admin_restricted,
            restrict_user_edits: this.state.restrict_user_edits,
            show_scores_while_editing: this.state.show_scores_while_editing,
        });

        create_pre_made_form(form_uuid, body).then((success) => {
            success.json().then(success => {
                let form_uuid = safe_get(success, "form.form_uuid", "");
                this.context.services.forms.syncForm(form_uuid).finally(() => {
                    this.props.history.replace(`/${this.props.org_uuid}/home/${this.state.baseFormTypeUrl}/edit_form/${form_uuid}`)
                });
            });
        }, function (error) {
            console.log(error)
            this.setState({creatingPreMade: false});
        });
    }

    previewTemplate = (template) => {
        this.setState({
            showFormPreview: true,
            previewTemplate: template
        });
    }

    onInputChange = (e) => {
        this.setState({form_title_edit: e.target.value});
    }

    toggleTitleEdit = () => {
        if (this.state.editTitle) {
            this.setState({form_title: this.state.form_title_edit});
        }
        this.setState({editTitle: !this.state.editTitle});
    }

    render() {

        return (
            <div className={mainContentContainerClass()}>

                {
                    this.state.showFormPreview &&
                    <FormPreviewModal
                        show={this.state.showFormPreview}
                        cancel={() => {
                            this.setState({showFormPreview: false})
                        }}
                        title={this.state.previewTemplate.name}
                        fields={this.state.previewTemplate.fields}
                        is_builder={false}
                        form_uuid={null}
                    />
                }

                <div id="page-head" className="no-padding-mobile" style={{padding: "0px"}}>
                    {/* <div className="row">                                                                   
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0" >                                  
                            <div id="page-title" style={{padding: '0px'}}>                                                                
                                <div className="panel thin-border" style={{maxWidth: '850px', margin: '0 auto'}}>
                                    <div className="pad-all">                   
                                        <div className="team-name-header">
                                                                                                                                 
                                            <div className="form-group" style={{marginBottom: '0px' }}>
                                                <input name="post_title" type="text" placeholder="Form Title" className="form-control custom-placeholder" maxLength="100" value={this.state.form_title} onFocus={textareafocus} onBlur={textareablur} onChange={this.onInputChange}/>                        
                                                {
                                                    this.state.titleError &&
                                                    <small className="error">{this.state.titleError}</small>
                                                }
                                            </div>
                                                                                                      
                                        </div>                                                                                                                                                                                                                                                       
                                    </div>
                                </div>                                                                                                            
                            </div> 
                        </div>                                                                          
                    </div> */}
                </div>

                <div id="page-content" className="no-padding-mobile-account">
                    {
                        this.state.loading &&
                        <LoadingIndicator/>
                    }
                    {
                        !this.state.loading &&
                        <div className="panel thin-border pad-all">
                            {
                                !this.state.editTitle &&
                                <div>
                                    <h4 className="zero-blue mar-rgt-5"
                                        style={{display: "inline-block"}}>{this.state.form_title}</h4><EditOutlined
                                    className="zero-blue" style={{cursor: "pointer"}} onClick={this.toggleTitleEdit}/>
                                </div>
                            }
                            {
                                this.state.editTitle &&
                                <div>
                                    <input type="text" placeholder="Untitled form"
                                           className="form-control mar-btm-10 mar-rgt-5" maxLength="100"
                                           value={this.state.form_title_edit} onFocus={hideFooterMobileApp}
                                           onBlur={showFooterMobileApp} onChange={this.onInputChange}
                                           style={{display: "inline-block", width: "55%"}}/>
                                    <div style={{display: "inline-block", verticalAlign: "top", float: "right"}}>
                                        <button className="btn btn-discard mar-rgt-5" style={{display: "inline-block"}}
                                                onClick={() => {
                                                    this.setState({
                                                        editTitle: false,
                                                        form_title_edit: this.state.form_title
                                                    });
                                                }}>Cancel
                                        </button>
                                        <button className="btn btn-discard" style={{display: "inline-block"}}
                                                onClick={this.toggleTitleEdit}>Save
                                        </button>
                                    </div>

                                </div>
                            }

                            {
                                !this.state.showTemplates &&
                                <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                        <div className="panel reports mar-btm-0" onClick={this.createForm}>
                                            <div className="panel-body">
                                                <GlobalOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                                <h3>Start from scratch</h3>
                                                <p className="zero-dark-grey">Create your own custom template from
                                                    scratch.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-sm-6">
                                        <div className="panel reports mar-btm-0" onClick={() => {
                                            this.setState({showTemplates: true});
                                        }}>
                                            <div className="panel-body">
                                                <GlobalOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                                <h3>Start from template</h3>
                                                <p className="zero-dark-grey">Choose from a list of pre-loaded templates
                                                    to get you started.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                this.state.showTemplates &&
                                <div>
                                    <button className="ButtonLink mar-btm-5" style={{color: "#505050"}} onClick={() => {
                                        this.setState({showTemplates: false});
                                    }}>
                                        <LeftCircleOutlined style={{color: "#505050", fontSize: "15px"}}/> <span
                                        className="link-hover" style={{fontWeight: "700"}}>Back</span>
                                    </button>
                                    <Input
                                        className="mar-btm-10"
                                        value={this.state.preMadeFilter}
                                        onChange={this.setPreMadeFilter}
                                        placeholder="Search..."
                                        allowClear
                                    />
                                    {
                                        this.state.filteredTemplates.map((template, index) => (
                                            <div key={index} className="panel reports no-hover mar-btm-10" style={{
                                                backgroundColor: "#F6F6F6",
                                                border: "1px solid #ddd",
                                                height: "auto"
                                            }}>
                                                <div className="panel-body">
                                                    {/* <GlobalOutlined className="zero-blue" style={{ fontSize: "20px" }} /> */}
                                                    <div style={{display: "inline-block"}}>
                                                        <h3>{template.name}</h3>
                                                        <p className="zero-dark-grey">
                                                            {template.field_count} {template.field_count === 1 ? "question" : "questions"}
                                                            <button className="ButtonLink zero-blue link-hover"
                                                                    onClick={() => {
                                                                        this.previewTemplate(template);
                                                                    }}><EyeOutlined/></button>
                                                        </p>
                                                    </div>
                                                    <div style={{float: "right"}}>
                                                        <button className="btn btn-primary" onClick={() => {
                                                            this.createPreMade(template.form_uuid)
                                                        }} disabled={this.state.creatingPreMade}>
                                                            {
                                                                this.state.creatingPreMade ?
                                                                    <ButtonLoading/> : "Select Template"
                                                            }
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="flex flex-justify-center">
                                        <Button
                                            type="primary"
                                            loading={this.state.preMadeLoading}
                                            disabled={
                                                this.state.loading
                                                ||this.state.preMadeLoading
                                                || this.state.preMadePage >= this.state.maxPreMadePage
                                            }
                                            onClick={this.loadMorePreMadeTemplates}
                                        >Load More</Button>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                </div>


            </div>

        );
    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
    }
}

export default withRouter(connect(mapStateToProps)(NewForm));
