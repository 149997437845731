// @ts-check

import ResetGridLink from "components/Shared/DataGrid/ResetGridLink";
import { useFeedDataGridContext } from "./FeedDataGrid.context";
import { EventNames } from "./FeedDataGrid.utils";

const defaultSortModel = [
    {
        colId: "revised_at",
        sort: "desc",
    },
];

export default function ResetFeedGridLink() {
    const { settings } = useFeedDataGridContext();

    return (
        <ResetGridLink
            settings={settings}
            resetTableEventName={EventNames.RESET_TABLE}
            defaultSortModel={defaultSortModel}            
        />
    );
}
