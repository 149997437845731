// @ts-check

import { Checkbox } from "antd";
import { update_form_public_settings } from "api/zero-api";
import InfoIconComponent from "components/InfoIconComponent";
import NotificationAlert from "other/NotificationAlert";
import { useRef, useState } from "react";

export default function PublicFormSettings({form}) {
    const [nameRequired, setNameRequired] = useState(form.public_require_name);
    const [emailRequired, setEmailRequired] = useState(form.public_require_email);
    const updateTimeoutRef = useRef(null);
    const freezeInputRef = useRef(false);

    const scheduleUpdate = (body) => {
        if (updateTimeoutRef.current) {
            clearTimeout(updateTimeoutRef.current);
        }
        updateTimeoutRef.current = setTimeout(() => {
            freezeInputRef.current = true;
            update_form_public_settings(form.form_uuid, body)
                .then((response) => response.json())
                .then((form) => {
                    setNameRequired(form.public_require_name);
                    setEmailRequired(form.public_require_email);
                })
                .catch(err => {
                    console.error(err);
                    NotificationAlert('error', '', "Could not update template's public settings.");
                })
                .finally(() => {
                    freezeInputRef.current = false;
                });
        }, 250);
    }

    const handleCheckboxChange = (e) => {
        if (freezeInputRef.current === false) {
            const checked = e.target.checked;
            const name = e.target.name;
            const body = {
                public_require_name: nameRequired,
                public_require_email: emailRequired,
            };
            if (name === 'name') {
                setNameRequired(checked);
                body.public_require_name = checked;
            } else {
                setEmailRequired(checked);
                body.public_require_email = checked;
            }
            scheduleUpdate(body);
        }
    }

    return (
        <>
            <Checkbox
                name="name"
                checked={nameRequired}
                onChange={handleCheckboxChange}
            >
                Require a name for public users{/*<InfoIconComponent text="Adam puts text here" />*/}
            </Checkbox>
            <Checkbox
                name="email"
                checked={emailRequired}
                onChange={handleCheckboxChange}
            >
                Require an email for public users{/*<InfoIconComponent text="Adam puts text here" />*/}
            </Checkbox>
        </>
    )
}