// @ts-check

import { useMemo } from "react";
import { useOfflineDataCachesContext } from "../OfflineDataCaches";
import SubmissionSyncErrorAlert from "./SubmissionSyncErrorAlert";

export default function SubmissionSyncErrorAlertList() {
    const {submissionDrafts: {drafts}, isSyncing} = useOfflineDataCachesContext();

    const draftsWithErrors = useMemo(() => {
        return drafts.filter(draft => draft.$error);
    }, [drafts]);

    if (draftsWithErrors.length === 0 || isSyncing) {
        return null;
    }

    return draftsWithErrors.map(submission => (
        <SubmissionSyncErrorAlert
            key={submission._id}
            submission={submission}
            showTemplate
        />
    ));
}