// @ts-check
import { CheckCircleOutlined, CloudDownloadOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Dropdown, Tag } from "antd";
import { dateFormatterNoTime, formatName, isAdminOrTeamLead, isPublicUser, safe_get } from "other/Helper";
import { IoIosMore as MoreIcon } from 'react-icons/io';
import { Link } from "react-router-dom";
import { getPublicUserDisplayName, getSubmissionLink } from "./SubmissionDataGrid.utils";
import useOrganizationId from "hooks/useOrganizationId";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useCurrentUser } from "hooks/reduxHooks";
import { useSubmissionDataGridContext } from "./SubmissionDataGrid.context";
import ButtonLink from "components/Shared/ButtonLink";

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function SubmissionActionCell({data: submission}) {
    const user = useCurrentUser();
    const {setActiveModal} = useSubmissionDataGridContext();

    if (!submission) {
        return null;
    }

    const userOwnsSubmission = user.uuid === submission.created_by.user_uuid;
    const restrictUserEdits = safe_get(submission, "form.restrict_user_edits", false);
    const userCanEditOwnSubmission = !restrictUserEdits || isAdminOrTeamLead(user);

    // can edit if user created this submission and restrict user edits is disabled, OR is an admin or team lead
    const submissionEditIsEnabled = (userOwnsSubmission && userCanEditOwnSubmission) || isAdminOrTeamLead(user);
    // can delete if user created this submission and restrict user edits is disabled, OR is an admin
    const submissionDeleteIsEnabled = (userOwnsSubmission && userCanEditOwnSubmission) || isAdminOrTeamLead(user);

    const canExport = userOwnsSubmission || isAdminOrTeamLead(user);

    const handleMenuItemClicked = ({key}) => {
        setActiveModal({
            key,
            submission
        });
    }

    const tableMenu = {
        onClick: handleMenuItemClicked,
        items: [
            canExport && {
                key: "export_submission",
                icon: <CloudDownloadOutlined className="mar-rgt-5" />,
                label: "Export",
            },
            {
                key: "edit_submission",
                icon: <EditOutlined />,
                label: "Edit",
                disabled: !submissionEditIsEnabled,
            },
            {
                key: "delete_submission",
                icon: <DeleteOutlined />,
                label: "Delete",
                className: submissionDeleteIsEnabled ? "zero-delete-red" : "",
                disabled: !submissionDeleteIsEnabled,
            },
        ].filter((item) => !!item),
    };

    return (
        <div className="more pull-right" style={{ display: "inline-block", color: "grey", lineHeight: 0}}>
            <Dropdown menu={tableMenu} trigger={["click"]} placement="bottomLeft">
                <button className="ButtonLink ant-dropdown-link">
                    <MoreIcon style={{ height: "20px", width: "20px" }} />
                </button>
            </Dropdown>
        </div>
    );
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function SubmissionNameCell({data: submission, value: createdBy, api}) {
    const history = useHistory();
    const orgId = useOrganizationId();

    let fullName = formatName(createdBy, {defaultName: ""});

    if (!submission) {
        return fullName;
    }

    if (isPublicUser(submission.created_by)) {
        fullName = getPublicUserDisplayName(submission);
    }

    const linkUrl = getSubmissionLink(submission, orgId);

    const onClick = () => {
        history.replace(history.location.pathname, {gridState: api.getState()});
    }

    return <Link
        to={linkUrl}
        className="blue-link"
        onClick={onClick}
    >{fullName}</Link>
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function SubmissionStatusTag(props) {
    const { value: status } = props;

    const style = {
        fontWeight: "normal",
        verticalAlign: "bottom",
    };

    switch (status) {
        case "submitted":
            return <Tag style={style}>Submitted</Tag>;
        case "in_review":
            return (
                <Tag style={style} color="gold">
                    In Review
                </Tag>
            );
        case "closed":
            return (
                <Tag style={style} color="green">
                    <CheckCircleOutlined /> Closed
                </Tag>
            );
        default:
            return <Tag>N/A</Tag>;
    }
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function SubmissionCommentsCell(props) {
    const {value: comments, data: submission} = props

    const {setActiveModal} = useSubmissionDataGridContext();

    if (comments === null || comments === undefined) {
        return null;
    }

    if (comments === 0) {
        return '--';
    }

    const clickHandler = () => {
        setActiveModal({
            key: 'comments',
            submission,
        });
    }

    return <ButtonLink onClick={clickHandler}>Yes ({comments})</ButtonLink>
}