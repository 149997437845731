import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { EditOutlined } from '@ant-design/icons';

import { get_form, get_form_analytics_fields, get_simple_form_submissions, update_form } from "api/zero-api";
import Button from "components/Shared/Button";
import LoadingIndicator from "components/Shared/LoadingIndicator";
import { BorderedPanel, MainContent, PageContent, PageHead } from "components/Shared/PageParts";
import { useAsyncDataLoader } from "hooks/useAsyncDataLoader";
import { dateFormatterWithTime, hideFooterMobileApp, safe_get, showFooterMobileApp } from "other/Helper";
import NotificationAlert from "other/NotificationAlert";
import { Tabs } from "antd";
import FormAnalytics from "./FormAnalytics";
import FormBuilderComponent from "components/Forms/FormBuilderComponent";


/**
 * @param {{
 *  form: object,
 *  onSaveTitle: (newTitle: string) => void
 * }} props
 * @returns {JSX.Element}
 */
function EditableFormTitle({ form, onSaveTitle }) {
    const [formTitle, setFormTitle] = useState(form.name);
    const [isEditing, setIsEditing] = useState(false);

    const reset = () => {
        setFormTitle(form.name);
        setIsEditing(false);
    }

    const save = () => {
        onSaveTitle(formTitle);
        setIsEditing(false);
    }

    if (!isEditing) {
        return (
            <h3 className="page-title zero-blue">
                {form.name}&nbsp;
                <EditOutlined
                    className="zero-blue"
                    style={{cursor: "pointer", fontSize: '70%'}}
                    onClick={() => setIsEditing(true)}
                />
            </h3>
        )
    }

    return (
        <div style={{display: "flex", alignItems: "baseline", gap: '0.5rem'}}>
            <input 
                type="text"
                placeholder="Untitled form"
                className="form-control"
                maxLength="100"
                value={formTitle}
                onFocus={hideFooterMobileApp}
                onBlur={showFooterMobileApp}
                onChange={e => setFormTitle(e.target.value)}
                style={{display: "inline-block", width: "55%"}}
            />
            <Button type={'discard'} small onClick={reset}>Cancel</Button>
            <Button type={'primary'} small onClick={save}>Save</Button>
        </div>
    )
}

export default function ManageFormPage() {
    const { form_uuid, tabName } = useParams();
    const history = useHistory();
    const formLoader = useAsyncDataLoader();
    const analyticsLoader = useAsyncDataLoader();
    const [isSaving, setIsSaving] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    const loadForm = (form) => {
        formLoader.load(async () => {
            if (form) {
                return form;
            }
            const res = await get_form(form_uuid);
            const data = await res.json();
            return data.form;
        });
    };

    const loadAnalytics = (teamId) => {
        analyticsLoader.load(async () => {
            const query = new URLSearchParams();
            query.append("form_uuid", form_uuid);
            query.append("period", "all_time");
            if (teamId && teamId !== "all_teams") {
                query.append("team_uuid", teamId);
            }
            const res1 = await get_form_analytics_fields(`?${query}`);
            const analytics = await res1.json();

            const res2 = await get_simple_form_submissions(`?${query}`);
            const submissionStubs = (await res2.json()).submissions;

            return {
                analytics,
                submissionStubs,
            };
        });
    };

    useEffect(() => {
        loadForm();
        loadAnalytics();
    }, [form_uuid])

    const onTabChange = (newTab) => {
        /** @type {string} */
        const path = history.location.pathname;
        if (!tabName) {
            history.replace(`${path}/${newTab}`);
        } else {
            const newPath = path.replace(new RegExp(`${tabName}$`), newTab);
            history.replace(newPath);
        }
    };

    if (formLoader.status !== "success") {
        return <LoadingIndicator />
    }

    const form = formLoader.data;

    const onSaveTitle = (newTitle) => {
        const body = {
            name: newTitle,
            fields: form.fields,
            custom_confirmation_page: form.custom_confirmation_page,
            confirmation_fields: form.confirmation_fields,
            enabled: true,
            form_type: form.form_type,
            schedule_only: form.schedule_only,
            category_uuid: safe_get(form, "category.category_uuid", ""),
            admin_restricted: safe_get(form, "admin_restricted", false),
            restrict_user_edits: safe_get(form, "restrict_user_edits", false),
        };

        update_form(form.form_uuid, JSON.stringify(body))
        .then(res => res.json())
        .then(data => {
            loadForm(data.form);
        })
        .catch(err => {
            console.error(err);
            NotificationAlert("error", "", "Unable to update form title.");
        });

    }

    const onAnalyticsTeamChange = (teamId) => {
        loadAnalytics(teamId);
    }

    return (
        <MainContent>
            <PageHead>
                <BorderedPanel className="zero-blue">
                    <EditableFormTitle form={form} onSaveTitle={onSaveTitle} />
                    
                    <p className="page-description">
                        <span>
                            Last edited by {form.edited_by.full_name} on {dateFormatterWithTime(form.edited_at)}.{" "}
                            { isSaving ? 
                                (
                                    <>Saving...</>
                                ) : (
                                    <>All changes saved.</>
                                )
                            }
                        </span>
                    </p>
                </BorderedPanel>
            </PageHead>
            <PageContent>
                <BorderedPanel className="zero-blue">
                {/* <div className="col-lg-12  col-xs-12 col-xs-offset-0"> */}

                    <Tabs
                        defaultActiveKey={tabName || "analytics"}
                        activeKey={tabName || "analytics"}
                        onChange={onTabChange}
                        tabBarExtraContent={(
                            <>
                                {tabName === "editor" &&
                                    <Button type="primary" onClick={() => setShowPreview(true)}>Preview</Button>
                                }
                            </>
                        )}
                        items={[
                            {
                                key: "analytics",
                                label: "Analytics",
                                children: (
                                    <FormAnalytics
                                        form={form}
                                        analyticsLoader={analyticsLoader}
                                        onTeamChange={onAnalyticsTeamChange}
                                    />
                                ),
                            },
                            {
                                key: "editor",
                                label: "Editor",
                                children: (
                                    <FormBuilderComponent
                                        form_data={form}
                                        form_title={form.title}
                                        edit_mode={true}
                                        onSaveStateChange={setIsSaving}
                                        showFormPreview={showPreview}
                                        setShowFormPreview={setShowPreview}
                                    />
                                )
                            }
                        ]}
                    />
                </BorderedPanel>
            </PageContent>
        </MainContent>
    )
}