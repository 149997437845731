import { Spin } from "antd";
import { useEffect, useRef } from "react";
import { useZeroContext } from "components/ZeroContext";
import { usePostIsSyncing } from "hooks/offlineHooks";
import LogicLessSyncSpinner from "components/Shared/LogicLessSyncSpinner";

/**
 * 
 * @param {{style: import("react").CSSProperties, postSyncCallback?: () => void, forPosts?: boolean}} props
 * @returns 
 */
export default function SyncInProgressSpinner({style, postSyncCallback, forPosts=false}) {
    const zeroContext = useZeroContext();
    const { isOffline } = zeroContext;
    const postService = zeroContext.services.post;
    const postIsSyncing = usePostIsSyncing(postService);
    const isSyncing = forPosts ? postIsSyncing : zeroContext.caches.isSyncing;
    const wasSyncingRef = useRef(isSyncing);

    useEffect(() => {
        if (isSyncing !== wasSyncingRef.current) {
            if (wasSyncingRef.current && typeof postSyncCallback === "function") {
                // finished a sync cycle; run callback
                postSyncCallback();
            }
            wasSyncingRef.current = isSyncing;
        }
    }, [isSyncing, postSyncCallback]);

    if (!isSyncing || isOffline) {
        return null;
    }

    return (
        <LogicLessSyncSpinner style={style} />
    )
}