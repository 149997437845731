import React, {Component, Fragment, useMemo} from 'react';
import {connect} from 'react-redux';
import safe_get from '../../other/SafeGet.js';

import TextareaAutosize from "react-textarea-autosize";

import {Editor} from '@tinymce/tinymce-react';

import {fileUpload, generateUUID, hideLoadingTinyMCE, showLoadingTinyMCE} from '../../other/Helper'
import {Select, DatePicker, Modal, Checkbox, InputNumber} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';

import '../../assets/css/antd-custom.css';
import DynamicListSelector from './Fields/DynamicListSelector.js';

class FormFieldEditorModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            field: {},
            is_audit_field: false,
            conditional_fields: [],
            selected_conditional_field: {},
            selectedListId: null,
        };

        this.handleFieldLabelChange = this.handleFieldLabelChange.bind(this)
        this.handleDisplayHorizontal = this.handleDisplayHorizontal.bind(this);
        this.handleRequired = this.handleRequired.bind(this);
        this.handleConditionalLogic = this.handleConditionalLogic.bind(this);

        //custom logic stuff
        this.handleTrigger = this.handleTrigger.bind(this);
        this.handleTriggerField = this.handleTriggerField.bind(this);
        this.handleTriggerLogic = this.handleTriggerLogic.bind(this);
        this.handleTriggerFieldOption = this.handleTriggerFieldOption.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);

        this.saveField = this.saveField.bind(this);
    }

    componentDidMount() {
        let fields = [...this.props.fields]
        let field = fields[this.props.field_index];

        let disabled_logic_elements = [
            "LineBreak", "Paragraph", "Header", "DatePicker", "TimePicker", "PageBreak", "Signature", "Section", "Attachments", "FileUploads"
        ];

        let conditional_fields = []

        if (field.sectionId) {
            conditional_fields = fields.filter((obj) => {
                let is_not_editing_field = obj.id !== field.id
                let has_no_logic_applied = obj.custom_logic === undefined
                let is_not_disabled_logic = disabled_logic_elements.indexOf(obj.element) < 0
                let is_in_section = obj.sectionId === field.sectionId

                return is_not_disabled_logic && is_not_editing_field && has_no_logic_applied && is_in_section
            });
        } else {
            conditional_fields = fields.filter(function (obj) {
                let is_not_editing_field = obj.id !== field.id
                let is_not_disabled_logic = disabled_logic_elements.indexOf(obj.element) < 0
                let is_not_in_section = !obj.sectionId

                return is_not_disabled_logic && is_not_editing_field && is_not_in_section
            });
        }

        var selected_conditional_field = {}
        if (field.custom_logic !== undefined) {
            let conditional_field = conditional_fields.find(function (obj) {
                return obj.id === safe_get(field, "custom_logic.trigger_field_id", "")
            });
            selected_conditional_field = conditional_field
        }

        if (field.disableAttachments === undefined || field.disableAttachments === null) {
            field["disableAttachments"] = true;
        }

        if (field.audit) {
            field["is_scored"] = true;
        }

        fields.map(field => {
            if (['RadioButtons', 'Dropdown'].includes(field.element)) {
                if (safe_get(field, 'options', []).length > 0) {
                    field.options.map(option => {
                        if (option.is_failure === undefined) {
                            if (field.audit && option.value === 'no') {
                                option.is_failure = true;
                            } else {
                                option.is_failure = false;
                            }
                        }
                    });
                }
            }
        });

        this.setState({
            field: field,
            is_audit_field: field.audit || field.text.includes("audit"),
            conditional_fields: conditional_fields,
            selected_conditional_field: selected_conditional_field
        });

    }

    handleFieldLabelChange(event) {
        const target = event.target;
        const value = target.value;

        var field = {...safe_get(this.state, "field", {})}
        var element = field.element
        if (element === "Header" || element === "Paragraph" || element === "FileUploads") {
            field["content"] = value
        } else {
            field["label"] = value;
        }
        field["edited"] = true;
        this.setState({field: field});
    }

    handleEditorChange = (content) => {
        var field = {...safe_get(this.state, "field", {})};
        field["content"] = content;
        this.setState({field: field});
    }

    handleFileUpload(file, cb) {
        var self = this;

        var params = JSON.stringify({
            feature_key: "forms_template",
            form_uuid: this.props.form_uuid,
            field_uuid: this.state.field.id,
            content_type: file.type,
            file_name: file.name,
            embedded: true
        });

        file["form_embedded"] = true;

        showLoadingTinyMCE();

        fileUpload(this, params, file, function (progress) {
            self.setState({progress: progress});
        }, function (pre_signed_url, file_key) {
            cb(pre_signed_url + "/" + file_key, {title: file.name, text: file.name});
            hideLoadingTinyMCE();
        }, function (error) {
            hideLoadingTinyMCE();
        });
    }

    handleOptionsInputChange = (event, index) => {
        const target = event.target;
        const value = target.value;

        var field = {...safe_get(this.state, "field", {})};
        var options = [...safe_get(field, "options", [])]

        if (options.length > 0) {
            var option = options[index]
            option["text"] = value

            options[index] = option
            field["options"] = options
            field["edited"] = true;

            this.setState({field: field});
        }
    }

    handleOptionsScoreBlur = (event, index) => {
        let field = {...safe_get(this.state, "field", {})};
        let options = [...safe_get(field, "options", [])]

        if (options.length > 0) {
            let option = options[index]
            if (option.score === '') {
                delete option['score'];

                options[index] = option
                field["options"] = options
                field["edited"] = true;

                this.setState({field: field});
            }

        }
    }

    handleOptionsFailureChange = (event, index) => {
        let checked = event.target.checked;

        let field = {...safe_get(this.state, "field", {})};
        let options = [...safe_get(field, "options", [])]

        if (options.length > 0) {
            let option = options[index]
            option["is_failure"] = checked;

            options[index] = option
            field["options"] = options
            field["edited"] = true;

            this.setState({field: field});
        }
    }

    handleOptionsScoreChange = (value, index) => {

        let field = {...safe_get(this.state, "field", {})};
        let options = [...safe_get(field, "options", [])]

        if (options.length > 0) {
            let option = options[index]
            option["score"] = value

            options[index] = option
            field["options"] = options
            field["edited"] = true;

            this.setState({field: field});
        }
    }

    handleRemoveOption = (index) => {
        var field = {...safe_get(this.state, "field", {})};
        var options = [...safe_get(field, "options", [])]

        if (options.length > 1 && index >= 0) {
            options.splice(index, 1)
            field["options"] = options;
            field["edited"] = true;
            this.setState({field: field});
        }
    }

    handleAddOption = () => {
        var field = {...safe_get(this.state, "field", {})};
        var options = [...safe_get(field, "options", [])]

        let uuid = generateUUID();

        var new_option = {
            key: field.element.toLowerCase() + "_option_" + uuid,
            text: "",
            value: "place_holder_option_" + uuid
        }

        options.push(new_option)
        field["options"] = options
        this.setState({field: field});
    }

    handleDisplayHorizontal() {
        var field = {...safe_get(this.state, "field", {})};
        field["canHaveDisplayHorizontal"] = !field.inline
        field["inline"] = !field.inline;
        field["edited"] = true;

        this.setState({field: field});
    }

    handleAllowAttachment = () => {
        var field = {...safe_get(this.state, "field", {})};
        field["disableAttachments"] = !field.disableAttachments;
        field["edited"] = true;
        this.setState({field: field});
    }

    handleScoring = () => {
        let field = {...safe_get(this.state, "field", {})};
        field["is_scored"] = !field.is_scored;
        field["edited"] = true;
        this.setState({field: field});
    }

    handleRequired() {
        var field = {...safe_get(this.state, "field", {})};
        field["required"] = !field.required;
        field["edited"] = true;
        this.setState({field: field});
    }

    handleConditionalLogic() {
        var field = {...safe_get(this.state, "field", {})};
        if (field.custom_logic) {
            delete field.custom_logic
        } else {
            field["custom_logic"] = {
                trigger_result: "show",
                trigger_logic: "is"
            }
        }
        field["edited"] = true;

        this.setState({field: field});
    }

    handleTrigger(value) {
        var field = {...safe_get(this.state, "field", {})};
        var custom_logic = field.custom_logic
        custom_logic["trigger_result"] = value
        field["custom_logic"] = custom_logic
        field["edited"] = true;

        this.setState({field: field});
    }

    handleTriggerField(value) {
        var field = {...safe_get(this.state, "field", {})};
        var custom_logic = field.custom_logic
        custom_logic["trigger_field_id"] = value

        var conditional_fields = [...this.state.conditional_fields]
        var conditional_field = conditional_fields.find(function (obj) {
            return obj.id === value
        })

        console.log('conditional_field', conditional_field);

        if (!["TextInput", "NumberInput", "TextArea", "DatePicker", "TimePicker"].includes(conditional_field.element)) {
            custom_logic["trigger_option_id"] = conditional_field.options[0].key
        } else {

        }

        field["custom_logic"] = custom_logic

        this.setState({field: field, selected_conditional_field: conditional_field});
    }

    handleTriggerLogic(value) {
        var field = {...safe_get(this.state, "field", {})};
        var custom_logic = field.custom_logic
        custom_logic["trigger_logic"] = value
        field["custom_logic"] = custom_logic
        this.setState({field: field});
    }

    handleTriggerFieldOption(value) {
        var field = {...safe_get(this.state, "field", {})};
        var custom_logic = field.custom_logic
        custom_logic["trigger_option_id"] = value
        field["custom_logic"] = custom_logic
        this.setState({field: field});
    }

    handleInputChange(e) {
        let value = e.target.value
        var field = {...safe_get(this.state, "field", {})};
        var custom_logic = field.custom_logic
        custom_logic["trigger_value"] = value
        field["custom_logic"] = custom_logic
        this.setState({field: field});
    }

    handleDateChange = (value) => {
        console.log(value)
    }

    saveField() {
        this.props.saveField(this.state.field, this.props.field_index);
        this.props.cancel();
    }

    stripHtml(html) {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    renderTitleText(element) {
        switch (element) {
            case "Header":
                return "Header";
            case "Section":
            case "PageBreak":
                return "Title";
            case "Attachments":
            case "FileUploads":
                return "Question/Title";
            default:
                return "Question";
        }
    }

    tinyMceImageUploadHandler = (blobInfo, success, failure, progress) => {
        const file = blobInfo.blob();
        file["form_embedded"] = true;

        const params = JSON.stringify({
            feature_key: "forms_template",
            form_uuid: this.props.form_uuid,
            field_uuid: this.state.field.id,
            content_type: file.type,
            file_name: blobInfo.filename(),
            embedded: true
        });

        const onSuccess = (preSignedUrl, key) => {
            success(`${preSignedUrl}/${key}`);
        };

        const onFailure = (error) => {
            console.error("tinyMceImageUploadHandler error:", error);
            failure("Could not upload image");
        }

        fileUpload(null, params, file, progress, onSuccess, onFailure);
    }

    render() {
        let self = this;

        let element = this.state.field.element;
        let disabledHorizontal = ["Header", "Paragraph", "Dropdown", "Tags", "TextInput", "NumberInput", "TextArea", "DatePicker", "Section", "Attachments", "TimePicker", "FileUploads", "DynamicListInput", "UserDropdown"]
        let disabledAttachmentFields = ["Header", "DatePicker", "Paragraph", "Signature", "Section", "Attachments", "TimePicker", "FileUploads", "DynamicListInput", "UserDropdown"];
        let disabledScoring = ["Header", "DatePicker", "Paragraph", "Signature", "Tags", "TextInput", "TextArea", "NumberInput", "Section", "Attachments", "TimePicker", "FileUploads", "DynamicListInput", "UserDropdown"];
        let disabledLogic = ["DynamicListInput", "UserDropdown"];

        return (
            (<Modal
                title={"Edit " + this.state.field.text}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                width={700}
                footer={
                    <div style={{height: '33px'}}>
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.saveField}>
                            Save
                        </button>
                    </div>
                }
            >
                {
                    element !== "Paragraph" &&
                    <p className="zero-blue" style={{fontWeight: "600", marginBottom: "5px"}}>
                        {this.renderTitleText(element)}
                    </p>
                }
                {
                    element === "Paragraph" &&
                    <Editor
                        apiKey='k8pzwxerydel2fec8qy1mel2q6ui0m6vduu5r57o1ok5yn1j'
                        init={{
                            content_style: "body { color: #505050; font-size: 13px; font-weight: normal;} img {max-width: 100%;}",
                            menubar: '',
                            branding: false,
                            height: "300",
                            image_dimensions: false,
                            default_link_target: '_blank',
                            file_picker_types: 'file image media',
                            file_picker_callback: function (cb, value, meta) {
                                const input = document.createElement('input');
                                input.setAttribute('type', 'file');

                                if (meta.filetype !== 'file') {
                                    input.setAttribute('accept', '.jpeg, .jpg, .gif, .png, .mov, .mpeg, .mp4, .quicktime');
                                }

                                input.onchange = function () {
                                    var file = this.files[0];
                                    self.handleFileUpload(file, cb)
                                };

                                input.click();
                            },
                            block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4',
                            toolbar1: "undo redo | formatselect | fontsizeselect | bold italic underline strikethrough forecolor backcolor",
                            toolbar2: "alignleft aligncenter alignright alignjustify | bullist numlist | link image media | removeformat ",
                            automatic_uploads: true,
                            images_upload_handler: this.tinyMceImageUploadHandler,
                            paste_data_images: true,
                        }}
                        id="tinymce-editor"
                        value={safe_get(this.state, "field.label", "") || safe_get(this.state, "field.content", "")}
                        onEditorChange={this.handleEditorChange}
                        plugins={"autolink directionality visualblocks visualchars link table charmap hr pagebreak nonbreaking insertdatetime advlist lists wordcount  mediaembed image media textpattern help paste"}
                        media_live_embeds={true}
                    />
                }
                {
                    element !== "Paragraph" &&
                    <TextareaAutosize
                        type="text"
                        value={this.stripHtml(safe_get(this.state, "field.label", "")) || this.stripHtml(safe_get(this.state, "field.content", ""))}
                        className="form-control custom-placeholder"
                        onChange={this.handleFieldLabelChange}
                        placeholder={"Add a title or question..."}
                    />
                }
                <br/>
                {
                    element === 'DynamicListInput' &&
                    <div>
                        <p className="zero-blue" style={{
                            fontWeight: "600",
                            marginBottom: "5px",
                            display: "inline-block",
                            width: '100%'
                        }}>Responses</p>
                        <p className="zero-blue">This field works off of data options you push to ZERO through the ZERO
                            List API. For example, you could push a list of job numbers, current contractors, or any
                            other piece of relevant data. To learn more, please refer to the <a
                                rel="noopener noreferrer"
                                onClick={() => FreshworksWidget('open', 'article', {'id': 70000619648})}
                                className="blue-link">List API Documentation in the help center</a>.</p>
                        <p className="zero-blue mar-btm-5">Select a list below to get started.</p>
                        <DynamicListSelector
                            selectedListId={this.state.field?.listId || null}
                            onListSelected={listId => {
                                this.setState({
                                    field: {
                                        ...this.state.field,
                                        listId
                                    }
                                })
                            }}
                        />
                        <br/>
                    </div>
                }
                {
                    element === 'UserDropdown' &&
                    <div>
                        <p className="zero-blue" style={{
                            fontWeight: "600",
                            marginBottom: "5px",
                            display: "inline-block",
                            width: '100%'
                        }}>Responses</p>
                        <p className="zero-blue">This field will automatically show all users from your account
                            directory as response options. Users can search for and select a colleague from the
                            directory while submitting a form.</p>
                        {this.props.active_user_count === 1 &&
                            <p className="zero-blue">There is 1 active user in your account directory.</p>
                        }
                        {this.props.active_user_count !== 1 &&
                            <p className="zero-blue">There are {this.props.active_user_count} active users in your
                                directory.</p>
                        }
                    </div>
                }
                {
                    !["Paragraph", "Header", "TextArea", "NumberInput", "TextInput", "DatePicker", "Section", "PageBreak", "Attachments", "TimePicker", "FileUploads", "DynamicListInput", "UserDropdown", "Signature"].includes(element) &&
                    <ResponseSection
                        field={this.state.field}
                        isAuditField={this.state.is_audit_field}
                        onOptionsInputChange={this.handleOptionsInputChange}
                        onOptionsScoreBlur={this.handleOptionsScoreBlur}
                        onOptionsScoreChange={this.handleOptionsScoreChange}
                        onOptionsFailureChange={this.handleOptionsFailureChange}
                        onRemoveOption={this.handleRemoveOption}
                        onAddResponse={this.handleAddOption}
                    />
                }
                {
                    !["PageBreak"].includes(element) &&
                    <>
                        <p className="zero-blue" style={{fontWeight: "600", marginBottom: "5px"}}>Settings</p>
                        <div className="checkbox">
                            {
                                this.state.is_audit_field && element === "RadioButtons" && !this.props.is_confirmation &&
                                <Checkbox checked={this.state.field.required} disabled={true}
                                          style={{paddingLeft: "0px"}}>
                                    Require a response
                                </Checkbox>
                            }
                            {
                                this.state.is_audit_field && element !== "RadioButtons" && !this.props.is_confirmation &&
                                <Checkbox checked={this.state.field.required} onChange={this.handleRequired}
                                          disabled={element === "Header" || element === "Paragraph" || element === "LineBreak" || element === "Section" || element === "FileUploads"}
                                          style={{paddingLeft: "0px"}}>
                                    Require a response
                                </Checkbox>
                            }
                            {
                                !this.state.is_audit_field && !this.props.is_confirmation &&
                                <Checkbox checked={this.state.field.required} onChange={this.handleRequired}
                                          disabled={element === "Header" || element === "Paragraph" || element === "LineBreak" || element === "Section" || element === "FileUploads"}
                                          style={{paddingLeft: "0px"}}>
                                    Require a response
                                </Checkbox>
                            }
                        </div>
                        {
                            !this.props.is_confirmation &&
                            <div className="checkbox">
                                <Checkbox
                                    checked={this.state.field.inline}
                                    onChange={this.handleDisplayHorizontal}
                                    disabled={disabledHorizontal.indexOf(element) >= 0}
                                    style={{paddingLeft: "0px"}}
                                >
                                    Display responses horizontally
                                </Checkbox>
                            </div>
                        }
                        {
                            !this.props.is_confirmation &&
                            <div className="checkbox">
                                <Checkbox
                                    checked={["Paragraph", "Header", "Signature", "Section", "FileUploads", "DatePicker", "TimePicker"].includes(element) ? false : !this.state.field.disableAttachments}
                                    onChange={this.handleAllowAttachment}
                                    disabled={disabledAttachmentFields.indexOf(element) >= 0}
                                    style={{paddingLeft: "0px"}}
                                >
                                    Allow attachment or comment
                                </Checkbox>
                            </div>
                        }
                        {
                            !this.props.is_confirmation &&
                            <div className="checkbox">
                                <Checkbox
                                    checked={safe_get(this.state, "field.is_scored", false) || this.state.is_audit_field}
                                    onChange={this.handleScoring}
                                    disabled={disabledScoring.includes(element) || this.state.is_audit_field}
                                    style={{paddingLeft: "0px"}}
                                >
                                    Enable scoring
                                </Checkbox>
                            </div>
                        }

                        {
                            disabledLogic.indexOf(element) < 0 &&
                            <div className="checkbox">
                                <Checkbox
                                    checked={safe_get(this.state, "field.custom_logic", undefined) !== undefined}
                                    onChange={this.handleConditionalLogic}
                                    disabled={safe_get(this.state, "conditional_fields", []).length === 0}
                                    style={{paddingLeft: "0px"}}
                                >
                                    Enable conditional logic
                                </Checkbox>
                            </div>
                        }
                    </>
                }
                {
                    element !== "PageBreak" && !disabledLogic.includes(element) &&
                    safe_get(this.state, "conditional_fields", []).length === 0 &&
                    <p>To enable conditional logic, more fields are required.</p>
                }
                {
                    this.state.field.custom_logic !== undefined && safe_get(this.state, "conditional_fields", []).length > 0 &&
                    <div>
                        <p className="zero-blue" style={{fontWeight: "600", marginBottom: "5px"}}>Conditional Logic</p>
                        <Select
                            placeholder="Display"
                            style={{display: "inline-block", width: "18%", marginRight: "10px"}}
                            dropdownStyle={{zIndex: "100000000"}}
                            onChange={this.handleTrigger}
                            defaultValue={safe_get(this.state.field, "custom_logic.trigger_result", "")}
                        >
                            <Select.Option key={0} value={"show"}>Show</Select.Option>
                            <Select.Option key={1} value={"hide"}>Hide</Select.Option>
                        </Select>
                        <p className="zero-dark-grey" style={{display: "inline-block"}}>this field when</p>
                        <Select
                            placeholder="Select a field"
                            defaultValue={this.state.selected_conditional_field && safe_get(this.state, "field.custom_logic.trigger_field_id", undefined)}
                            style={{display: "block", width: "100%", marginBottom: "5px"}}
                            dropdownStyle={{zIndex: "100000000"}}
                            onChange={this.handleTriggerField}
                        >
                            {
                                safe_get(this.state, "conditional_fields", []).length > 0 &&
                                safe_get(this.state, "conditional_fields", []).map((field, index) => (
                                    <Select.Option field_id={field.id} key={index}
                                                   value={field.id}>{field.label}</Select.Option>
                                ))
                            }

                        </Select>

                        <Select
                            placeholder="Condition"
                            style={{display: "inline-block", width: "18%", marginRight: "2%"}}
                            dropdownStyle={{zIndex: "100000000"}}
                            onChange={this.handleTriggerLogic}
                            defaultValue={this.state.selected_conditional_field && safe_get(this.state.field, "custom_logic.trigger_logic", "")}
                        >
                            <Select.Option key={0} value={"is"}>Is</Select.Option>
                            <Select.Option key={1} value={"is_not"}>Is not</Select.Option>
                        </Select>
                        {
                            ["TextInput", "NumberInput", "TextArea", "DatePicker"].indexOf(safe_get(this.state, "selected_conditional_field.element", "")) < 0 &&
                            <Select
                                placeholder="Option"
                                value={this.state.selected_conditional_field && safe_get(this.state, "field.custom_logic.trigger_option_id", undefined)}
                                style={{display: "inline-block", width: "80%"}}
                                dropdownStyle={{zIndex: "100000000"}}
                                onChange={this.handleTriggerFieldOption}
                            >
                                {
                                    safe_get(this.state, "selected_conditional_field.options", []).map((option, index) => (
                                        <Select.Option key={index} value={option.key}>{option.text}</Select.Option>
                                    ))
                                }

                            </Select>
                        }
                        {
                            safe_get(this.state, "selected_conditional_field.element", "") === "TextInput" &&
                            <input
                                className="form-control"
                                type={"text"}
                                value={safe_get(this.state, "field.custom_logic.trigger_value", "")}
                                placeholder="Text Input"
                                onChange={this.handleInputChange}
                                style={{width: "73%", display: "inline-block"}}
                            />
                        }
                        {
                            safe_get(this.state, "selected_conditional_field.element", "") === "NumberInput" &&
                            <input
                                className="form-control"
                                type={"number"}
                                value={safe_get(this.state, "field.custom_logic.trigger_value", "")}
                                placeholder="Number Input"
                                onChange={this.handleInputChange}
                                style={{width: "73%", display: "inline-block"}}
                            />
                        }
                        {
                            safe_get(this.state, "selected_conditional_field.element", "") === "TextArea" &&
                            <textarea
                                className="form-control"
                                value={safe_get(this.state, "field.custom_logic.trigger_value", "")}
                                onChange={this.handleInputChange}
                                placeholder="Multi-line input"
                                rows={1}
                                style={{width: "73%", display: "inline-block", verticalAlign: "bottom"}}
                            />
                        }
                        {
                            safe_get(this.state, "selected_conditional_field.element", "") === "DatePicker" &&
                            <DatePicker
                                format={"MM/DD/YYYY"}
                                onChange={this.handleDateChange}
                                style={{width: "40%", display: "inline-block"}}
                            />
                        }

                    </div>
                }
            </Modal>)
        );


    }

}

const mapStateToProps = store => {
    return {
        active_user_count: safe_get(store, "org_helper.organization.active_user_count", 0)
    }
}

export default connect(mapStateToProps)(FormFieldEditorModal);

function ResponseSection({
    field,
    isAuditField,
    onOptionsInputChange,
    onOptionsScoreBlur,
    onOptionsScoreChange,
    onOptionsFailureChange,
    onRemoveOption,
    onAddResponse,
}) {
    const element = field?.element ?? "";
    const options = field?.options ?? [];
    const isScored = field?.is_scored ?? false;
    const showFailureColumn = ['RadioButtons', 'Dropdown'].includes(element);

    const minWidths = {
        points: '60px',
        failures: '50px',
        delete: '42px'
    }

    const nonResponseColumnCount = 1 + (isScored ? 1 : 0) + (showFailureColumn ? 1 : 0);

    /** @type {React.CSSProperties} */
    const gridStyle = {
        display: "grid",
        gap: '1rem',
        gridTemplateColumns: `8fr repeat(${nonResponseColumnCount}, 1fr)`,
    }

    // calculate max score
    const maxScore = useMemo(() => {
        if (!field || (!isScored && !field?.audit)) {
            return 0;
        }

        let scores = options.map(opt => safe_get(opt, "score", 0))

        if (element === "Checkboxes") {
            return scores.reduce((a, b) => a + b, 0)
        } else if (field.audit) {
            scores = options.map(opt => safe_get(opt, "score", null))
            let nonNullScores = scores.filter(score => score !== null);
            if (nonNullScores.length == 0) {
                return 1;
            } else {
                return Math.max(...nonNullScores);
            }
        } else {
            return Math.max(...scores)
        }
    }, [field]);

    return (
        <div className="mar-btm-15" style={gridStyle}>
            <div className="text-bold">Responses</div>
            {isScored && <div className="text-bold text-center" style={{width: minWidths.points}}>Points</div> }
            {showFailureColumn && <div className="text-bold text-center" style={{minWidth: minWidths.failures}}>Failed</div>}
            <div style={{minWidth: minWidths.delete}}>&nbsp;</div>
            {options.map((option, index) => (
                <Fragment key={option.key}>
                    <input
                        type="text"
                        value={option.text}
                        placeholder="Add a response..."
                        className="form-control custom-placeholder"
                        onChange={(event) => onOptionsInputChange(event, index)}
                        disabled={isAuditField}
                    />
                    {isScored &&
                        <InputNumber
                            min={0}
                            max={100}
                            value={option.score ?? ((isAuditField && option.value === "yes") ? 1 : 0)}
                            onBlur={event => onOptionsScoreBlur(event, index)}
                            disabled={isAuditField && option.value === "na"}
                            onChange={(value) => onOptionsScoreChange(value, index)}
                            style={{width: minWidths.points}}
                        />
                    }
                    {showFailureColumn &&
                        <div className="flex" style={{minWidth: minWidths.failures, alignItems: 'center', justifyContent: 'center'}}>
                            <Checkbox
                                disabled={isAuditField && option.value === "na"}
                                checked={option.is_failure ?? false}
                                onChange={event => onOptionsFailureChange(event, index)}                                    
                            />
                        </div>
                    }
                    {(!isAuditField || option.key === "radiobuttons_option_na") ? (
                        <button
                            className="btn zero-delete-red"
                            tabIndex="-1"
                            disabled={options.length === 1}
                            onClick={() => onRemoveOption(index)}
                            style={{minWidth: minWidths.delete}}
                        >
                            <DeleteOutlined style={{fontSize: "15px"}}/>
                        </button>
                    ) : (
                        <div style={{minWidth: minWidths.delete}}>&nbsp;</div>
                    )}
                </Fragment>
            ))}
            {isAuditField ? (
                <div>&nbsp;</div>
            ) : (
                <div className="blue-link" style={{fontWeight: "500"}} onClick={onAddResponse}>Add another response</div>
            )}
            {
                (isScored || field?.audit) &&
                <div className="zero-blue text-center" style={{minWidth: minWidths.points}}>Max: {maxScore}</div>
            }
        </div>
    );
}