import Previewer from '../../Previewer';

import { InboxOutlined } from '@ant-design/icons';

import { FileUploadContextManager, FileUploadDragger, FileUploadError, FileUploadList } from 'components/Shared/FileUpload.js';
import ConfirmModal from '../../Shared/ConfirmModal.js';
import { useEffect, useRef, useState } from 'react';

/**
 * 
 * @param {{
 *  attachments: any[],
 *  incident_uuid: string,
 *  fieldsDisabled: boolean,
 *  onAttachmentUploaded: (attachment: any) => void,
 *  onAttachmentDeleted: (attachment: any) => void,
 *  onFinishedUploads: () => void,
 * }} param0 
 * @returns 
 */
export default function IncidentAttachments({
    attachments, incident_uuid, fieldsDisabled,
    onAttachmentUploaded, onAttachmentDeleted, onFinishedUploads,
}) {
    const [indexToDelete, setIndexToDelete] = useState(null);
    const [indexToPreview, setIndexToPreview] = useState(null);
    const hasUploadedFiles = useRef(false);

    const showDeleteConfirmationModal = indexToDelete !== null;
    const showPreview = indexToPreview !== null;

    const confirmDelete = () => {
        onAttachmentDeleted(attachments[indexToDelete]);
        setIndexToDelete(null);
    }

    const generateParamsCallback = file => ({
        feature_key: "incident",
        incident_uuid,
        content_type: file.type || 'application/octet-stream',
        file_name: file.name,
        embedded: false,
    })

    const handleUploadSuccess = (file, preSignedUrl, fileKey) => {
        const attachment = {
            file_path: fileKey,
            file_name: file.name,
            public_url: `${preSignedUrl}/${fileKey}`,
            mime_type: file.type,
            embedded: false,
        };

        hasUploadedFiles.current = true;
        onAttachmentUploaded(attachment);
    }

    const handleUploadError = (error) => {
        console.error(`Error uploading file:`, error);
    }

    const handleFileListChange = (newFileList) => {
        const count = newFileList.length;
        if (count === 0 && hasUploadedFiles.current) {
            onFinishedUploads();
            hasUploadedFiles.current = false;
        }
    }

    return (
        <FileUploadContextManager
            attachments={attachments}
            generateParamsCallback={generateParamsCallback}
            onUploadSuccess={handleUploadSuccess}
            onUploadError={handleUploadError}
            options={{
                onFileListChange: handleFileListChange,
            }}
        >
            <div>
                { showDeleteConfirmationModal &&
                    <ConfirmModal
                        show={true}
                        cancel={() => setIndexToDelete(null)}
                        confirm={confirmDelete}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this attachment?"}
                        confirmButtonName={"Delete"}
                    />
                }

                { showPreview &&
                    <Previewer
                        show={true}
                        close={() => setIndexToPreview(null)}
                        attachments={attachments}
                        index={indexToPreview}
                    />
                }

                <h3 className="zero-blue print-only"
                    style={{marginBottom: "20px", marginTop: "5px", fontSize: "120%", textDecoration: "underline"}}>
                    Attachments
                </h3>

                <FileUploadDragger
                    alwaysShow
                    uploadProps={{
                        className: "library-upload mar-top dont-print",
                        disabled: fieldsDisabled,
                    }}
                >
                    <div>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="zero-dark-grey">Click or drag file to this area to upload</p>
                    </div>
                </FileUploadDragger>

                <FileUploadError />

                <FileUploadList
                    className="mar-top-10"
                    onPreview={setIndexToPreview}
                    onDelete={setIndexToDelete}
                />
            </div>
        </FileUploadContextManager>
    );
}
