// @ts-check
import { AlertOutlined } from '@ant-design/icons';
import { Alert, Popconfirm, Radio, Space, Spin } from "antd";
import ButtonLink from "components/Shared/ButtonLink";
import LogicLessSyncSpinner from "components/Shared/LogicLessSyncSpinner";
import useOrganizationId from "hooks/useOrganizationId";
import { getAttachments, syncLocalDraft, useDraftsCurrentlySyncing } from "offline/SubmissionDraftsCache";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useOfflineDataCachesContext } from "../OfflineDataCaches";
import { dateFormatterWithTime } from 'other/Helper';
import { get_submission } from 'api/zero-api';
import Button from 'components/Shared/Button';
import { processAttachments } from 'offline/utils';

/**
 * @param {{
 *  submission: SubmissionDraft
 *  showTemplate?: boolean;
 *  dontShowSyncConflict?: boolean;
 *  onSyncClicked?: () => void;
 *  onSync?: (success: boolean) => void;
 *  onDelete?: () => void;
 *  onEditClicked?: () => void;
 * }} props 
 */
export default function SubmissionSyncErrorAlert({submission, showTemplate, dontShowSyncConflict, onSyncClicked, onSync, onDelete, onEditClicked}) {
    const submissionId = submission?.submission_uuid;
    const orgId = useOrganizationId();
    const {submissionDrafts: {cache}} = useOfflineDataCachesContext();
    const draftsCurrentlySyncing = useDraftsCurrentlySyncing();
    const [isSyncing, setIsSyncing] = useState(draftsCurrentlySyncing.ids.includes(submissionId));
    const [versionToKeep, setVersionToKeep] = useState(null);
    const [remoteDraft, setRemoteDraft] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        return draftsCurrentlySyncing.subscribe(({detail}) => {
            const {id, action} = detail;
            if (id === submissionId) {
                setIsSyncing(action === "add");
            }
        });
    }, [draftsCurrentlySyncing.subscribe]);

    useEffect(() => {
        async function fetchData() {
            if (submission.$error === 'sync-conflict' && !dontShowSyncConflict) {
                try {
                    const response = await get_submission(submission.submission_uuid);
                    const content = await response.json();
                    setRemoteDraft(content.submission);
                } catch (err) {
                    console.error('Could not load remote draft:', err);
                    setErrorMessage('Could not load remote draft at this time.');
                }
            }
        }

        fetchData();
    }, [submission.$error, submission.submission_uuid, dontShowSyncConflict]);

    const resolveSyncConflict = useCallback(async () => {
        try {
            setIsLoading(true);

            if (versionToKeep === 'local') {
                submission.$error = null;
                await syncLocalDraft(cache, submission);
            } else {
                await cache.delete(submission._id, {immediate: true, includeAttachments: true});
                await cache.set(remoteDraft.submission_uuid, remoteDraft);
                const attachments = getAttachments(remoteDraft);
                await processAttachments(cache, remoteDraft.submission_uuid, attachments);
            }
        } catch (err) {
            console.error('Could not resolve sync conflict:', err);
            setErrorMessage('Could not resolve sync conflict. Please contact support.');
        } finally {
            setIsLoading(false);
        }
    }, [submission, remoteDraft, versionToKeep, cache, setIsLoading, setErrorMessage]);

    if (!submission || !submission.$error) {
        return null;
    }

    const isSyncConflict = submission.$error === 'sync-conflict';

    if (isSyncConflict && dontShowSyncConflict) {
        return null;
    }

    const retrySync = async () => {
        onSyncClicked?.();
        setIsSyncing(true);
        const error = await syncLocalDraft(cache, submission, {
            forceSync: true,
            forceSubmission: submission.$error === "submit",
        });
        setIsSyncing(false);
        onSync?.(!error);
    };

    const deleteDraft = () => {
        cache.delete(submissionId, {immediate: true, includeAttachments: true})
        .then(() => onDelete?.());
    };

    const linkUrl = `/${orgId}/home/team/${submission.team.uuid}/forms/${submission.form.form_uuid}/submission/${submission._id}`;

    const fieldError = getFieldErrorDetails(submission);

    const fullDescription = (
        <span className="zero-dark-grey">
            { isSyncConflict ? (
                <p className="mar-no">There are two conflicting versions of this draft (one local and one remote). Which version would you like to keep?</p>
            ) : (
                <p className="mar-no"><strong>A sync error occurred:</strong> {getErrorMessage(submission)}</p>
            )}
            { showTemplate &&
                <p className="mar-no"><strong>Template:</strong> {submission.form.name}</p>
            }
            { fieldError &&
                <p className="mar-no"><strong>Field:</strong> {fieldError.label} <span style={{whiteSpace: 'nowrap'}}>({fieldError.id})</span></p>
            }
            { isSyncing ? (
                <LogicLessSyncSpinner className="flex" style={{alignItems: 'center'}} />
            ) : (
                <>
                    { isSyncConflict ? (
                        <>
                            <div className='mar-btm-10'>
                                <Radio.Group className="mar-top-10" onChange={e => setVersionToKeep(e.target.value)} value={versionToKeep}>
                                    <Space direction="vertical" size={0}>
                                        <Radio value="local">
                                            <span className="text-semibold">Local version</span>: last edited
                                            on {dateFormatterWithTime(submission.$editedAt)}
                                        </Radio>
                                        <Radio disabled={remoteDraft === null} value="remote">
                                            <span className="text-semibold">Remote version</span>:
                                            {remoteDraft ? (
                                                <> last edited on {dateFormatterWithTime(remoteDraft.edited_at)}</>
                                            ) : (
                                                <Spin/>
                                            )}
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </div>
                            { errorMessage &&
                                <small className="error">{errorMessage}</small>
                            }
                            <Button type="primary" disabled={isLoading || versionToKeep === null} onClick={resolveSyncConflict}>Confirm</Button>
                        </>
                    ) : (
                        <p className="mar-no">
                            <ButtonLink onClick={retrySync}>Retry sync</ButtonLink>
                            {" "}|{" "}
                            { onEditClicked ? (
                                <ButtonLink onClick={onEditClicked}>Edit draft</ButtonLink>
                            ) : (
                                <Link to={linkUrl} className="blue-link">Edit draft</Link>
                            )}
                            {" "}|{" "}
                            <Popconfirm
                                title="Delete draft?"
                                description="Are you sure you want to delete this draft?"
                                onConfirm={deleteDraft}
                                okText="Yes"
                                cancelText="No"
                            >
                                <ButtonLink color="red">Delete draft</ButtonLink>
                            </Popconfirm>
                        </p>
                    )}
                </>
            )}
        </span>
    );

    return (
        <Alert
            className="mar-btm-10"
            message={<span className="text-semibold">{isSyncConflict ? 'Sync Conflict' : 'Sync Error'}</span>}
            description={fullDescription}
            type={isSyncConflict ? "error" : "warning"}
            showIcon
            icon={<AlertOutlined />}
        />
    )
}

/**
 * @param {SubmissionDraft} submission 
 * @returns {string}
 */
function getErrorMessage(submission) {
    let msg = submission.$errorMessage;

    if (!msg) {
        return "Unknown error.";
    }

    // Get rid of "Error: " at beginning of message
    msg = msg.replace(/^Error: /, "");

    // Add period at end if necessary
    if (!msg.endsWith(".")) {
        msg += ".";
    }

    return msg;
}

/**
 * @param {SubmissionDraft} submission 
 * @returns {{label: string, id: string}}
 */
function getFieldErrorDetails(submission) {
    if (submission.$fieldErrors && submission.$fieldErrors.length > 0) {
        const err = submission.$fieldErrors.find(fe => 'field_id' in fe && 'field_label' in fe);
        if (err) {
            return {
                label: /** @type {string} */(err.field_label),
                id: /** @type {string} */(err.field_id)
            }
        }
    }
    return null;
}