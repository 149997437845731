import { useCallback, useState } from "react";

import { createIncidentUserComment } from 'api/zero-api';
import AddCommentWithNotifications from "components/Shared/AddCommentWithNotifications";

export default function IncidentCommentInput({managers, incidentId, onCommentCreated}) {
    const [error, setError] = useState('');

    const handleCreateComment = useCallback(async (body, assignedUsers) => {
        try {
            setError('');
            const requestBody = {
                body,
                notify_user_uuids: assignedUsers.map(user => user.uuid),
            };
            await createIncidentUserComment(incidentId, JSON.stringify(requestBody));
            onCommentCreated?.();
        } catch (err) {
            setError('Could not create comment.');
        }
    }, [incidentId]);

    return (
        <div>
            <AddCommentWithNotifications
                availableUsers={managers}
                onCreateClicked={handleCreateComment}
            />
            {error && <small className="error">{error}</small>}
        </div>
    );
}