import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { CompleteFormBuilderItems } from '../../other/Config.js';
import {
    floatingButtonMargin,
    generateUUID,
    getErrorMessageFromResponse,
    safe_get
} from '../../other/Helper.js';

import { update_form } from '../../api/zero-api.js';


import FormPreviewModal from '../AdminPages/ManageForms/FormPreviewModal.js';


import NotificationAlert from '../../other/NotificationAlert.js';

import ConfirmationFieldsRenderer from './ConfirmationFieldsRenderer.js';
import FormFieldsRenderer from './FormFieldsRenderer';

import {
    AlignCenterOutlined,
    AlignLeftOutlined,
    BlockOutlined, BorderOutlined,
    CalendarOutlined,
    CheckSquareOutlined,
    ColumnHeightOutlined,
    CopyOutlined,
    DatabaseOutlined,
    DownSquareOutlined,
    EditOutlined, EyeOutlined,
    FieldTimeOutlined,
    FontColorsOutlined,
    LineOutlined,
    NumberOutlined,
    OrderedListOutlined,
    PaperClipOutlined,
    PictureOutlined,
    PlusCircleOutlined,
    TagsOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import { Alert, Popover } from 'antd';

import { ZeroContext } from 'components/ZeroContext.js';
import { FormType } from 'other/Constants.js';
import '../../assets/css/form-builder.css';
import FormFieldMenu from './FormFieldMenu.js';

class FormBuilderComponent extends Component {
    static contextType = ZeroContext;

    constructor(props) {
        super(props);

        /** @type {ZeroContext} */
        this.context;

        this.state = {
            customConfirmation: false,
            showSavedText: true,
            submitting: false,
            toolbar_items: [],
            confirmation_toolbar_items: [],
            selected_teams: [],
            form_data: [],
            form_fields: [],
            confirmation_fields: [],
            form_title: "Untitled form",
            form_title_edit: "",
            form_type: 0,
        };
    }

    componentDidMount() {
        this.initComponent();
    }

    componentWillUnmount() {
        if (this.state.maxFieldsAlert) {
            this.state.maxFieldsAlert.destroy('forms-max-fields-alert');
        }
    }

    initComponent = () => {
        if (this.props.form_data !== undefined) {
            let form_type = parseInt(safe_get(this.props.form_data, "form_type", 0));

            let toolBarItems = CompleteFormBuilderItems(this.props.form_data.form_type);

            if (form_type === 3 || form_type === 2) {
                toolBarItems = toolBarItems.filter(item => {
                    return !item.audit
                });
            }
            if (form_type === 2) { // incident
                toolBarItems = toolBarItems.filter(item => {
                    return item.element !== "PageBreak"
                });
            }

            this.setState({
                form_data: this.props.form_data.fields,
                form_fields: this.props.form_data.fields,
                form_title: safe_get(this.props.form_data, "name", ""),
                form_title_edit: safe_get(this.props.form_data, "name", ""),
                customConfirmation: safe_get(this.props.form_data, "custom_confirmation_page", false),
                confirmation_fields: safe_get(this.props.form_data, "confirmation_fields", []),
                edit_title: false,
                form_type: form_type,
                toolbar_items: toolBarItems,
                confirmation_toolbar_items: toolBarItems,
                selected_teams: [this.props.current_team_uuid],
                schedule_only: safe_get(this.props, "form_data.schedule_only", false),
                editedAt: safe_get(this.props, 'form_data.edited_at', 0),
                editedBy: safe_get(this.props, 'form_data.edited_by.full_name', '')
            });
        }

    }

    onInputChange = (e) => {
        this.setState({form_title_edit: e.target.value});
    }

    toggleTitleEdit = () => {
        if (this.state.editTitle) {
            this.setState({form_title: this.state.form_title_edit}, () => {
                this.saveDraft();
            });
        }
        this.setState({editTitle: !this.state.editTitle});

    }

    toggleCustomConfirmation = () => {
        let newState = {
            "customConfirmation": !this.state.customConfirmation
        }
        if (this.state.confirmation_fields.length === 0) {
            let conf_fields = [
                {
                    bold: false,
                    canHaveAlternateForm: false,
                    canHaveDisplayHorizontal: true,
                    canHaveOptionCorrect: true,
                    canHaveOptionValue: true,
                    canHavePageBreakBefore: false,
                    content: `<h3 class="zero-blue" style="text-align: center;">Form Submitted!</h3><p class="zero-dark-grey" style="text-align: center;">Your form has been submitted successfully.</p>`,
                    disableAttachments: false,
                    element: "Paragraph",
                    id: generateUUID(),
                    italic: false,
                    required: false,
                    static: true,
                    text: "Paragraph"
                }
            ]
            newState["confirmation_fields"] = conf_fields;
        }
        this.setState(newState, () => {
            this.saveDraft();
        });
    }

    saveField = (field, index) => {
        if (field && index >= 0) {
            var updated_form_fields = [...this.state.form_fields];
            updated_form_fields[index] = field;
            this.setState({form_fields: updated_form_fields}, () => {
                this.saveDraft();
            });
        }
    }

    deleteField = (index) => {
        if (index >= 0) {
            let updated_form_fields = [];
            let form_fields = [...this.state.form_fields];
            let delete_field = form_fields[index];

            let section_children = []
            if (delete_field.element === "Section") {
                section_children = delete_field.children
            }

            form_fields.forEach((field) => {
                if (field.custom_logic && field.custom_logic.trigger_field_id === delete_field.id) {
                    field["custom_logic"] = undefined
                }

                if (delete_field.sectionId && delete_field.sectionId === field.id) {
                    let delete_field_section_index = field.children.indexOf(delete_field.id)
                    if (delete_field_section_index >= 0) {
                        field.children.splice(delete_field_section_index, 1)
                    }
                }

                if (field.id !== delete_field.id && !section_children.includes(field.id)) {
                    updated_form_fields.push(field);
                }

            });

            this.setState({form_fields: updated_form_fields}, () => {
                this.saveDraft();
            });
        }
    }

    saveConfirmationField = (field, index) => {
        if (field && index >= 0) {
            var updated_confirmation_fields = [...this.state.confirmation_fields];
            updated_confirmation_fields[index] = field;
            this.setState({confirmation_fields: updated_confirmation_fields}, () => {
                this.saveDraft();
            });
        }
    }

    deleteConfirmationField = (index) => {
        if (index >= 0) {
            var updated_confirmation_fields = [...this.state.confirmation_fields];
            updated_confirmation_fields.splice(index, 1);
            this.setState({confirmation_fields: updated_confirmation_fields}, () => {
                this.saveDraft();
            });
        }
    }

    handleMenuClick = (key) => {
        let field = [...this.state.toolbar_items][key];
        if (field) {
            this.addField(field);
        }
    }

    addField = (new_field) => {
        var form_fields = [...this.state.form_fields];

        let field = {...new_field};

        if (field.element === "Section") {
            field.children = [];
        }

        // deep copy options
        if (field.options) {
            field.options = [...field.options]
            for (let i = 0; i < field.options.length; i++) {
                field.options[i] = {...field.options[i]}
            }
        }

        field["id"] = generateUUID();
        delete field["icon"];

        form_fields.push(field);
        this.setState({form_fields: form_fields, optionsVisible: false, nonFloatingOptionsVisible: false}, () => {
            this.saveDraft();
        });
    }

    saveForm = (fields) => {
        this.setState({form_fields: fields}, () => {
            this.saveDraft();
        });
    }

    saveConfirmation = (fields) => {
        this.setState({confirmation_fields: fields}, () => {
            this.saveDraft();
        });
    }

    saveDraft = () => {
        this.props.onSaveStateChange(true);
        this.setState({showSavingText: true, showSavedText: false, allowNav: false});

        var body = JSON.stringify({
            name: this.state.form_title.replace(/&nbsp;/gi, ''),
            fields: this.state.form_fields,
            custom_confirmation_page: this.state.customConfirmation,
            confirmation_fields: this.state.confirmation_fields,
            enabled: true,
            team_uuids: this.state.selected_teams,
            form_type: this.state.form_type,
            schedule_only: this.state.schedule_only,
            category_uuid: safe_get(this.props, "form_data.category.category_uuid", ""),
            admin_restricted: safe_get(this.props, "form_data.admin_restricted", false),
            restrict_user_edits: safe_get(this.props, "form_data.restrict_user_edits", false),
        });

        update_form(this.props.form_data.form_uuid, body)
            .then(response => response.json())
            .then(data => {
                if (data?.form) {
                    this.context.services.forms.syncForm(this.props.form_data.form_uuid, data.form);
                }

                if (this.state.maxFieldsAlert) {
                    this.state.maxFieldsAlert.destroy('forms-max-fields-alert');
                }

                this.setState({
                    editedAt: safe_get(data, 'form.edited_at', 0),
                    editedBy: safe_get(data, 'form.edited_by.full_name', ''),
                    showSavingText: false,
                    showSavedText: false,
                    maxFieldsAlert: null,
                });
            })
            .catch(async (err) => {
                let maxFieldsAlert = this.state.maxFieldsAlert;

                if (err instanceof Response) {
                    const errorMessage = await getErrorMessageFromResponse(err, 'Could not save form');
                    const match = errorMessage.match(/Max (\d+) fields allowed/);

                    if (!maxFieldsAlert && match) {
                        maxFieldsAlert = NotificationAlert(
                            'error',
                            '',
                            `Maximum of ${match[1]} fields allowed. Your changes will no longer save. Please remove fields to proceed.`,
                            0,
                            {
                                canClose: false,
                                key: 'forms-max-fields-alert',
                            }
                        );
                    }
                }

                this.setState({showSavingText: false, showSavedText: false, maxFieldsAlert});
            })
            .finally(() => {
                this.props.onSaveStateChange(false);
            });
    }

    updateForm = () => {
        var self = this;
        if (!this.state.submitting) {
            this.props.onSaveStateChange(true);
            this.setState({submitting: true, allowNav: true});

            if (this.state.form_title === "") {
                this.setState({submitting: false, titleError: "Form title cannot be blank."});
                if (this.props.edit_mode) {
                    this.props.handleTitleError("Form title cannot be blank.");
                }
                this.props.onSaveStateChange(false);
            } else if (safe_get(this.state, "form_fields", []).length === 0) {
                this.setState({submitting: false, submitError: "Form must have at least one field."});
                this.props.onSaveStateChange(false);
            } else {
                var body = JSON.stringify({
                    name: this.state.form_title.replace(/&nbsp;/gi, ''),
                    fields: this.state.form_fields,
                    custom_confirmation_page: this.state.customConfirmation,
                    confirmation_fields: this.state.confirmation_fields,
                    enabled: true,
                    team_uuids: this.state.selected_teams,
                    form_type: this.state.form_type,
                    schedule_only: this.state.schedule_only,
                    category_uuid: safe_get(this.props, "form_data.category.category_uuid", ""),
                    admin_restricted: safe_get(this.props, "form_data.admin_restricted", false),
                    restrict_user_edits: safe_get(this.props, "form_data.restrict_user_edits", false),
                });

                update_form(this.props.form_data.form_uuid, body)
                .then(function (success) {
                    self.setState({submitting: false});
                    NotificationAlert("success", "", "Template updated.");
                    self.props.history.push("/" + self.props.org_uuid + "/home/manage_forms_templates");
                })
                .catch((error) => {
                    self.setState({submitting: false});
                    NotificationAlert("error", "", "Unable to update form.");
                })
                .finally(() => {
                    this.props.onSaveStateChange(false);
                })
            }
        }
    }


    handleVisibleChange = (visible) => {
        this.setState({optionsVisible: visible});
    }

    handleNonFloatingOptionsChange = (visible) => {
        this.setState({nonFloatingOptionsVisible: visible});
    }

    render() {
        const icons = {
            FontColorsOutlined: FontColorsOutlined,
            AlignLeftOutlined: AlignLeftOutlined,
            LineOutlined: LineOutlined,
            UnorderedListOutlined: UnorderedListOutlined,
            CheckSquareOutlined: CheckSquareOutlined,
            TagsOutlined: TagsOutlined,
            DownSquareOutlined: DownSquareOutlined,
            NumberOutlined: NumberOutlined,
            CalendarOutlined: CalendarOutlined,
            AlignCenterOutlined: AlignCenterOutlined,
            BorderOutlined: BorderOutlined,
            BlockOutlined: BlockOutlined,
            EditOutlined: EditOutlined,
            OrderedListOutlined: OrderedListOutlined,
            ColumnHeightOutlined: ColumnHeightOutlined,
            CopyOutlined: CopyOutlined,
            PaperClipOutlined: PaperClipOutlined,
            FieldTimeOutlined: FieldTimeOutlined,
            PictureOutlined: PictureOutlined,
            DatabaseOutlined
        };

        const menu = (
            <FormFieldMenu items={this.state.toolbar_items} onClick={this.handleMenuClick}/>
        );

        const showEditFormAlert = this.state.form_type !== FormType.POST && (this.props.form_data?.submissions_count ?? 0) > 0;

        return (
            <>
                {
                    this.props.showFormPreview &&
                    <FormPreviewModal
                        show={true}
                        cancel={() => {
                            this.props.setShowFormPreview(false);
                        }}
                        title={this.state.form_title}
                        fields={this.state.form_fields}
                        is_builder={true}
                        form_uuid={null}
                    />
                }

                {
                    this.state.showConfirmationPreview &&
                    <FormPreviewModal
                        show={this.state.showConfirmationPreview}
                        cancel={() => {
                            this.setState({showConfirmationPreview: false})
                        }}
                        title={this.state.form_title}
                        fields={this.state.confirmation_fields}
                        is_builder={true}
                        form_uuid={null}
                    />
                }

                { showEditFormAlert &&
                    <Alert
                        className="ant-alert-message-no-bottom-margin"
                        description={
                            <span className="zero-dark-grey">
                                Please note: this template is already in use. Any changes made to the template now will take 
                                immediate effect and will update past submissions. For example, deleting a question will 
                                cause loss of data on past submissions that cannot be undone. If you need to make make 
                                material changes to the template, we recommend copying the template and starting
                                 fresh so as not to affect your historical data.
                            </span>}
                        type="error"
                    />
                }

                <FormFieldsRenderer
                    form_uuid={this.props.form_data.form_uuid}
                    submission_uuid=""
                    form_fields={this.state.form_fields}
                    pages={[this.state.form_fields]}
                    page={0}
                    field_answers={[]}
                    toolbar_items={this.state.toolbar_items}
                    icons={icons}
                    is_builder={true}
                    saveForm={this.saveForm}
                    saveField={this.saveField}
                    deleteField={this.deleteField}
                />

                <Popover placement={"topLeft"} content={menu} trigger="click"
                            style={{position: "fixed"}}
                            onOpenChange={this.handleNonFloatingOptionsChange}
                            open={this.state.nonFloatingOptionsVisible}>
                    <div className="pad-10 pad-top-0">
                        <button className="btn btn-primary"><PlusCircleOutlined/> Add Field
                        </button>
                    </div>
                </Popover>

                {
                    <div id="floating-button" style={{bottom: floatingButtonMargin()}}>
                        <Popover placement={"topRight"} content={menu} trigger="click"
                                    style={{position: "fixed"}} onClick={this.handleVisibleChange}
                                    onOpenChange={this.handleVisibleChange}
                                    open={this.state.optionsVisible}>
                            <p className="plus-sign">+</p>
                        </Popover>
                    </div>
                }

                {
                    FormType.allowsCustomConfirmationPage(this.props.form_data.form_type) &&
                    <>
                        <hr/>
                        <label className={"radio-label zero-blue"} style={{marginLeft: "3px"}}>
                            <input
                                type="checkbox"
                                checked={this.state.customConfirmation}
                                onChange={this.toggleCustomConfirmation}
                            /> Add custom confirmation page
                        </label>
                    </>
                }
                {
                    this.state.customConfirmation &&
                    <div className="panel thin-border">
                        <div className="panel-body">
                            <h4 className="zero-blue mar-rgt-5 mar-btm-0">Confirmation Page</h4>
                            <p className="mar-btm-0 zero-dark-grey ">Here's what people will see after
                                they submit this form.</p>
                            {
                                this.state.showSavedText &&
                                <p className="mar-btm-0 zero-dark-grey"
                                    style={{display: "inline-block"}}>All changes saved</p>
                            }
                            {
                                this.state.showSavingText &&
                                <p className="mar-btm-0 zero-dark-grey"
                                    style={{display: "inline-block"}}>Saving...</p>
                            }
                            <EyeOutlined className="mar-lft-5 zero-dark-grey" onClick={() => {
                                this.setState({showConfirmationPreview: true});
                            }}/>
                            <hr/>
                            <ConfirmationFieldsRenderer
                                form_uuid={""}
                                form_fields={this.state.form_fields}
                                confirmation_fields={this.state.confirmation_fields}
                                pages={[this.state.confirmation_fields]}
                                page={0}
                                field_answers={[]}
                                toolbar_items={this.state.confirmation_toolbar_items}
                                icons={icons}
                                is_builder={true}
                                is_confirmation={true}
                                saveForm={this.saveConfirmation}
                                saveField={this.saveConfirmationField}
                                deleteField={this.deleteConfirmationField}
                            />
                        </div>
                    </div>
                }
            </>
        );
    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", "")
    }
}

export default withRouter(connect(mapStateToProps)(FormBuilderComponent));