// @ts-check

import { useMemo } from "react";

export default function ActivityTimelineLayout({className='', timeline, details}) {
    const fullClassName = useMemo(() => `activity-timeline-layout ${className}`.trim(), [className]);

    return (
        <div className={fullClassName} style={{paddingBottom: '2rem'}}>
            <section className="details-section">
                {details}
            </section>
            <hr className="timeline-top-rule" />
            <section className="timeline-section">
                <h3 className="timeline-print-header">Timeline</h3>
                {timeline}
            </section>
        </div>
    )
}