import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {Link} from 'react-router-dom';

import FieldComment from './FieldComment.js';
import NewPostModal from '../Bulletin/NewPostModal.js';
import Previewer from '../Previewer';
import NotificationAlert from '../../other/NotificationAlert.js';

import {List} from 'antd';
import {PaperClipOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';

import safe_get from '../../other/SafeGet.js';
import {isVideo, isImage, getFileThumbnail, isRestrictedTeamUser, isViewer} from '../../other/Helper.js'

import '../../assets/css/form-builder.css'
import ConfirmModal from '../Shared/ConfirmModal.js';
import VideoThumbnail from 'components/Shared/VideoThumbnail.js';
import { ZeroContext } from 'components/ZeroContext.js';
import { replaceAttachmentUrlWithBlob } from "offline/utils";
import { DebugLogger } from 'other/DebugLogger.js';
import { PostEvents } from 'services/postService.js';
import DOMPurify from 'dompurify';
import { FormType } from 'other/Constants.js';
import { isNumber } from 'lodash-es';

const debugLogger = new DebugLogger("FormFieldCommentAttachment");

var saveTimeout = null;

class FormFieldCommentAttachment extends Component {
    static contextType = ZeroContext;

    constructor(props) {
        super(props);

        /** @type {ZeroContext} */
        this.context;

        this.state = {
            field: this.props.field,
            answer_data: this.props.answer_data,
        };

        this.handleFilePreviewer = this.handleFilePreviewer.bind(this);
        this.showCommentBox = this.showCommentBox.bind(this);
        this.cancelComment = this.cancelComment.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.postCreated = this.postCreated.bind(this);
        this.hideCreatePost = this.hideCreatePost.bind(this);

    }

    componentDidMount() {
        var self = this;

        let team = this.props.teams.find(function (team) {
            return team.uuid === safe_get(self.props, "match.params.team_uuid", "")
        });

        this.setState({team: team});
    }

    componentDidUpdate(prevProps) {
        if (this.props.saving !== prevProps.saving) {
            this.setState({
                field: this.props.field,
                answer_data: this.props.answer_data
            });
        }

        if (this.props.answer_data !== prevProps.answer_data) {
            this.setState({
                answer_data: this.props.answer_data
            })
        }
    }

    showCommentBox(field) {
        if (this.props.is_preview) {
            return
        }
        field["showCommentBox"] = true;
        this.setState({field: field});
    }

    cancelComment(field) {
        field["showCommentBox"] = false;
        this.setState({field: field});
    }

    saveComment(field, answer_data, comment, attachments) {
        field["showCommentBox"] = false;
        answer_data["comment"] = comment;
        answer_data["attachments"] = attachments
        answer_data["comment_saved"] = true
        this.setState({answer_data: answer_data, field: field}, () => {
            this.props.updateField(answer_data, this.props.index)
        });
    }

    deleteComment = () => {
        let field = this.state.fieldCommentToDelete;
        if (field) {
            field["comment"] = null;
            field["attachments"] = [];
            field["related_post_uuid"] = null;
            field["showCommentBox"] = false;
            field["comment_saved"] = false;

            this.setState({
                answer_data: field,
                field: field,
                showDeleteConfirmation: false,
                fieldCommentToDelete: null
            }, () => {
                this.props.updateField(field, this.props.index);
            });
        }
    }

    renderAttachment(attachment, attachmentIndex) {
        var file_name = safe_get(attachment, "file_name", "");
        var thumbnail = getFileThumbnail(attachment, this.context.caches.submissionDrafts.blobs);

        let attachments = safe_get(this.state, "answer_data.attachments", [])
        const errorBorder = attachment.error ? { border: '1px solid red' } : {};

        if (isVideo(file_name)) {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px", ...errorBorder}}>
                    <VideoThumbnail
                        imgSrc={thumbnail}
                        onPlay={() => {
                            this.handleFilePreviewer(attachments, attachmentIndex);
                        }}
                    />
                </li>
            )
        } else if (isImage(file_name)) {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px", width: '102px', ...errorBorder}}>
                    <button className="ButtonLink" onClick={() => {
                        this.handleFilePreviewer(attachments, attachmentIndex);
                    }}>
                        <img className="post-img" src={thumbnail} alt={file_name} style={{
                            objectFit: 'contain',
                            width: "100px",
                            height: "100px",
                            borderRadius: "2px",
                            backgroundColor: "#f6f6f6",
                            border: "1px solid #e5e5e5"
                        }}/>
                    </button>
                </li>
            )
        } else {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px", height: "100px", width: "100px", ...errorBorder}}>
                    <button className="ButtonLink" style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "2px",
                        backgroundColor: "#f6f6f6",
                        border: "1px solid #e5e5e5",
                        position: "absolute"
                    }} onClick={() => {
                        this.handleFilePreviewer(attachments, attachmentIndex);
                    }}>
                        <img src={thumbnail} alt={file_name} style={{
                            objectFit: 'cover',
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            display: "block",
                            margin: "0 auto"
                        }}/>
                        <p className="zero-dark-grey ellipsis-2-lines" style={{
                            margin: "0px",
                            padding: "0px 5px 5px 5px",
                            fontSize: "80%",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            fontWeight: "500",
                            textAlign: "center"
                        }}
                        title={file_name}
                        >{file_name}</p>
                    </button>
                </li>
            )
        }
    }

    handleFilePreviewer = (attachments, index) => {
        this.setState({
            preview_visible: true,
            preview_index: index,
            previewAttachments: attachments
        });
    }

    createPost = () => {
        // this.saveComment(this.state.field, this.state.answer_data);
        this.setState({
            selected_field: this.state.answer_data,
            showNewPost: true
        });
    }

    postCreated(post) {
        const attachPost = () => {
            var field = {...this.state.selected_field};
            const postId = post.post_uuid.replace("offline:", "");
    
            let attachments = field.attachments;
            attachments = attachments.filter(obj => {
                return !(obj.file_path.includes("bulletin"));
            });
    
            field["attachments"] = attachments;
            field["showCommentBox"] = false;
            field["related_post_uuid"] = postId;
            field["post_title"] = post.title
            field["post_status"] = post.status
    
            this.setState({answer_data: field, field: field, selected_field: undefined, attachingNewPost: false}, () => {
                this.props.updateField(field, this.props.index)
            });
    
            NotificationAlert("success", "", "Related post created.");
    
            if (this.props.linkPostToField) {
                this.props.linkPostToField(postId, field.id);
            }
        };

        this.setState({showNewPost: false, attachingNewPost: true});
        const timeout = setTimeout(attachPost, 10_000);
        const unsubscribe = this.context.services.post.subscribe(ev => {
            if (ev.data.type === PostEvents.POST_ID_CHANGE) {
                if (ev.data.oldId === post.post_uuid) {
                    clearTimeout(timeout);
                    unsubscribe();
                    post.post_uuid = ev.data.newId;
                    attachPost();
                }
            }
        });
    }

    hideCreatePost(field) {
        field["hideCreatePost"] = true
        this.setState({field: field});
    }

    /**
     * Loops through an array of attachments and makes sure the public_url and file_path are using up to date blob URLs
     * for any offline attachments
     */
    fixAttachmentUrls = (attachments) => {
        debugLogger.log("fixAttachmentUrls called with %o", attachments);

        const result = attachments.map(attachment => {
            const {attachment_uuid} = attachment;
            debugLogger.log("attachment_uuid:", attachment_uuid);
            if (!attachment_uuid || attachment_uuid.startsWith("offline:") === false) {
                debugLogger.log("not an offline attachment, skipping");
                return attachment;
            }

            const blobStruct = this.context.caches.submissionDrafts.blobs[attachment_uuid];
            if (!blobStruct) {
                // Didn't find a blob matching assignment ID, just return whatever we have
                debugLogger.log("attachment id not found in blob cache, skipping");
                return attachment;
            }

            const {url} = blobStruct;

            if (attachment.public_url !== url) {
                debugLogger.log("updating blob URL", attachment.public_url, "to", url);
            }

            return {
                ...attachment,
                file_path: url,
                public_url: url,
            }
        });

        debugLogger.log("fixAttachmentUrls returning %o", result);
        return result;
    }

    render() {
        let {is_submission, is_preview} = this.props
        let field = this.state.field
        let answer_data = safe_get(this.state, "answer_data", {});
        let attachments = safe_get(answer_data, "attachments", []);

        const hasRelatedPost = answer_data.related_post_uuid && answer_data.post_status;

        let hasAvailableTeamToPost = false;
        let teams = this.props.teams;
        for (var i in teams) {
            let team = teams[i];
            if (!isRestrictedTeamUser(team, this.props.user)) {
                hasAvailableTeamToPost = true;
                break;
            }
        }

        const formType = this.props.form?.form_type;

        const canCreatePost = 
            !this.state.attachingNewPost
            && (answer_data.comment_saved || attachments.length > 0)
            && !hasRelatedPost
            && !field.hideCreatePost
            && hasAvailableTeamToPost
            && !isViewer(this.props.user)
            && (isNumber(formType) ? FormType.canCreatePost(formType) : true);
        
        if (field.element === 'Attachments') {
            return null;
        }

        return (
            <div className="mar-btm-5 form-comment-container">
                {
                    this.state.showDeleteConfirmation &&
                    <ConfirmModal
                        show={this.state.showDeleteConfirmation}
                        cancel={() => {
                            this.setState({showDeleteConfirmation: false})
                        }}
                        confirm={this.deleteComment}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this comment?"}
                        confirmButtonName={"Delete"}
                    />
                }
                {
                    this.state.showNewPost &&
                    <NewPostModal
                        show={this.state.showNewPost}
                        cancel={() => {
                            this.setState({showNewPost: false});
                        }}
                        description={this.state.selected_field.comment}
                        // incident_uuid={this.props.incident_uuid}
                        postCreated={this.postCreated}
                        fromForms={true}
                        attachments={this.fixAttachmentUrls(this.state.selected_field.attachments)}
                    />
                }
                {
                    this.state.preview_visible &&
                    <Previewer
                        show={this.state.preview_visible}
                        close={() => {
                            this.setState({preview_visible: false})
                        }}
                        attachments={this.state.previewAttachments}
                        index={this.state.preview_index}
                    />
                }
                {
                    (is_submission || is_preview) && !field.showCommentBox && (safe_get(answer_data, "comment", "").length === 0 && attachments.length === 0) && !answer_data.related_post_uuid && safe_get(answer_data, "value", [undefined])[0] && !field.disableAttachments &&
                    <PaperClipOutlined style={{cursor: "pointer"}} onClick={(e) => {
                        e.preventDefault();
                        this.showCommentBox(field)
                    }}/>
                }
                {
                    is_submission && field.showCommentBox &&
                    <FieldComment
                        formId={this.props.form_uuid}
                        submissionId={this.props.submission_uuid}
                        fieldId={field.id}
                        initialComment={answer_data.comment}
                        initialAttachments={attachments.map((att) => replaceAttachmentUrlWithBlob(att, this.context.caches.submissionDrafts.blobs))}
                        onCancel={() => {
                            this.cancelComment(field)
                        }}
                        onSave={(comment, attachments) => {
                            this.saveComment(field, answer_data, comment, attachments)
                        }}
                    />
                }
                {
                    !field.showCommentBox && (answer_data.comment_saved || attachments.length > 0) &&
                    <>
                        <List
                            className=""
                            itemLayout="horizontal"
                            bordered
                            dataSource={[answer_data]}
                            renderItem={(item, index) => (
                                <List.Item
                                    actions={
                                        is_submission ?
                                            [
                                                <EditOutlined
                                                    className="zero-blue"
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        this.showCommentBox(field)
                                                    }}
                                                />,
                                                <DeleteOutlined
                                                    className="zero-delete-red"
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        this.setState({
                                                            showDeleteConfirmation: true,
                                                            fieldCommentToDelete: item
                                                        });
                                                    }}
                                                />
                                            ] : []
                                    }
                                >
                                    <div style={{width: "100%"}}>
                                        {
                                            item.comment &&
                                            <div className="quill-preview titles onboarding invite-link mt-0 zero-blue" style={{
                                                marginBottom: (attachments.length > 0 ? "5px" : "0px"),
                                                marginTop: "0px",
                                                fontSize: "100%",
                                                lineHeight: "1.4",
                                                fontWeight: "500"
                                            }}
                                                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.comment)}}
                                            />
                                        }

                                        {
                                            attachments.length > 0 &&
                                            <ul className="thumb">
                                                {
                                                    attachments.map((attachment, index) => (
                                                        <span key={index}>
                                                  {this.renderAttachment(attachment, index)}   
                                          </span>
                                                    ))
                                                }
                                            </ul>
                                        }
                                    </div>
                                </List.Item>
                            )}
                        />
                        {
                            hasRelatedPost &&
                            <p style={{float: "right", fontSize: "13px", marginTop: "4px"}}>
                                <Link className="link-hover zero-light-blue"
                                      to={"/" + this.props.org_uuid + "/home/team/my_teams/feed/post/" + answer_data.related_post_uuid}>Post
                                    created</Link> <span
                                style={{color: "#505050"}}>(Status: {answer_data.post_status || "deleted"})</span>
                            </p>
                        }
                        { this.state.attachingNewPost &&
                            <p style={{float: "right", fontSize: "13px", marginTop: "4px"}}>Attaching new post...</p>
                        }
                        {
                            canCreatePost &&
                            <p className="underline-hover dont-print" style={{
                                marginBottom: "0px",
                                marginTop: "4px",
                                color: "#505050",
                                fontSize: "13px",
                                float: "right",
                                cursor: "pointer"
                            }} onClick={this.createPost}>
                                Create post...
                            </p>
                        }
                    </>
                }
            </div>

        );

    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        current_team: safe_get(store, "teams_helper.team", ""),
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        teams: safe_get(store, "teams_helper.teams", "")
    }
}

export default withRouter(connect(mapStateToProps)(FormFieldCommentAttachment));
  