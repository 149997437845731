import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'

import NotificationAlert from '../../../other/NotificationAlert';
import {safe_get, monthToInt, scrollToTop, isAdmin, isAdminOrTeamLead} from '../../../other/Helper.js';
import {REPORT_YEARS, REPORT_QUARTERS, REPORT_MONTHS} from '../../../other/Config.js'

import {
    get_locations,
    post_account_report,
    post_directory_report,
    post_team_report,
    post_open_posts_report,
    feed_posts_report,
    get_incidents,
    incidents_report,
    form_submission_report,
    get_reports_history,
    get_form_reports_history,
    get_feed_reports_history,
    get_form_report_data,
    incidents_report_history,
    get_schedule_report_data,
    generate_schedule_report,
    generate_assignment_report,
    notifyError,
    get_report_schedules,
    course_report,
    course_schedule_report,
    course_assignment_report,
    get_lms_report_history,
    masterIncidentReport,
    getTeamsList
} from '../../../api/zero-api.js'

import {
    GlobalOutlined,
    CalendarOutlined,
    LayoutOutlined,
    UserOutlined,
    TeamOutlined,
    ToolOutlined,
    PushpinOutlined
} from '@ant-design/icons'

import {DatePicker, Select, Checkbox, Tag} from 'antd';

import ReportHistory from './ReportHistory';
import ReportScheduleModal from './ReportScheduleModal';

import IncidentsTable from '../../Incidents/IncidentsTable';

import ButtonLoading from '../../Shared/ButtonLoading';

import checklist from '../../../assets/css/img/checklist.png'
import ZERO_logo from '../../../assets/css/img/ZERO-(blue)-small.png';
import dayjs from "dayjs";

const Option = Select.Option;

var today_moment = dayjs();
var current_year = new Date().getFullYear();
var available_years = REPORT_YEARS();
var available_quarters = REPORT_QUARTERS(available_years);
var available_months = REPORT_MONTHS(available_years);

class GenerateReport extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            teams: [],
            all_teams: [],
            locations: [],
            filtered_incidents: [],
            all_forms: [],
            form_report_data: [],
            schedule_report_data: [],
            selected_statuses: [],
            selected_form_uuids: [],
            team_uuid: undefined,
            selected_location_uuid: "",
            report_type_selected: "",
            date_range_selected: "all_time",
            year: current_year,
            quarter: available_quarters[0],
            monthly: available_months[0],
            weekly: today_moment,
            custom_start_moment: today_moment,
            custom_end_moment: today_moment,
            report_years: available_years.reverse(),
            report_quarters: available_quarters,
            report_months: available_months,
            report_weeks: [],
            report_format_type: "xlsx",
            loading_schedule_data: true,
            buttonDisabled: false,
            reportSuccess: false,
            include_deactivated_users: true,
        };

        this.handleReportChange = this.handleReportChange.bind(this);
        this.handleTeamSelect = this.handleTeamSelect.bind(this);
        this.handleLocationSelect = this.handleLocationSelect.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleYearSelect = this.handleYearSelect.bind(this);
        this.handleQuarterSelect = this.handleQuarterSelect.bind(this);
        this.handleMonthSelect = this.handleMonthSelect.bind(this);
        this.handleWeekSelect = this.handleWeekSelect.bind(this);
        this.handleStartDateSelect = this.handleStartDateSelect.bind(this);
        this.handleEndDateSelect = this.handleEndDateSelect.bind(this);
        this.handleReportFormat = this.handleReportFormat.bind(this);

        this.generateReport = this.generateReport.bind(this);
        this.generateAccountReport = this.generateAccountReport.bind(this);
        this.generateTeamReport = this.generateTeamReport.bind(this);
        this.generateDirectoryReport = this.generateDirectoryReport.bind(this);
        this.generateFeedReport = this.generateFeedReport.bind(this);

        this.cancelReport = this.cancelReport.bind(this);
        this.goToReportSuccess = this.goToReportSuccess.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        // let isIncident = this.props.location.pathname.includes("incidents_reports") && !prevProps.location.pathname.includes("incidents_reports");
        // if (isIncident) {
        //     this.getIncidents();            
        // }

        // let isForms = this.props.location.pathname.includes("forms_report") && !prevProps.location.pathname.includes("forms_report");
        // if (isForms) {
        //     this.getForms();
        // }

        // if (this.props.location.pathname !== prevProps.location.pathname) {
        //     this.setState({ report_type_selected: "" });
        // }
    }

    componentDidMount() {
        this._isMounted = true;
        this.initComponent();
    }

    initComponent = () => {
        var self = this;

        getTeamsList()
            .then((teams) => {
                const my_teams = {
                    uuid: "my_teams",
                    name: "My Teams"
                };
                const all_teams = [my_teams, ...teams];
                self.setState({teams, all_teams});
            })
            .catch((error) => {
                notifyError(error);
            });

        if (this.props.isIncidentsReport) {
            this.getIncidents();
            get_locations("?all=true").then(function (success) {
                success.json().then(success => {
                    var locations = safe_get(success, "locations", []);
                    locations.sort(function (a, b) {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    });
                    self.setState({locations: locations});
                });
            }, function (error) {
                console.log(error)
            });
        }

        if (this.props.isFormsReport || this.props.isCoursesReport) {
            this.getForms();
            this.getSchedulesData()
        }
    }

    getIncidents = () => {
        var self = this;
        if (this._isMounted) {
            this.setState({loading_incidents: true});
            var query = "?period=all_time";
            get_incidents(query).then(function (success) {
                success.json().then(success => {
                    if (self._isMounted) {
                        var incidents = safe_get(success, "incidents", []);
                        self.setState({
                            all_incidents: incidents,
                            filtered_incidents: incidents,
                            loading_incidents: false
                        });
                    }
                });
            }, function (error) {
                console.log(error);
                self.setState({loading_incidents: false, location_error: true});
            });
        }
    }

    getForms = () => {
        var self = this;
        if (this._isMounted) {
            this.setState({loading_forms: true});

            get_form_report_data(`?form_types=${this.props.page_type}`).then(success => {
                success.json().then(success => {
                    self.setState({
                        form_report_data: safe_get(success, "report_data", []),
                        loading_forms: false
                    });
                })
            }, error => {
                console.log(error);
                self.setState({loading_forms: false});
            });
        }
    }

    getSchedulesData = () => {
        var self = this;
        get_schedule_report_data(`?form_types=${this.props.page_type}`).then(success => {
            success.json().then(success => {
                self.setState({
                    schedule_report_data: safe_get(success, "report_data", []),
                    loading_schedule_data: false
                });
            });
        }, error => {
            self.setState({
                loading_schedule_data: false
            });
        });
    }

    handleReportChange(value) {
        var disabled = false;
        if (value === "team_summary") {
            disabled = true;
        }
        this.setState({report_type_selected: value, team_uuid: undefined, buttonDisabled: disabled});
    }

    handleTeamSelect(value) {
        this.setState({team_uuid: value, buttonDisabled: false});
    }

    handleTeamsSelect = (team_uuids) => {
        this.setState({selected_team_uuids: team_uuids});
    }

    selectAllTeams = () => {
        let teams = (this.state.report_type_selected === "submission" || this.state.report_type_selected === "course") ? [...this.state.form_teams] : [...this.state.teams]
        let team_uuids = teams.map(team => team.uuid || team.team_uuid);
        this.setState({selected_team_uuids: team_uuids});
    }

    selectNoTeams = () => {
        this.setState({selected_team_uuids: []});
    }

    handleLocationSelect(value) {
        this.setState({selected_location_uuid: value, buttonDisabled: false});
    }

    handleMultiLocationSelect = (values) => {
        if (values.includes('all_locations')) {
            this.setState({selected_location_uuid: ['all_locations'], buttonDisabled: false});
        } else {
            this.setState({selected_location_uuid: values, buttonDisabled: false});
        }
    }

    handleFormSelect = (form_uuid) => {
        let form = {};
        let report_type = this.state.report_type_selected
        if (report_type === "submission" || report_type === "course") {
            form = this.state.form_report_data.find(function (obj) {
                return obj.form.form_uuid === form_uuid
            });
        } else if (report_type === "schedule" || report_type === "course_schedule") {
            form = this.state.schedule_report_data.find(function (obj) {
                return obj.form_uuid === form_uuid
            });
        }

        let form_teams = form ? form.teams : [];
        let form_schedules = form ? form.schedules : []

        this.setState({
            selected_form_uuid: form_uuid,
            form_teams: form_teams,
            form_schedules: form_schedules,
            selected_schedule_uuids: [],
            selected_team_uuids: [],
            buttonDisabled: false
        });
    }

    handleFormsSelect = (form_uuids) => {
        var schedules = [];
        var schedules_uuids = [];

        if (form_uuids.includes("select_all")) {
            form_uuids = [...this.state.schedule_report_data].map(x => x.form_uuid);
        }

        form_uuids.forEach(uuid => {
            let form = this.state.schedule_report_data.find(function (obj) {
                return obj.form_uuid === uuid;
            });

            if (form) {
                let form_schedules = form.schedules;
                form_schedules.forEach(schedule => {
                    if (!schedules_uuids.includes(schedule.schedule_uuid)) {
                        schedules_uuids.push(schedule.schedule_uuid);
                        schedules.push(schedule);
                    }
                });
            }
        });

        schedules.sort((a, b) => {
            return a.schedule_name.toLowerCase() > b.schedule_name.toLowerCase() ? 1 : -1;
        });

        this.setState({
            selected_form_uuids: form_uuids,
            form_schedules: schedules,
            selected_schedule_uuids: [],
            buttonDisabled: false,
        });
    }

    selectAllForms = () => {
        let form_uuids = [...this.state.schedule_report_data].map(obj => obj.form_uuid);
        this.handleFormsSelect(form_uuids);
    }

    selectNoForms = () => {
        this.handleFormsSelect([]);
    }

    handleScheduleSelect = (schedule_uuids) => {
        if (schedule_uuids.includes("select_all")) {
            schedule_uuids = [...this.state.form_schedules].map(x => x.schedule_uuid);
        }
        this.setState({
            selected_schedule_uuids: schedule_uuids
        });
    }

    selectAllSchedules = () => {
        let schedule_uuids = [...this.state.form_schedules].map(obj => obj.schedule_uuid)
        this.handleScheduleSelect(schedule_uuids);
    }

    selectNoSchedules = () => {
        this.handleScheduleSelect([]);
    }

    handleStatusSelect = (satuses) => {
        this.setState({selected_statuses: satuses, statuses_error_msg: null});
    }

    selectAllStatuses = () => {
        this.setState({
            selected_statuses: [
                "completed", "open", "missed", "excused"
            ]
        });
    }

    selectNoStatus = () => {
        this.setState({selected_statuses: []});
    }

    handleDateRangeChange(value) {
        this.setState({date_range_selected: value});
    }

    handleYearSelect(value) {
        this.setState({year: value});
    }

    handleQuarterSelect(value) {
        this.setState({quarter: value});
    }

    handleMonthSelect(value) {
        this.setState({monthly: value});
    }

    handleWeekSelect(value, dateString) {
        var week = parseInt(dateString.split(" ")[0]);
        var real_week = value.week()

        if (week && (week < real_week)) {
            value.add("week", 1)
        }

        this.setState({weekly: value});
    }

    handleStartDateSelect(value) {
        this.setState({custom_start_moment: value})
    }

    handleEndDateSelect(value) {
        this.setState({custom_end_moment: value})
    }

    handleReportFormat(value) {
        this.setState({report_format_type: value});
    }

    generateReport() {
        if (!this.state.generatingReport) {
            this.setState({generatingReport: true});

            switch (this.state.report_type_selected) {
                case "account_summary":
                    this.generateAccountReport();
                    break;
                case "team_summary":
                    this.generateTeamReport();
                    break;
                case "directory":
                    this.generateDirectoryReport();
                    break;
                case "open_posts":
                    this.generateOpenPostsReport();
                    break;
                case "feed":
                    this.generateFeedReport();
                    break;
                case "feed":
                    this.generateFeedReport();
                    break;
                case "osha_300":
                case "osha_300a":
                case "osha_301":
                    this.generateIncidentReport();
                    break;
                case "master_incident":
                    this.generateMasterIncidentReport();
                    break;
                case "submission":
                    this.generateSubmissionsReport();
                    break
                case "schedule":
                    this.generateScheduleReport();
                    break;
                case "assignment":
                    this.generateAssignmentReport();
                    break;
                case "course":
                    this.generateCourseReport()
                    break;
                case "course_schedule":
                    this.generateCourseScheduleReport();
                    break;
                case "course_assignment":
                    this.generateCourseAssignmentReport();
                    break;
                default:
                    this.setState({generatingReport: false});
                    break;
            }
        }
    }

    generateAccountReport() {
        var self = this;
        var body = this.generateBody();
        if (body) {
            post_account_report(body).then(function (success) {
                self.goToReportSuccess();
            }, function (error) {
                self.setState({report_error: true, generatingReport: false});
            });
        } else {
            self.setState({report_error: true, generatingReport: false});
        }
    }

    generateTeamReport() {
        var self = this;
        var body = this.generateBody();
        if (body) {
            post_team_report(body).then(function (success) {
                self.goToReportSuccess();
            }, function (error) {
                self.setState({report_error: true, generatingReport: false});
            });
        } else {
            self.setState({report_error: true, generatingReport: false});
        }

    }

    generateDirectoryReport() {
        var self = this;
        var body = this.generateBody();
        if (body) {
            post_directory_report(body).then(function (success) {
                self.goToReportSuccess();
            }, function (error) {
                self.setState({report_error: true, generatingReport: false});
            });
        } else {
            self.setState({report_error: true, generatingReport: false});
        }
    }

    generateOpenPostsReport() {
        var self = this;
        var body = this.generateBody();
        if (body) {
            post_open_posts_report(body).then(function (success) {
                self.goToReportSuccess();
            }, function (error) {
                self.setState({report_error: true, generatingReport: false});
            })
        } else {
            self.setState({report_error: true, generatingReport: false});
        }
    }

    generateFeedReport() {
        var self = this;
        var body = this.generateBody();
        if (body) {
            feed_posts_report(body).then(function (success) {
                self.goToReportSuccess();
            }, function (error) {
                self.setState({report_error: true, generatingReport: false});
            });
        } else {
            self.setState({report_error: true, generatingReport: false});
        }
    }

    generateIncidentReport() {
        var self = this;
        var body = this.generateBody();
        if (body) {
            incidents_report(body).then(function (success) {
                self.goToReportSuccess();
            }, function (error) {
                self.setState({report_error: true, generatingReport: false});
            });
        } else {
            self.setState({report_error: true, generatingReport: false});
        }
    }

    generateMasterIncidentReport = async () => {
        const body = this.generateBody();
        const errorState = {report_error: true, generatingReport: false};
        try {
            if (!body) {
                throw new Error('empty body');
            }
            const res = await masterIncidentReport(body);
            this.goToReportSuccess();
        } catch (err) {
            console.error(err);
            this.setState(errorState);
        }
    }

    generateSubmissionsReport = () => {
        var self = this;
        var body = this.generateBody();
        if (body) {
            form_submission_report(body).then(success => {
                self.goToReportSuccess();
            }, error => {
                self.setState({report_error: true, generatingReport: false});
            });
        } else {
            self.setState({report_error: true, generatingReport: false});
        }
    }

    generateScheduleReport = () => {
        var self = this;
        var body = this.generateBody();
        if (body) {
            generate_schedule_report(body).then(success => {
                self.goToReportSuccess();
            }, error => {
                self.setState({report_error: true, generatingReport: false});
            });
        } else {
            self.setState({report_error: true, generatingReport: false});
        }

    }

    generateAssignmentReport = () => {
        var self = this;
        var body = this.generateBody();
        if (body) {
            generate_assignment_report(body).then(success => {
                self.goToReportSuccess();
            }, error => {
                self.setState({report_error: true, generatingReport: false});
            });
        } else {
            self.setState({report_error: true, generatingReport: false});
        }
    }

    generateCourseReport = () => {
        var self = this;
        var body = this.generateBody();
        if (body) {
            course_report(body).then(success => {
                self.goToReportSuccess();
            }, error => {
                self.setState({report_error: true, generatingReport: false});
            });
        } else {
            self.setState({report_error: true, generatingReport: false});
        }
    }

    generateCourseScheduleReport = () => {
        var self = this;
        var body = this.generateBody();
        if (body) {
            course_schedule_report(body).then(success => {
                self.goToReportSuccess();
            }, error => {
                self.setState({report_error: true, generatingReport: false});
            });
        } else {
            self.setState({report_error: true, generatingReport: false});
        }
    }

    generateCourseAssignmentReport = () => {
        var self = this;
        var body = this.generateBody();
        if (body) {
            course_assignment_report(body).then(success => {
                self.goToReportSuccess();
            }, error => {
                self.setState({report_error: true, generatingReport: false});
            });
        } else {
            self.setState({report_error: true, generatingReport: false});
        }
    }

    generateBody = () => {
        var body = {}
        let report_type = this.state.report_type_selected;

        if (report_type === 'directory') {
            body['include_deactivated_users'] = this.state.include_deactivated_users;
        }

        if (report_type === "team_summary") {
            if (!this.state.team_uuid) {
                this.setState({generatingReport: false, team_error_msg: "A team is required."});
                return;
            } else {
                body["team_uuid"] = this.state.team_uuid;
            }
        }

        if (report_type === "submission" || report_type === "schedule" || report_type === "course" || report_type === "course_schedule") {
            if (this.state.selected_form_uuid) {
                body["form_uuid"] = this.state.selected_form_uuid;
            } else {
                this.setState({template_error_msg: "A template is required."})
                return null;
            }
        }

        if (report_type === "submission" || report_type === "open_posts" || report_type === "feed" || report_type === "course") {
            if (this.state.selected_team_uuids && this.state.selected_team_uuids.length > 0) {
                body["team_uuids"] = this.state.selected_team_uuids;

                if (report_type === "feed") {
                    body["format"] = this.state.report_format_type;
                }
            } else {
                this.setState({generatingReport: false, team_error_msg: "A team is required."});
                return null
            }
        }

        if (report_type === "assignment" || report_type === "course_assignment") {
            if (this.state.selected_form_uuids.length > 0) {
                body["form_uuids"] = this.state.selected_form_uuids;
            } else {
                this.setState({template_error_msg: "At least one template is required."});
                return null;
            }

            let selected_statuses = [...this.state.selected_statuses];

            if (selected_statuses.length === 0) {
                this.setState({generatingReport: false, statuses_error_msg: "At least one status is required."});
                return null
            }

            let status = {
                "completed": selected_statuses.includes("completed"),
                "open": selected_statuses.includes("open"),
                "missed": selected_statuses.includes("missed"),
                "excused": selected_statuses.includes("excused"),
            };

            body["status"] = status;

        }

        if (report_type === "schedule" || report_type === "course_schedule" || report_type === "assignment" || report_type === "course_assignment" || report_type === 'incidents') {
            if (this.state.selected_schedule_uuids.length > 0) {
                body["schedule_uuids"] = this.state.selected_schedule_uuids;
            } else {
                this.setState({schedule_error_msg: "At least one schedule is required."})
                return null;
            }
        }

        if (report_type === "osha_300") {
            body["export_type"] = "300";
            if (this.state.selected_location_uuid[0] === 'all_locations') {
                body['location_uuids'] = this.state.locations.map(location => location.location_uuid);
            } else {
                body["location_uuids"] = this.state.selected_location_uuid;
            }
        } else if (report_type === "osha_300a") {
            body["export_type"] = "300a";
            if (this.state.selected_location_uuid[0] === 'all_locations') {
                body['location_uuids'] = this.state.locations.map(location => location.location_uuid);
            } else {
                body["location_uuids"] = this.state.selected_location_uuid;
            }
        } else if (report_type === 'master_incident') {
            if (this.state.selected_location_uuid === "" || this.state.selected_location_uuid.length === 0) {
                NotificationAlert("error", "", "Please select a location.");
                this.setState({generatingReport: false});
                return;
            } else if (this.state.selected_location_uuid[0] === 'all_locations') {
                body['location_uuids'] = this.state.locations.map(location => location.location_uuid);
            } else {
                body["location_uuids"] = this.state.selected_location_uuid;
            }
        }

        if (report_type.includes("osha")) {
            if (this.state.selected_location_uuid === "") {
                NotificationAlert("error", "", "Please select a location.");
                this.setState({generatingReport: false});
                return;
            } else {
                body["location_uuid"] = this.state.selected_location_uuid;
            }
        }

        switch (this.state.date_range_selected) {
            case "annually":
                body["period"] = "annual";
                body["year"] = this.state.year;
                break;
            case "quarterly":
                var range = this.state.quarter.split(" ");
                body["period"] = "quarter";
                body["year"] = range[1];
                body["quarter"] = range[0][1];
                break;
            case "monthly":
                var month = this.state.monthly.split(" ");
                body["period"] = "month";
                body["year"] = month[1];
                body["month"] = monthToInt(month[0]);
                break;
            case "weekly":
                const value = this.state.weekly
                body["period"] = "custom";
                body["from_date"] = value.startOf('week').format("YYYY-MM-DD");
                body["to_date"] = value.endOf('week').format("YYYY-MM-DD");
                break;
            case "custom":
                body["period"] = "custom";
                body["from_date"] = dayjs(this.state.custom_start_moment, 'YYYY/MM/DD').format("YYYY-MM-DD");
                body["to_date"] = dayjs(this.state.custom_end_moment, 'YYYY/MM/DD').format("YYYY-MM-DD");

                var diff = this.state.custom_start_moment.diff(this.state.custom_end_moment, 'day');
                console.log('total days is: ' + diff);
                if (diff < -185) {
                    this.setState({report_error_msg: "Max range of 6 months."})
                    return null;
                } else if (diff > 0) {
                    this.setState({report_error_msg: "End Date has to be after Start Date."})
                    return null;
                }
                break;
            case "all_time":
                body["period"] = "all_time";
                break;
            default:
                break;
        }

        this.setState({reportJSON: body});
        return JSON.stringify(body);
    }

    goToReportSuccess() {
        this.setState({reportSuccess: true});
        // if (this.props.isIncidentsReport) {
        //     this.props.history.push('/'+ this.props.organization.organization_uuid + '/home/incidents_reports/success');
        // }
        // else if (this.props.isFormsReport) {
        //     this.props.history.push('/'+ this.props.organization.organization_uuid + '/home/forms_report/success');
        // }
        // else {
        //     this.props.history.push('/'+ this.props.organization.organization_uuid + '/home/reports/success');
        // }
    }

    getHistory = (isRefresh = false) => {
        var self = this;

        this.setState({viewHistory: true, reportSuccess: false});
        if (isRefresh) {
            this.setState({reports: null});
        }

        if (this.props.isIncidentsReport) {
            incidents_report_history().then(function (success) {
                success.json().then(success => {
                    self.setState({
                        reports: safe_get(success, "history", []),
                    });
                });
            }, function (error) {
                notifyError(error);
            });
        } else if (this.props.isFormsReport) {
            get_form_reports_history().then(function (success) {
                success.json().then(success => {
                    self.setState({
                        reports: safe_get(success, "history", []),
                    });
                });
            }, function (error) {
                notifyError(error);
            });
        } else if (this.props.isFeedReport) {
            get_feed_reports_history().then(function (success) {
                success.json().then(success => {
                    self.setState({
                        reports: safe_get(success, "history", []),
                    });
                });
            }, function (error) {
                notifyError(error);
            });
        } else if (this.props.isCoursesReport) {
            get_lms_report_history().then(function (success) {
                success.json().then(success => {
                    self.setState({
                        reports: safe_get(success, "history", []),
                    });
                });
            }, function (error) {
                notifyError(error);
            });
        } else {
            get_reports_history().then(function (success) {
                success.json().then(success => {
                    self.setState({
                        reports: safe_get(success, "history", []),
                    });
                });
            }, function (error) {
                notifyError(error);
            });
        }
    }

    disabledWeek(current) {
        // Can not select week after today 
        return current > dayjs().endOf('week');
    }

    disabledDate(current) {
        // Can not select day after today 
        return current > dayjs().endOf('day');
    }

    cancelReport() {
        this.setState({
            report_type_selected: "",
            generatingReport: false,
            custom_start_moment: today_moment,
            custom_end_moment: today_moment,
            selected_form_uuids: [],
            selected_schedule_uuids: [],
            selected_team_uuids: [],
            selected_statuses: [],
            form_schedules: undefined,
            date_range_selected: "all_time",
            year: current_year,
            report_error: false,
            report_error_msg: undefined,
            reportSuccess: false,
            viewHistory: false,
            reports: undefined
        });
        scrollToTop("page-head");
    }

    render() {
        if (this.state.reportSuccess) {
            return (
                <div style={{padding: "10px"}}>
                    {
                        this.state.showScheduleModal &&
                        <ReportScheduleModal
                            show={this.state.showScheduleModal}
                            cancel={() => {
                                this.setState({showScheduleModal: false});
                            }}
                            isCreate={true}
                            reportType={this.state.report_type_selected}
                            reportParams={this.state.reportJSON}
                            user={this.props.user}
                            goToHistory={this.getHistory}
                        />
                    }
                    <button className="ButtonLink link-hover zero-light-blue" onClick={this.cancelReport}>
                        Back to Reports
                    </button>
                    <div className="panel-heading article text-center" style={{height: "auto"}}>
                        <img style={{height: '100px'}} src={ZERO_logo} alt="ZERO Logo"/>
                    </div>
                    <div className="panel-heading article" style={{height: "auto"}}>
                        <h3 className="directory teams header">We're generating that report for you...</h3>

                        <p className="zero-blue text-center" style={{maxWidth: '350px', margin: '0 auto'}}>
                            It should only take a few minutes. When it's done we'll email you a download link. Please
                            contact us at <a href="mailto:support@teamzero.com"
                                             className="link-hover zero-light-blue ">support@teamzero.com</a> if you
                            have any questions.
                        </p>

                    </div>
                    <div className="mar-top-10">
                        <button className="ButtonLink link-hover zero-light-blue" onClick={this.getHistory}
                                style={{margin: '0 auto', display: "block"}}>
                            View History
                        </button>
                    </div>
                    {/* {
                  this.props.isFormsReport &&
                  <div>
                    <hr />
                    <p className="text-center">
                      Want to create a recurring schedule for this report? <button className="ButtonLink link-hover zero-light-blue" onClick={() => {this.setState({ showScheduleModal: true })}}>Click here.</button>
                    </p>
                  </div>
                }
                */}
                </div>
            )
        } else if (this.state.viewHistory) {
            return (
                <ReportHistory
                    reports={this.state.reports}
                    isIncidents={this.props.isIncidentsReport}
                    isForms={this.props.isFormsReport}
                    isFeed={this.props.isFeedReport}
                    back={this.cancelReport}
                    refresh={this.getHistory}
                />
            )
        }
        return (
            <div className="panel" style={{maxWidth: '850px', margin: '0 auto', padding: "20px"}}>

                {
                    this.state.report_type_selected === "osha_301" &&
                    <button className="ButtonLink link-hover zero-light-blue" onClick={this.cancelReport}>
                        Back to reports
                    </button>
                }
                <div className="panel-heading article text-center mar-top-5"
                     style={{height: (this.state.report_type_selected !== "" && this.state.report_type_selected !== "osha_301") ? "200px" : "auto"}}>
                    {
                        this.state.report_type_selected !== "" && this.state.report_type_selected !== "osha_301" &&
                        <img alt="report-img" src={checklist} height="150"
                             style={{display: "block", margin: "0 auto", height: "150px"}}/>
                    }
                    <h3 className="directory teams"
                        style={{marginTop: (this.state.report_type_selected === "" ? "0px" : "20px")}}>
                        {
                            this.state.report_type_selected === "" ? "What report would you like?" :
                                this.state.report_type_selected === "account_summary" ? "Generate Account Report" :
                                    this.state.report_type_selected === "team_summary" ? "Generate Team Report" :
                                        this.state.report_type_selected === "directory" ? "Generate Members Report" :
                                            this.state.report_type_selected === "open_posts" ? "Generate Open Posts Report" :
                                                this.state.report_type_selected === "feed" ? "Generate Feed Report" :
                                                    this.state.report_type_selected === "osha_300" ? "Generate OSHA Form 300" :
                                                        this.state.report_type_selected === "osha_300a" ? "Generate OSHA Form 300A" :
                                                            this.state.report_type_selected === "osha_301" ? "Generate OSHA Form 301" :
                                                                this.state.report_type_selected === "master_incident" ? "Master Incident Report" :
                                                                    this.state.report_type_selected === "submission" ? "General Template Report" :
                                                                        this.state.report_type_selected === "schedule" ? "Schedule Report" :
                                                                            this.state.report_type_selected === "assignment" ? "Assignments Report" :
                                                                                this.state.report_type_selected === "course" ? "General Course Report" :
                                                                                    this.state.report_type_selected === "course_schedule" ? "Course Schedule Report" :
                                                                                        this.state.report_type_selected === "course_assignment" ? "Course Assignments Report" : ""

                        }
                    </h3>
                </div>

                {
                    this.state.report_type_selected === "" &&
                    <div className="row mar-btm-10">
                        <div className="col-sm-12">
                            <button className="ButtonLink link-hover zero-light-blue"
                                    style={{margin: "0 auto", display: "block"}} onClick={this.getHistory}>
                                View History
                            </button>
                        </div>
                    </div>
                }

                {
                    this.state.report_type_selected === "" && !this.props.isIncidentsReport && !this.props.isFormsReport && !this.props.isCoursesReport &&
                    <div className="row">
                        {
                            isAdmin(this.props.user) &&
                            <div className="col-md-4 col-sm-6">
                                <div className="panel reports" onClick={() => {
                                    this.setState({report_type_selected: "account_summary"});
                                    scrollToTop("page-head");
                                }}>
                                    <div className="panel-body">
                                        <GlobalOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                        <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                        <h3>Account Report</h3>
                                        <p className="zero-dark-grey">View summary data for all your teams. Compare
                                            performance and activity across teams.</p>
                                    </div>
                                </div>
                            </div>
                        }
                        { isAdminOrTeamLead(this.props.user) &&
                            <div className="col-md-4 col-sm-6">
                                <div className="panel reports" onClick={() => {
                                    this.setState({report_type_selected: "team_summary"});
                                    scrollToTop("page-head");
                                }}>
                                    <div className="panel-body">
                                        <TeamOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                        <Tag color="green" style={{
                                                padding: ".0em .5em .0em",
                                                marginLeft: "8px",
                                                verticalAlign: "super"
                                            }}>.xlsx</Tag>
                                        <h3>Team Report</h3>
                                        <p className="zero-dark-grey">View "team-level" analytics, including a full list of
                                            members in the team and their activity.</p>
                                    </div>
                                </div>
                            </div>
                        }
                        { isAdminOrTeamLead(this.props.user) &&
                            <div className="col-md-4 col-sm-6">
                                <div className="panel reports" onClick={() => {
                                    this.setState({report_type_selected: "directory"});
                                    scrollToTop("page-head");
                                }}>
                                    <div className="panel-body">
                                        <UserOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                        <Tag color="green" style={{
                                                padding: ".0em .5em .0em",
                                                marginLeft: "8px",
                                                verticalAlign: "super"
                                            }}>.xlsx</Tag>
                                        <h3>Members Report</h3>
                                        <p className="zero-dark-grey">View "user-level" analytics for all members in your
                                            teams.</p>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "feed"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <LayoutOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    {/* <span className="label label-info" style={{padding: ".3em .6em .3em", marginLeft: "8px", verticalAlign: "super"}}>.docx</span> */}
                                    <h3>Feed Report</h3>
                                    <p className="zero-dark-grey">View a complete list of all posts by team.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "open_posts", date_range_selected: "all_time"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <ToolOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    <h3>Open Posts Report</h3>
                                    <p className="zero-dark-grey">View a complete list of all open posts by team.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.report_type_selected === "" && this.props.isIncidentsReport && !this.props.isFormsReport && !this.props.isCoursesReport &&
                    <div className="row">

                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "osha_300"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <GlobalOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    <h3>OSHA Form 300</h3>
                                    <p className="zero-dark-grey">A log of work-related injuries and illnesses.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "osha_300a"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <TeamOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    <h3>OSHA Form 300A</h3>
                                    <p className="zero-dark-grey">Summary of work-related injuries and illnesses.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "osha_301"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <UserOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    <h3>OSHA Form 301</h3>
                                    <p className="zero-dark-grey">Injury and illness incident report.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "master_incident"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <GlobalOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    <h3>Master Incident Report</h3>
                                    <p className="zero-dark-grey">All raw incident data in an excel report.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.report_type_selected === "" && this.props.isFormsReport && !this.props.isIncidentsReport && !this.props.isCoursesReport &&
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "submission"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <GlobalOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    <h3>Template Report</h3>
                                    <p className="zero-dark-grey">A list of all submissions by template and by team.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "schedule"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <CalendarOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    <h3>Schedule Report</h3>
                                    <p className="zero-dark-grey">A list of all submissions by template and by
                                        schedule.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "assignment"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <PushpinOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    <h3>Assignments Report</h3>
                                    <p className="zero-dark-grey">A list of all open assignments by schedule.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.report_type_selected === "" && !this.props.isFormsReport && !this.props.isIncidentsReport && this.props.isCoursesReport &&
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "course"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <GlobalOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    <h3>Course Report</h3>
                                    <p className="zero-dark-grey">A list of all submissions by course and by team.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "course_schedule"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <CalendarOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    <h3>Schedule Report</h3>
                                    <p className="zero-dark-grey">A list of all submissions by schedule and by team.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="panel reports" onClick={() => {
                                this.setState({report_type_selected: "course_assignment"});
                                scrollToTop("page-head");
                            }}>
                                <div className="panel-body">
                                    <PushpinOutlined className="zero-blue" style={{fontSize: "20px"}}/>
                                    <Tag color="green" style={{
                                            padding: ".0em .5em .0em",
                                            marginLeft: "8px",
                                            verticalAlign: "super"
                                        }}>.xlsx</Tag>
                                    <h3>Assignments Report</h3>
                                    <p className="zero-dark-grey">A list of all open assignments by schedule.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.report_type_selected !== "" &&
                    <div>
                        <div className="panel-heading text-center"
                             style={{maxWidth: '400px', margin: '0 auto', paddingTop: '10px'}}>
                            {
                                this.state.report_type_selected === "master_incident" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Master Incident Report</b> is a raw data export for incident data.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "account_summary" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Account Report</b> contains summary data for all your teams. Compare
                                    performance and activity across teams.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "team_summary" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Team Report</b> contains "team-level" analytics, including a full list of
                                    members in the team and their activity.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "directory" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Members Report</b> contains "user-level" analytics for all members in your
                                    teams.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "open_posts" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Open Posts Report</b> contains a complete list of all open posts by team.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "feed" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Feed Report</b> contains a complete list of all posts by team.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "osha_300" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Form 300</b> is log of work-related injuries and illnesses.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "osha_300a" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Form 300</b> is log of work-related injuries and illnesses.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "osha_301" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Form 301</b> is an injury and illness incident report. To download an .XLS
                                    file, click the download icon on the right.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "submission" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Template Report</b> is a list of all forms data.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "schedule" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Schedule Report</b> is a list of all submissions.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "assignment" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Assignment Report</b> is a list of assignments by schedule.
                                </p>
                            }

                            {
                                this.state.report_type_selected === "course" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Course Report</b> is a list of all forms data.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "course_schedule" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Course Schedule Report</b> is a list of all submissions.
                                </p>
                            }
                            {
                                this.state.report_type_selected === "course_assignment" &&
                                <p className="zero-blue text-center" style={{padding: "0 8px"}}>
                                    The <b>Course Assignment Report</b> is a list of assignments by schedule.
                                </p>
                            }
                        </div>
                        {
                            (this.state.report_type_selected === "team_summary") &&
                            <div>
                                <div className="panel-heading article ">
                                    <h3 className="directory teams header">What team is this for?</h3>
                                </div>
                                <div className="text-center">
                                    {/* <p className="zero-blue mar-rgt" style={{display: 'inline-block', marginLeft: '10px'}}>Team Name</p> */}
                                    <Select style={{width: 400}} onChange={this.handleTeamSelect} virtual={false}
                                            placeholder="Select a team...">
                                        {
                                            this.state.teams.map((team, index) => (
                                                <Option key={index} value={team.uuid}>{team.name}</Option>
                                            ))
                                        }
                                    </Select>
                                    
                                    {this.state.team_error_msg &&
                                        <small className="error text-center"
                                                   style={{display: 'block'}}>
                                        {this.state.team_error_msg}
                                        </small>
                                    }
                                </div>
                            </div>
                        }
                        {
                            (this.state.report_type_selected === "open_posts" || this.state.report_type_selected === "feed") &&
                            <div>
                                <div className="panel-heading article ">
                                    <h3 className="directory teams header">What team is this for?</h3>
                                </div>
                                <div className="text-center">
                                    {/* <p className="zero-blue mar-rgt" style={{display: 'inline-block', marginLeft: '10px'}}>Team Name</p> */}
                                    <Select style={{width: 400}} onChange={this.handleTeamsSelect}
                                            placeholder="Select team(s)" mode="tags"
                                            value={this.state.selected_team_uuids} virtual={false}>
                                        <Option disabled>
                                            <span className="blue-link"
                                                  onClick={this.selectAllTeams}>Select All</span> &bull; <span
                                            className="blue-link" onClick={this.selectNoTeams}>Select None</span>
                                        </Option>
                                        {
                                            this.state.teams.map((team, index) => (
                                                <Option key={index} value={team.uuid}>{team.name}</Option>
                                            ))
                                        }
                                    </Select>
                                    {this.state.team_error_msg &&
                                        <small className="error text-center"
                                                   style={{display: 'block'}}>
                                        {this.state.team_error_msg}
                                        </small>
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.report_type_selected === "master_incident" &&
                            <div>
                                <div className="panel-heading article ">
                                    <h3 className="directory teams header">What location is this for?</h3>
                                </div>
                                <div className="text-center">
                                    <Select
                                        style={{width: 400}}
                                        onChange={this.handleMultiLocationSelect}
                                        virtual={false}
                                        placeholder="Select a location..."
                                        mode="multiple"
                                        value={this.state.selected_location_uuid || []}
                                    >
                                        <Option value={"all_locations"}>All Locations</Option>
                                        {
                                            this.state.locations.map((location, index) => (
                                                <Option key={index}
                                                        value={location.location_uuid}>{location.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>
                        }
                        {
                            (this.state.report_type_selected === "osha_300" || this.state.report_type_selected === "osha_300a") &&
                            <div>
                                <div className="panel-heading article ">
                                    <h3 className="directory teams header">What location is this for?</h3>
                                </div>
                                <div className="text-center">
                                    {/* <p className="zero-blue mar-rgt" style={{display: 'inline-block', marginLeft: '10px'}}>Location Name</p> */}
                                    <Select style={{width: 400}} onChange={this.handleLocationSelect} virtual={false}
                                            placeholder="Select a location...">
                                        {
                                            this.state.report_type_selected === "osha_300" && isAdmin(this.props.user) &&
                                            <Option value={"all_locations"}>All Locations</Option>
                                        }
                                        {
                                            this.state.locations.map((location, index) => (
                                                <Option key={index}
                                                        value={location.location_uuid}>{location.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>
                        }
                        {
                            this.state.report_type_selected === "osha_301" &&
                            <IncidentsTable loading={this.state.loading_incidents}
                                            incidents={this.state.filtered_incidents} getIncidents={this.getIncidents}
                                            incident_types={[]} isReportTable={true}/>
                        }
                        {
                            (this.state.report_type_selected === "submission" || this.state.report_type_selected === "course") &&
                            <div>
                                <div className="panel-heading article ">
                                    <h3 className="directory teams header">What {this.props.isFormsReport && "template"}{this.props.isCoursesReport && "course"} is
                                        this for?</h3>
                                </div>
                                <div className="panel-heading text-center" style={{height: "auto"}}>
                                    <Select style={{width: 400}} onChange={this.handleFormSelect}
                                            loading={this.state.loading_forms} disabled={this.state.loading_forms}
                                            placeholder={`Select ${this.props.isFormsReport ? "template" : this.props.isCoursesReport ? "course" : ""}...`}
                                            virtual={false}>
                                        {
                                            this.state.form_report_data.map((obj, index) => (
                                                <Option key={index} value={obj.form.form_uuid}>{obj.form.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                {
                                    this.state.template_error_msg &&
                                    <small className="error text-center"
                                           style={{display: 'block'}}>{this.state.template_error_msg}</small>
                                }

                                {
                                    this.state.form_teams &&
                                    <div>
                                        <div className="panel-heading article ">
                                            <h3 className="directory teams header">What team is this for?</h3>
                                        </div>
                                        <div className="panel-heading text-center" style={{height: "auto"}}>
                                            <Select style={{width: 400}} onChange={this.handleTeamsSelect}
                                                    placeholder="Select team(s)..."
                                                    value={this.state.selected_team_uuids} mode="tags" virtual={false}>
                                                <Select.Option disabled>
                                                    <span className="blue-link"
                                                          onClick={this.selectAllTeams}>Select All</span> &bull; <span
                                                    className="blue-link"
                                                    onClick={this.selectNoTeams}>Select None</span>
                                                </Select.Option>
                                                {
                                                    this.state.form_teams.map((team, index) => (
                                                        <Option key={index} value={team.team_uuid}>{team.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>

                                        {
                                            this.state.team_error_msg &&
                                            <small className="error text-center"
                                                   style={{display: 'block'}}>{this.state.team_error_msg}</small>
                                        }
                                    </div>
                                }

                            </div>
                        }
                        {
                            (this.state.report_type_selected === "schedule" || this.state.report_type_selected === "course_schedule") &&
                            <div>
                                <div className="panel-heading article ">
                                    <h3 className="directory teams header">What {this.props.isFormsReport && "template"}{this.props.isCoursesReport && "course"} is
                                        this for?</h3>
                                </div>
                                <div className="panel-heading text-center" style={{height: "auto"}}>
                                    <Select style={{width: 400}} onChange={this.handleFormSelect}
                                            loading={this.state.loading_schedule_data}
                                            disabled={this.state.loading_schedule_data}
                                            placeholder={`Select ${this.props.isFormsReport ? "template" : this.props.isCoursesReport ? "course" : ""}...`}
                                            virtual={false}>
                                        {
                                            this.state.schedule_report_data.map((obj, index) => (
                                                <Option key={index} value={obj.form_uuid}>{obj.form_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                {
                                    this.state.template_error_msg &&
                                    <small className="error text-center"
                                           style={{display: 'block'}}>{this.state.template_error_msg}</small>
                                }

                                {
                                    this.state.form_schedules &&
                                    <div>
                                        <div className="panel-heading article ">
                                            <h3 className="directory teams header">What schedule is this for?</h3>
                                        </div>
                                        <div className="panel-heading text-center" style={{height: "auto"}}>
                                            <Select style={{width: 400}} onChange={this.handleScheduleSelect}
                                                    mode="tags" value={this.state.selected_schedule_uuids}
                                                    placeholder="Select schedule(s)..." virtual={false}>
                                                <Select.Option disabled>
                                                    <span className="blue-link" onClick={this.selectAllSchedules}>Select All</span> &bull;
                                                    <span className="blue-link" onClick={this.selectNoSchedules}>Select None</span>
                                                </Select.Option>
                                                {
                                                    this.state.form_schedules.map((schedule, index) => (
                                                        <Option key={index}
                                                                value={schedule.schedule_uuid}>{schedule.schedule_name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                        {
                                            this.state.schedule_error_msg &&
                                            <small className="error text-center"
                                                   style={{display: 'block'}}>{this.state.schedule_error_msg}</small>
                                        }
                                    </div>
                                }

                            </div>
                        }
                        {
                            (this.state.report_type_selected === "assignment" || this.state.report_type_selected === "course_assignment") &&
                            <div>
                                <div className="panel-heading article ">
                                    <h3 className="directory teams header">What {this.props.isFormsReport && "template"}{this.props.isCoursesReport && "course"} is
                                        this for?</h3>
                                </div>
                                <div className="panel-heading text-center" style={{height: "auto"}}>
                                    <Select style={{width: 400}} onChange={this.handleFormsSelect}
                                            loading={this.state.loading_schedule_data}
                                            value={this.state.selected_form_uuids}
                                            disabled={this.state.loading_schedule_data}
                                            placeholder={`Select ${this.props.isFormsReport ? "template" : this.props.isCoursesReport ? "course" : ""}...`}
                                            mode="multiple" virtual={false}>
                                        <Option disabled>
                                            <span className="blue-link"
                                                  onClick={this.selectAllForms}>Select All</span> &bull; <span
                                            className="blue-link" onClick={this.selectNoForms}>Select None</span>
                                        </Option>
                                        {
                                            this.state.schedule_report_data.map((obj, index) => (
                                                <Option key={index} value={obj.form_uuid}>{obj.form_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                {
                                    this.state.template_error_msg &&
                                    <small className="error text-center"
                                           style={{display: 'block'}}>{this.state.template_error_msg}</small>
                                }

                                {
                                    this.state.form_schedules &&
                                    <div>
                                        <div className="panel-heading article ">
                                            <h3 className="directory teams header">What schedule is this for?</h3>
                                        </div>
                                        <div className="panel-heading text-center" style={{height: "auto"}}>
                                            <Select style={{width: 400}} onChange={this.handleScheduleSelect}
                                                    mode="multiple" value={this.state.selected_schedule_uuids}
                                                    placeholder="Select schedule(s)..." virtual={false}>
                                                <Option disabled>
                                                    <span className="blue-link" onClick={this.selectAllSchedules}>Select All</span> &bull;
                                                    <span className="blue-link" onClick={this.selectNoSchedules}>Select None</span>
                                                </Option>
                                                {
                                                    this.state.form_schedules.map((schedule, index) => (
                                                        <Option key={index}
                                                                value={schedule.schedule_uuid}>{schedule.schedule_name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                        {
                                            this.state.schedule_error_msg &&
                                            <small className="error text-center"
                                                   style={{display: 'block'}}>{this.state.schedule_error_msg}</small>
                                        }

                                        <div className="panel-heading article ">
                                            <h3 className="directory teams header">What status is this for?</h3>
                                        </div>
                                        <div className="panel-heading text-center" style={{height: "auto"}}>
                                            <Select style={{width: 400}} onChange={this.handleStatusSelect} mode="tags"
                                                    placeholder="Select status..." value={this.state.selected_statuses}
                                                    virtual={false}>
                                                <Option disabled>
                                                    <span className="blue-link" onClick={this.selectAllStatuses}>Select All</span> &bull;
                                                    <span className="blue-link" onClick={this.selectNoStatus}>Select None</span>
                                                </Option>
                                                <Option value={"completed"}>Completed</Option>
                                                <Option value={"open"}>Open</Option>
                                                <Option value={"missed"}>Missed</Option>
                                                <Option value={"excused"}>Excused</Option>
                                            </Select>
                                        </div>
                                        {
                                            this.state.statuses_error_msg &&
                                            <small className="error text-center"
                                                   style={{display: 'block'}}>{this.state.statuses_error_msg}</small>
                                        }
                                    </div>
                                }

                            </div>
                        }
                        <br/>
                        {
                            this.state.report_type_selected !== "osha_301" &&
                            <div>
                                <div className="panel-heading article ">
                                    <h3 className="directory teams header">What time period should be included?</h3>
                                </div>
                                <div className="panel-heading text-center" style={{height: "auto"}}>
                                    <p className="zero-blue mar-rgt" style={{display: 'inline-block'}}>Report Period</p>
                                    <Select virtual={false} defaultValue={"all_time"} style={{width: 200}}
                                            onChange={this.handleDateRangeChange}>
                                        <Option value="all_time">All Time</Option>
                                        <Option value="annually">Annually</Option>
                                        <Option value="quarterly">Quarterly</Option>
                                        <Option value="monthly">Monthly</Option>
                                        <Option value="weekly">Weekly</Option>
                                        <Option value="custom">Custom Date Range</Option>
                                    </Select>

                                    {
                                        this.state.date_range_selected === "annually" &&
                                        <div style={{marginTop: '8px'}}>
                                            <p className="zero-blue mar-rgt"
                                               style={{display: 'inline-block', marginLeft: '63px'}}>Year</p>
                                            <Select virtual={false} defaultValue={this.state.year} style={{width: 200}}
                                                    onChange={this.handleYearSelect}>
                                                {
                                                    this.state.report_years.map((year, index) => (
                                                        <Option key={index} value={year}>{year}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                    }

                                    {
                                        this.state.date_range_selected === "quarterly" &&
                                        <div style={{marginTop: '8px'}}>
                                            <p className="zero-blue mar-rgt"
                                               style={{display: 'inline-block', marginLeft: '40px'}}>Quarter</p>
                                            <Select virtual={false} defaultValue={this.state.quarter}
                                                    style={{width: 200}} onChange={this.handleQuarterSelect}>
                                                {
                                                    this.state.report_quarters.map((quarter, index) => (
                                                        <Option key={index} value={quarter}>{quarter}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                    }

                                    {
                                        this.state.date_range_selected === "monthly" &&
                                        <div style={{marginTop: '8px'}}>
                                            <p className="zero-blue mar-rgt"
                                               style={{display: 'inline-block', marginLeft: '48px'}}>Month</p>
                                            <Select virtual={false} defaultValue={this.state.monthly}
                                                    style={{width: 200}} onChange={this.handleMonthSelect}>
                                                {
                                                    this.state.report_months.map((month, index) => (
                                                        <Option key={index} value={month}>{month}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                    }
                                    {
                                        this.state.date_range_selected === "weekly" &&
                                        <div style={{marginTop: '8px'}}>
                                            <p className="zero-blue " style={{
                                                display: 'inline-block',
                                                marginLeft: '48px',
                                                marginRight: "20px"
                                            }}>Week</p>
                                            <DatePicker.WeekPicker className="custom-range-picker" style={{width: 200}}
                                                                   placeholder="Select a week" value={this.state.weekly}
                                                                   format={"w (YYYY)"} onChange={this.handleWeekSelect}
                                                                   disabledDate={this.disabledWeek} allowClear={false}/>
                                        </div>
                                    }
                                    {
                                        this.state.date_range_selected === "custom" &&
                                        <div>
                                            <div style={{marginTop: '8px'}}>
                                                <p className="zero-blue mar-rgt"
                                                   style={{display: 'inline-block', marginLeft: '25px'}}>Start Date</p>
                                                <DatePicker value={this.state.custom_start_moment}
                                                            className="custom-range-picker" style={{width: 200}}
                                                            format={"MM/DD/YYYY"} onChange={this.handleStartDateSelect}
                                                            disabledDate={this.disabledDate} allowClear={false}/>
                                            </div>
                                            <div style={{marginTop: '8px'}}>
                                                <p className="zero-blue mar-rgt"
                                                   style={{display: 'inline-block', marginLeft: '30px'}}>End Date</p>
                                                <DatePicker value={this.state.custom_end_moment}
                                                            className="custom-range-picker" style={{width: 200}}
                                                            format={"MM/DD/YYYY"} onChange={this.handleEndDateSelect}
                                                            disabledDate={this.disabledDate} allowClear={false}/>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.report_error_msg &&
                                        <small className="error"
                                               style={{display: 'block'}}>{this.state.report_error_msg}</small>
                                    }
                                </div>
                            </div>
                        }

                        {this.state.report_type_selected === "directory" &&
                            <div className="zero-blue text-center" style={{marginTop: 8}}>
                                <Checkbox
                                    checked={this.state.include_deactivated_users}
                                    onChange={e => this.setState({include_deactivated_users: e.target.checked})}
                                >Include Deactivated Users</Checkbox>
                            </div>
                        }

                        {/* {
                            this.state.report_type_selected === "feed" &&
                            <div className="panel-heading text-center" style={{height: "auto"}}>
                                <p className="zero-blue " style={{display: 'inline-block', marginRight: "10px"}}>Report Format</p>
                                <Select defaultValue={"xlsx"} style={{ width: 200}} onChange={this.handleReportFormat}>                                
                                    <Option value="xlsx">xlsx</Option>                               
                                    <Option value="docx">docx</Option>
                                </Select>
                            </div>
                        } */}


                        <div className="panel-heading article text-center mar-top" style={{height: "auto"}}>
                            {
                                this.state.report_type_selected !== "osha_301" &&
                                <div>
                                    <button className="btn btn-discard mar-top "
                                            style={{marginBottom: '8px', marginRight: "8px"}}
                                            onClick={this.cancelReport}>
                                        Back to Reports
                                    </button>
                                    <button className="btn btn-primary mar-top" style={{marginBottom: '8px'}}
                                            onClick={this.generateReport} disabled={this.state.buttonDisabled}>
                                        {
                                            this.state.generatingReport ? <ButtonLoading/> : "Generate Report"
                                        }
                                    </button>
                                </div>

                            }

                            {
                                this.state.report_error && !this.state.report_error_msg &&
                                <small className="error" style={{display: 'block'}}>Could not generate report.</small>
                            }
                        </div>
                    </div>

                }


            </div>
        );
    }
}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {}),
    }
}

export default withRouter(connect(mapStateToProps)(GenerateReport));
  