import { Component, createRef } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import {
    export_feed_analytics,
    getBulkSubmissions,
    get_bulletins,
    notifyError
} from '../../api/zero-api.js';

import NotificationAlert from '../../other/NotificationAlert';

import {
    floatingButtonMargin,
    isAdmin,
    isContributor,
    isMobileApp,
    isRestrictedTeamUser,
    isRoleOrHigher,
    isViewer,
    mainContentContainerClass, profileImageContain,
    safe_get,
    scrollToTop
} from '../../other/Helper.js';

import {
    DownloadOutlined,
    ExpandAltOutlined,
    LayoutOutlined,
    PlusCircleOutlined,
    ReloadOutlined,
    TableOutlined
} from '@ant-design/icons';
import { Pagination, Tabs } from 'antd';


import BulletinPost from './BulletinPost.js';
import DefaultPost from './DefaultPost.js';

import GenerateReport from '../AdminPages/Reports/GenerateReport';

import BulletinSearchFilter from './BulletinSearchFilter';

import DraftsModal from './DraftsModal';

import BulletinPostsAnalytics from '../Analytics/BulletinPostsAnalytics.js';
import CategoryAnalytics from '../Analytics/CategoryAnalytics.js';
import RiskLevelsChart from '../Analytics/Charts/RiskLevelsChart.js';
import TagsChart from '../Analytics/Charts/TagsChart.js';
import MembersAnalytics from '../Analytics/MembersAnalytics.js';
import MembersAnalyticsTable from '../Analytics/MembersAnalyticsTable.js';
import PopularKeywords from '../Analytics/PopularKeywords';
import SubStatusAnalytics from '../Analytics/SubStatusAnalytics.js';
import TagsAnalytics from '../Analytics/TagsAnalytics';
import TopPosters from '../Analytics/TopPosters.js';

import ButtonLoading from '../Shared/ButtonLoading.js';

import Skeleton from 'react-loading-skeleton';

import * as feedHelperActions from '../../store/actions/FeedHelperActions';

import PostSettings from 'components/AdminPages/AccountSettings/PostSettings.js';
import FormOptionsModal from 'components/AdminPages/ManageForms/FormOptionsModal.js';
import SyncInProgressSpinner from 'components/Offline/SyncInProgressSpinner.js';
import Button from 'components/Shared/Button.js';
import { BorderedPanel } from 'components/Shared/PageParts.js';
import { ZeroContext } from 'components/ZeroContext.js';
import { keyBy } from 'lodash-es';
import { FormType, UserRole } from 'other/Constants.js';
import { NO_EMBEDDED_FORM } from 'other/forms.js';
import '../../assets/css/Library.css';
import BulletinTemplateTab from './BulletinTemplateTab.js';
import FeedDataGrid from './FeedDataGrid/FeedDataGrid.jsx';
import NewPostModal from './NewPostModal.js';
import { generatePostQueryParams } from 'other/posts.js';
import ResetFeedGridLink from './FeedDataGrid/ResetFeedGridLink.jsx';
import { FeedDataGridContextProvider } from './FeedDataGrid/FeedDataGrid.context.jsx';
import FeedDataGridExportButton from './FeedDataGrid/FeedDataGridExportButton.jsx';
import { EventNames } from './FeedDataGrid/FeedDataGrid.utils.js';

class BulletinFeed extends Component {
    _isMounted = false;
    static contextType = ZeroContext;

    constructor(props) {
        super(props);

        /** @type {ZeroContext} */
        this.context;
        this.defaultSortField = '-revised_at'

        this.state = {
            showModal: false,
            bulletins: [],
            team: {},
            prefilters: [],
            membersFilter: [],
            loading: true,
            bulletin_loading: true,
            emptyMessage: "",
            showDefaultPost: false,
            showAddMembers: false,
            showEmailInvite: true,
            showLinkInvite: false,
            team_loading: false,
            sortField: this.defaultSortField,
            locationId: null,
            canPostToCurrentTeam: this.canPostToCurrentTeam(),
            showFormOptionsModal: false,
        };

        this.currentPage = 1;
        this.tableRef = createRef();

        this.updatePeopleFilter = this.updatePeopleFilter.bind(this);
        this.updateBulletin = this.updateBulletin.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.loadMorePosts = this.loadMorePosts.bind(this);
        this.goTo = this.goTo.bind(this);
    }


    componentDidMount() {
        this._isMounted = true;
        scrollToTop("page-head");

        let tab = safe_get(this.props, "match.params.tab", "posts");
        if (tab !== this.props.feed_tab) {
            this.props.dispatch(feedHelperActions.update_feed_tab(this.props.match.params.tab));
        }

        if (tab === 'analytics') {
            const query = window.location.search;
            if (query.includes('export=true')) {
                const [filterQuery, dateQuery] = this.splitExportQuery(query);
                this.props.dispatch(feedHelperActions.update_feed_query(filterQuery))
                this.props.dispatch(feedHelperActions.update_date_query(dateQuery));
            }
        }

        this.updateBulletin();
        this.getDrafts();

        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener("resize", this.updateDimensions);
        this.props.dispatch(feedHelperActions.update_feed_position(window.pageYOffset));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.feed_view !== this.props.feed_view && this.state.sortField !== this.defaultSortField) {
            this.updateBulletin();
        }

        if (prevProps.current_team !== this.props.current_team) {
            this.setState({canPostToCurrentTeam: this.canPostToCurrentTeam()});
        }

        if (prevProps.match.params.tab !== this.props.match.params.tab) {
            this.props.dispatch(feedHelperActions.update_feed_tab(this.props.match.params.tab));
        }
    }

    canPostToCurrentTeam() {
        const {current_team: currentTeam} = this.props;

        if (!currentTeam) return false;

        if (currentTeam.uuid === 'my_teams' || currentTeam.uuid === 'organization_teams') {
            const validTeams = this.props.teams.filter(team => !isRestrictedTeamUser(team, this.props.user))
            return validTeams.length > 0;
        } else {
            const team = this.props.teams.find(team => team.uuid === currentTeam.uuid);

            if (!team) return false;

            return !isRestrictedTeamUser(team, this.props.user);
        }
    }

    updateDimensions() {
        if (this._isMounted) {
            if (window.innerWidth < 768) {
                this.setState({mobileDevice: true});
            } else {
                this.setState({mobileDevice: false});
            }
        }
    }

    cacheEmbeddedSubmissions = async (embeddedForms) => {
        if (embeddedForms.length > 0) {
            try {
                const efKeyedBySubId = keyBy(embeddedForms, 'submission_uuid');
                const submissionIds = Object.keys(efKeyedBySubId);
                const res = await getBulkSubmissions(submissionIds);
                const submissions = await res.json();
                submissions.forEach(s => {
                    s.form.fields = efKeyedBySubId[s.submission_uuid].form_fields;
                })
                const entries = submissions.map(s => [s.submission_uuid, s]);
                this.context.kv.setMany(entries);
            } catch (err) {
                console.error("Could not fetch post submissions:", err);
            }
        }
    }

    async updateBulletin(showLoading = true) {
        if (this._isMounted) {
            if (this.props.feed_view === 'table') {
                this.context.events.emit(EventNames.REFRESH_DATA);
                return;
            }

            if (showLoading) {
                this.setState({bulletin_loading: true});
            }

            const newState = {}
            this.currentPage = 1;

            const queryParams = generatePostQueryParams(safe_get(this.props, "feed_query", ""), safe_get(this.props, "date_query", ""));

            if (this.props.feed_view === "table") {
                queryParams.set('page', String(this.currentPage));

                if (this.state.sortField) {
                    queryParams.set('sort_by', this.state.sortField);
                }
            }

            try {
                const res =  await get_bulletins(`?${queryParams}`)
                const data = await res.json();
                /** @type {any[]} */
                const bulletins = safe_get(data, 'posts', []);
                newState.max_posts = safe_get(data, "max_results", 30);
                newState.bulletins = bulletins;
                newState.posts_total = safe_get(data, "total_posts", 0);
                newState.bulletin_loading = false;

                const embeddedForms = bulletins.flatMap(post => post.embedded_forms);
                await this.cacheEmbeddedSubmissions(embeddedForms);

                this.setState(newState, () => {
                    profileImageContain("img.img-sm.img-circle.bulletin", "26px");
                });
            } catch (err) {
                this.setState({bulletin_loading: false, bulletins: []});
                notifyError(err);
            };
        }

    }

    loadMorePosts(e) {
        var self = this;
        if (!this.state.load_more_posts) {
            this.setState({load_more_posts: true}, () => {
                this.currentPage += 1;
                var query = `${this.props.feed_query}${this.props.date_query}&page=${this.currentPage}&per_page=30&include_embedded_forms=true`
                get_bulletins(query).then(function (success) {
                    success.json().then(success => {
                        const bulletins = safe_get(success, "posts", []);

                        if (self._isMounted) {
                            self.setState({
                                bulletins: self.state.bulletins.concat(bulletins),
                                load_more_posts: false
                            });
                        }
                    });
                }, function (error) {
                    self.setState({
                        load_more_posts: false
                    });
                });
            });
        }
    }

    getDrafts = async () => {
        try {
            const drafts = await this.context.services.post.getLocalPosts({onlyDrafts: true, includeDeleted: false});
            const alteredDrafts = drafts.filter(draft => draft.post_uuid !== this.state.post_uuid);
            this.setState({drafts: alteredDrafts});
        } catch (err) {
            console.error(err);
            NotificationAlert("error", "", "Unable to load your drafts.");
        }
    }

    updatePeopleFilter(filter) {
        this.setState({membersFilter: filter});
    }

    updateFeedTab(tab) {
        if (tab === this.props.feed_tab) return;

        const tabInPath = this.props.match.params.tab;
        let newPath = '';

        if (tabInPath === undefined) {
            newPath = `${this.props.location.pathname}/tab/${tab}`;
        } else {
            const pathSegments = this.props.location.pathname.split('/').slice(0, -1);
            pathSegments.push(tab);
            newPath = pathSegments.join('/');
        }

        this.props.history.push(newPath + this.props.location.search);
    }

    toggleFeedView = () => {
        let view = this.props.feed_view === "posts" ? "table" : "posts";
        this.currentPage = 1;
        this.props.dispatch(feedHelperActions.toggle_feed_view(view));
        if (view === "posts") {
            setTimeout(() => {
                this.updateBulletin(false);
            }, 50);
        }
    }

    goTo(location) {
        this.props.history.push(location);
    }

    exportAnalytics = () => {
        this.setState({isExportingAnalytics: true});

        const query = this.props.feed_query + this.props.date_query
        const body = JSON.stringify({query});

        if (window.location.hostname.includes('localhost')) {
            console.log('export analytics body', body);
            setTimeout(() => {
                this.setState({isExportingAnalytics: false});
            }, 2000)

            return;
        }

        export_feed_analytics(body).then(success => {
            success.json().then(success => {
                if (success.data.public_url !== null) {
                    window.location = success.data.public_url;
                } else {
                    // only happens when running against a local running back-end
                    console.log('export query', success.data.query);
                }
                this.setState({isExportingAnalytics: false});
            });
        }, error => {
            NotificationAlert('error', '', 'Could not export feed analytics.');
            this.setState({isExportingAnalytics: false});
        });
    }

    splitExportQuery(query) {
        let filters = query.substring(1).split("&");
        filters = filters.filter(function (n) {
            return n !== "" && n
        });

        const dateFilters = ['period', 'year', 'quarter', 'month', 'from_date', 'to_date']
        let dateQuery = '&';
        let filterQuery = '?';

        for (const i in filters) {
            const filter = filters[i].split("=");
            const filter_type = filter[0];
            const filter_value = filter[1] || "";

            if (dateFilters.includes(filter_type)) {
                dateQuery += `${filter_type}=${filter_value}&`;
            } else {
                filterQuery += `${filter_type}=${filter_value}&`;
            }
        }

        // remove trailing '&'
        dateQuery = dateQuery.substring(0, dateQuery.length - 1);
        filterQuery = filterQuery.substring(0, filterQuery.length - 1);

        return [filterQuery, dateQuery];
    }

    goToNewPost = () => {
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/feed/new_post");
    }

    goToPost = (post) => {
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/my_teams/feed/new_post/" + post.post_uuid);
    }

    sortPostsBy = (sortField) => {
        this.setState({sortField}, this.updateBulletin);
    }

    fullScreen = () => {
        const elem = document.getElementById("feedTable");
        if (elem.requestFullscreen) {
            elem.requestFullscreen({navigationUI: "show"});
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen({navigationUI: "show"});
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen({navigationUI: "show"});
        }
    }

    renderTabActions = () => {
        return (
            <>
                { this.props.feed_tab === "posts" &&
                    <div className="mar-rgt-10" style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                        {this.props.feed_view === 'table' &&
                            <ResetFeedGridLink />
                        }

                        {this.props.feed_view === "table" &&
                            <ExpandAltOutlined
                                className="hover-cursor zero-blue"
                                style={{fontSize: "20px"}}
                                onClick={this.fullScreen}
                            />}
                        <FeedDataGridExportButton />
                        <div
                            className="hover-cursor zero-blue"
                            style={{lineHeight: 1}}
                            onClick={this.toggleFeedView}
                        >
                            {this.props.feed_view === "posts" ?
                                <TableOutlined style={{fontSize: '20px'}}/> :
                                <LayoutOutlined style={{fontSize: '18px'}}/>}
                        </div>
                        <ReloadOutlined className="hover-cursor zero-blue"
                                        style={{fontSize: '18px'}} onClick={this.updateBulletin}/>
                    </div>
                }
                { this.props.feed_tab === "analytics" && !isContributor(this.props.user) &&
                    <div className="hidden-xs" style={{display: 'flex', alignItems: 'center'}}>
                        <Button
                            type="primary"
                            className="dont-print"
                            onClick={this.exportAnalytics}
                            loading={this.state.isExportingAnalytics}
                            disabled={this.state.isExportingAnalytics}
                        >Export to PDF</Button>
                    </div>
                }
                { this.props.feed_tab === "settings" &&
                    <Button
                        type="primary"
                        onClick={() => { this.setState({showPostPreview: true}); }}
                    >Preview</Button>
                }
                { this.props.feed_tab === "templates" &&
                    <Button type="primary" onClick={() => this.setState({showFormOptionsModal: true})}><PlusCircleOutlined/> New Template</Button>
                }
            </>
        )
    }

    isMobileView = () => {
        return window.innerWidth < 650;
    }

    render() {
        // var canEditTeam = (isAdmin(this.props.user) || isTeamLead(this.props.user)) && this.props.current_team_uuid !== "my_teams";
        var isMyPostsFeed = this.props.feed_query.includes("my_stuff") || this.props.location.pathname.includes("my_posts");
        var loading = this.state.team_loading || this.state.bulletin_loading
        const isTableView = this.props.feed_view === "table";

        return (
            <FeedDataGridContextProvider>
            <div className={mainContentContainerClass() + ' print-7in bulletin-feed'}>
                {
                    this.state.showDraftsModal &&
                    <DraftsModal
                        onCancel={() => {
                            this.setState({showDraftsModal: false});
                            this.getDrafts();
                        }}
                        onEditDraft={this.goToPost}
                    />
                }
                { this.state.showFormOptionsModal &&
                    <FormOptionsModal
                        show={this.state.showFormOptionsModal}
                        cancel={() => this.setState({showFormOptionsModal: false})}
                        complete={() => this.setState({showFormOptionsModal: false})}
                        form_to_edit={null}
                        team_access={false}
                        forceFormType={FormType.POST}
                        refresh={() => {}}
                    />
                }

                <div id="page-head" className="no-padding-mobile no-padding-print">
                    <div className="row">
                        <div className={"col-lg-12 "}>
                            <div id="page-title" style={{padding: '0px'}}>

                                <div className="panel thin-border" style={{margin: "0 auto"}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                Posts
                                            </h3>
                                            {/* {
                              !canEditTeam && isRestrictedTeamUser(this.props.current_team, this.props.user) &&
                              <span className="label label-warning" style={{ padding: ".3em .6em", verticalAlign: "super", marginLeft: "8px", backgroundColor: "#d68e7e" }}>View Only</span>
                            } */}

                                            {
                                                !isMobileApp() && !isViewer(this.props.user) &&
                                                <Button
                                                    type="primary"
                                                    className="pull-right dont-print"
                                                    onClick={this.goToNewPost}
                                                    disabled={!this.state.canPostToCurrentTeam}
                                                >
                                                    Post
                                                </Button>
                                            }
                                            {
                                                safe_get(this.state, "drafts", []).length > 0 &&
                                                <span className="blue-link mar-top-5 mar-rgt-10 pull-right dont-print"
                                                      onClick={() => {
                                                          this.setState({showDraftsModal: true});
                                                      }}>
                                My Drafts ({safe_get(this.state, "drafts", []).length})
                              </span>
                                            }

                                        </div>

                                        <p style={{
                                            marginBottom: "0px",
                                            marginLeft: "2px",
                                            color: "#505050",
                                            paddingTop: "3px"
                                        }}>
                                            <span>Issue identification, tracking, and resolution.</span>
                                        </p>

                                        {
                                            ["posts", "analytics", "members"].includes(this.props.feed_tab) &&
                                            <BulletinSearchFilter
                                                loading={loading}
                                                updateFeed={this.updateBulletin}
                                                total_posts={this.state.posts_total}
                                                isMobileDevice={this.state.mobileDevice}
                                                onChangePeopleFilter={(value) => {
                                                    this.setState({membersFilter: value})
                                                }}
                                                onLocationSelected={locationId => this.setState({locationId})}
                                            />
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div id="page-content" className="no-padding-mobile">
                    {
                        <SyncInProgressSpinner
                            forPosts
                            style={{padding: "0 0 1rem 1rem"}}
                            postSyncCallback={() => {
                                this.updateBulletin();
                                this.getDrafts();
                            }}
                        />
                    }
                    <BorderedPanel style={{padding: '0 1.5rem 1.5rem',}}>
                        <Tabs
                            activeKey={this.props.feed_tab}
                            destroyInactiveTabPane={true}
                            onChange={activeTab => {
                                if (this.props.feed_tab === 'settings') {
                                    // sync post options when leaving settings tab
                                    this.context.services.post.localDbService.syncPostOptions();
                                }

                                this.updateFeedTab(activeTab);
                                if (activeTab !== 'members') {
                                    this.setState({membersFilter: undefined});
                                }
                                
                                if (activeTab === 'posts') {
                                    this.updateBulletin();
                                }
                            
                            }}
                            tabBarExtraContent={!this.isMobileView() && this.renderTabActions()}
                            items={[
                                {
                                    key: 'posts',
                                    label: 'Posts',
                                    children: <>
                                        <div
                                            className="tab-content"
                                            id="feedTable"
                                            style={{
                                                background: "white",
                                                paddingBottom: "35px",
                                                display: isTableView ? 'block' : 'none'
                                            }}
                                        >
                                            <FeedDataGrid />
                                        </div>
                                        { this.props.feed_view === "posts" &&
                                            <div className="blog blog-list-full">
                                                {
                                                    this.state.bulletin_loading &&
                                                    <div>
                                                        {[...Array(3)].map((e, index) =>
                                                            <div key={index} className="panel article"
                                                                style={{borderTopLeftRadius: index === 0 ? "0px" : "3px", padding: '0 0 2rem', borderBottom: '1px solid var(--zero-border-color)'}}>
                                                                <div className="panel-body pad-no bulletin-post">
                                                                    <div style={{minHeight: "120px"}}>
                                                                        <h2 className="zero-blue" style={{
                                                                            lineHeight: '1.4',
                                                                            marginBottom: '0px',
                                                                            marginTop: "0px"
                                                                        }}>
                                                                            <Skeleton width={200}/>
                                                                        </h2>
                                                                        <p>
                                                                            <Skeleton height={13} width={"99%"}/>
                                                                            <Skeleton height={13} width={"93%"}/>
                                                                            <Skeleton height={13} width={"96%"}/>
                                                                        </p>
                                                                        <ul className="thumb">
                                                                            <li style={{marginRight: "5px"}}>
                                                                                <Skeleton height={100} width={100}/>
                                                                            </li>
                                                                            <li style={{marginRight: "5px"}}>
                                                                                <Skeleton height={100} width={100}/>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                { !this.state.bulletin_loading &&
                                                    <div>
                                                        { this.state.bulletins.map((post, index) => (
                                                            <BulletinPost
                                                                key={index}
                                                                index={index}
                                                                post={post}
                                                                mobileDevice={this.state.mobileDevice}
                                                                goTo={this.goTo}
                                                                refreshFeed={this.updateBulletin}
                                                                isMyPostsFeed={isMyPostsFeed}
                                                            />
                                                        )) }
                                                    </div>
                                                }
                                                { !this.state.bulletin_loading && !this.state.load_more_posts &&
                                                    (this.props.feed_query === "?team_uuid=my_teams" || this.props.feed_query === "?team_uuid=" + this.props.current_team.uuid || this.props.feed_query === "?team_uuid=my_teams&my_posts_only=1") && this.props.date_query === "&period=all_time" &&
                                                    (this.state.posts_total <= (this.state.max_posts * this.currentPage)) &&
                                                        <DefaultPost showAddMembers={() => {
                                                            this.setState({showAddMembers: true})
                                                        }}/>
                                                }
                                                { (!this.state.bulletin_loading && this.state.bulletins.length === 0) &&
                                                    (!this.props.date_query.includes("period=all_time") || this.props.feed_query !== `?team_uuid=${this.props.current_team}`) &&
                                                        <div className="panel article thin-border animated fadeIn">
                                                            <div className="panel-body pad-no bulletin-post">
                                                                <h4 className="zero-blue text-center">No posts.</h4>
                                                            </div>
                                                        </div>
                                                }
                                                { (this.state.posts_total > (this.state.max_posts * this.currentPage)) && !this.state.bulletin_loading && this.state.bulletins.length !== 0 &&
                                                    <button className="btn btn-primary" style={{display: "block", margin: "1.5rem auto 0"}}
                                                            onClick={this.loadMorePosts}>
                                                        { this.state.load_more_posts ? <ButtonLoading/> : "Load More" }
                                                    </button>
                                                }
                                            </div>
                                        }
                                    </>
                                },
                                {
                                    key: 'analytics',
                                    label: 'Analytics',
                                    children: <>
                                        <div>
                                            <div style={{marginBottom: "10px"}}>
                                                <BulletinPostsAnalytics updateFeed={this.updateBulletin} isMyPostsFeed={isMyPostsFeed}/>
                                            </div>
                                            <div style={{paddingLeft: "5px", paddingRight: "5px"}}>
                                                <CategoryAnalytics updateFeed={this.updateBulletin} isMyPostsFeed={isMyPostsFeed}/>
                                                { !isMyPostsFeed &&
                                                    <TopPosters/>
                                                }
                                                <TagsChart isMyPostsFeed={isMyPostsFeed} />
                                                {this.props.feed_tags.length > 0 &&
                                                    <TagsAnalytics updateFeed={this.updateBulletin} isMyPostsFeed={isMyPostsFeed}/>
                                                }
                                                {this.props.feed_statuses.length > 0 &&
                                                    <SubStatusAnalytics updateFeed={this.updateBulletin} group={1} isMyPostsFeed={isMyPostsFeed} />
                                                }
                                                {this.props.feed_statuses2.length > 0 &&
                                                    <SubStatusAnalytics updateFeed={this.updateBulletin} group={2} isMyPostsFeed={isMyPostsFeed} />
                                                }
                                                {this.props.risk_enabled &&
                                                    <RiskLevelsChart updateFeed={this.updateBulletin} isMyPostsFeed={isMyPostsFeed}/>
                                                }
                                                <PopularKeywords updateFeed={this.updateBulletin} isMyPostsFeed={isMyPostsFeed}/>
                                            </div>
                                        </div>
                                    </>
                                },
                                isRoleOrHigher(this.props.user, UserRole.MEMBER) && {
                                    key: 'members',
                                    label: 'Members',
                                    children: <>
                                        <div>
                                            <div style={{marginBottom: "10px"}}>
                                                <MembersAnalytics
                                                    loading={this.state.team_loading}
                                                    membersFilter={safe_get(this.state, "membersFilter", [])}
                                                    locationId={this.state.locationId}
                                                    updatePeopleFilter={this.updatePeopleFilter}
                                                    toggleChart={() => {
                                                        this.setState({showUsersChart: !this.state.showUsersChart})
                                                    }}
                                                />
                                            </div>
                                            <MembersAnalyticsTable
                                                loading={this.state.team_loading}
                                                team={this.props.current_team}
                                                org_uuid={this.props.org_uuid}
                                                membersFilter={this.state.membersFilter}
                                                locationId={this.state.locationId}
                                                removeFilters={(value) => {
                                                    this.setState({removeFilters: value});
                                                }}
                                            />
                                        </div>
                                    </>
                                },
                                isRoleOrHigher(this.props.user, UserRole.CONTRIBUTOR) && {
                                    key: 'reports',
                                    label: 'Reports',
                                    children: <GenerateReport isIncidentsReport={false} isFormsReport={false} isFeedReport={true}/>
                                },
                                isAdmin(this.props.user) && {
                                    key: 'settings',
                                    label: 'Settings',
                                    children: <>
                                        { this.state.showPostPreview &&
                                            <NewPostModal
                                                show={this.state.showPostPreview}
                                                cancel={() => {
                                                    this.setState({showPostPreview: false});
                                                }}
                                                isPreview={true}
                                            />
                                        }
                                        <PostSettings/>
                                    </>
                                },
                                isAdmin(this.props.user) && {
                                    key: 'templates',
                                    label: 'Templates',
                                    children: <BulletinTemplateTab />
                                },
                            ].filter(tab => tab !== false)}
                            renderTabBar={(props, DefaultTabBar) => (
                                <>
                                    <DefaultTabBar {...props} />
                                    { this.isMobileView() && 
                                        <div style={{paddingBottom: '1rem', display: 'flex', justifyContent: 'end'}}>
                                            {this.renderTabActions()}
                                        </div>
                                    }
                                </>
                            )}
                        />
                    </BorderedPanel>

                    {
                        isMobileApp() && !isViewer(this.props.user) && this.state.canPostToCurrentTeam &&
                        <Link
                            to={"/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/feed/new_post"}>
                            <div id="floating-button" style={{bottom: floatingButtonMargin()}}>
                                <p className="plus-sign">+</p>
                            </div>
                        </Link>
                    }

                </div>

            </div>
            </FeedDataGridContextProvider>
        );

    }
}

const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", {}),
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        feed_categories: safe_get(store, "feed_helper.categories", []),
        feed_tags: safe_get(store, "feed_helper.tags", []),
        feed_statuses: safe_get(store, "feed_helper.statuses", []),
        feed_statuses2: safe_get(store, "feed_helper.statuses2", []),
        feed_query: safe_get(store, "feed_helper.query", ""),
        date_query: safe_get(store, "feed_helper.date_query", ""),
        feed_position: safe_get(store, "feed_helper.position", 0),
        feed_tab: safe_get(store, "feed_helper.tab", "posts"),
        feed_view: safe_get(store, "feed_helper.feed_view", "posts"),
        risk_enabled: safe_get(store, "org_helper.organization.risk_level_enabled", true)
    }
}

export default connect(mapStateToProps)(BulletinFeed);