import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Link } from 'react-router-dom';

import {
    dateFormatterWithTime,
    formatName,
    getErrorMessageFromResponse,
    hasTeamAccess, isAdmin,
    isPublicUser,
    mainContentContainerClass,
    scrollToTop,
    updateFormAssignments,
} from '../../other/Helper.js';
import safe_get from '../../other/SafeGet.js';

import {
    delete_submission,
    get_form_drafts,
    submit_incident_form
} from '../../api/zero-api.js';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Alert, Modal } from 'antd';
import moment from 'moment';

import Skeleton from 'react-loading-skeleton';

import NotificationAlert from '../../other/NotificationAlert.js';
import ButtonLoading from '../Shared/ButtonLoading.js';
import TeamListPopover from '../Shared/TeamListPopover.js';

import LoadingIndicator from '../Shared/LoadingIndicator.js';
import FormFieldsRenderer from './FormFieldsRenderer.js';

import ConfirmModal from '../Shared/ConfirmModal.js';
import SelectTeamModal from '../Teams/SelectTeamModal.js';
import AssignmentSelectModal from './AssignmentSelectModal.js';


import { ZeroContext } from 'components/ZeroContext.js';
import { DebugLogger } from 'other/DebugLogger';
import { generateFormPages, submissionHasBlankRequiredFields } from 'other/forms.js';
import '../../assets/css/form-builder.css';
import FormConfirmation from './FormConfirmation.js';
import FormSubmissionControls from './FormSubmissionControls.js';
import { PageSelectDropdown } from './PageSelectDropdown.js';
import { getPublicUserDisplayName } from './SubmissionDataGrid/SubmissionDataGrid.utils.js';

let saveTimeout = null;
let resetSubmissionLockTimeout = null;

class FormSubmitComponent extends Component {
    _isMounted = false;

    static contextType = ZeroContext;

    constructor(props, context) {
        super(props);

        /** @type {ZeroContext} */
        this.context;

        const urlParams = new URLSearchParams(this.props.location.search);

        this.state = {
            refreshKey: 0,
            nextPath: null,
            loadingAssignments: true,
            loading: true,
            submissionConfirmation: urlParams.get('confirmation') === 'true',
            allowNav: false,
            showSavedText: true,
            assignment: {},
            submittingAssignment: false,
            submitting: false,
            savingDraft: false,
            pendingDraftSave: false,
            form_uuid: "",
            edit_mode: false,
            selected_team_uuid: this.props.match.params.team_uuid,
            form_title: "",
            tag_enabled: false,
            previewAttachments: [],
            form_answers_data: [],
            my_assignments: [],
            score: 0,
            numerator: 0,
            denominator: 0,
            pages: [],
            page: 0,
            submissionLocked: false,
            showQR: false,
        };

        this.preventFormAnswerDataOverwrite = false;
        this.encounteredConflictOnSave = false;
        this.confirmSaveDraftClicked = false;

        this.initFormSubmit = this.initFormSubmit.bind(this);
        this.getTeamName = this.getTeamName.bind(this);
        this.saveDraft = this.saveDraft.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.discard = this.discard.bind(this);
    }

    getDefaultNextPath({useSelectedTeamId} = {}) {
        const formType = this.props.form_data?.form_type === 3 ? 'courses' : 'forms';
        const teamId = useSelectedTeamId ? this.state.selected_team_uuid || 'my_teams' : 'my_teams';
        
        // if we're offline and not on a courses page, redirect to offline dashboard
        // else if we're online or on a courses page, redirect to regular forms list
        return (this.context.offlineAllowed && this.context.isOffline) && formType !== 'courses'
            ? `/${this.props.org_uuid}/offline/dashboard?tab=submission_drafts`
            : `/${this.props.org_uuid}/home/team/${teamId}/${formType}`;
    }

    componentWillUnmount() {
        this.context.caches.submissionDrafts.cache.target.removeEventListener('draftChange', this.draftChangeHandler);
        this._isMounted = false;
        window.removeEventListener('beforeprint', this.handleBeforePrint);
        window.removeEventListener('afterprint', this.handleAfterPrint);
        // this.setState({ allowNav: true });
        if (this.state.unblockHistory) {
            this.state.unblockHistory();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.initFormSubmit()
            if (this.state.nextPath === this.props.location.pathname) {
                this.setState({nextPath: null});
            }
        }

        if (this.state.submissionConfirmation === true && this.state.submissionConfirmation !== prevState.submissionConfirmation) {
            // just switched to showing submission confirmation, add url params
            const urlParams = new URLSearchParams(this.props.location.search);
            urlParams.set('confirmation', 'true');
            this.props.history.replace(`${this.props.location.pathname}?${urlParams}`);
        }
    }

    componentDidMount() {
        this._isMounted = true;
        scrollToTop("page-head");

        window.addEventListener('beforeprint', this.handleBeforePrint);
        window.addEventListener('afterprint', this.handleAfterPrint);

        if (this.props.form_data) {
            // cache form data since we have it
            this.context.services.forms.syncForm(this.props.form_data.form_uuid, this.props.form_data).then(() => {
                this.getAssignments();
            });
        } else {
            this.getAssignments();
        }
    }

    onNavBlock = (tx) => {
        const debugLogger = DebugLogger.getLogger("FormSubmitComponent.onNavBlock");
        debugLogger.log('tx:', tx);

        this.setState({nextPath: safe_get(tx, "pathname", this.getDefaultNextPath())});

        if (document.body.classList.contains('offline-mode')) {
            debugLogger.log('offline-mode on body, return true');
            return true;
        }

        if (!this.state.is_draft) {
            debugLogger.log('not a draft, return true');
            return true
        } else if (this.state.submissionConfirmation) {
            debugLogger.log('showing confirmation, return true');
            return true;
        } else if (!this.state.allowNav) {
            debugLogger.log('nav blocked, show modal');
            this.setState({
                showSaveDraftModal: true,
                // nextPath: `/${this.props.org_uuid}/home/team/my_teams/${this.state.form_type === 3 ? "courses" : "forms"}`
            });
            return false
        } else {
            debugLogger.log('fallthrough, allowNav:', this.state.allowNav);
            return this.state.allowNav
        }
    }

    handleTeamSelectConfirm(team_uuid) {
        this.setState({selected_team_uuid: team_uuid, showSelectTeamModal: false}, () => {
            this.initFormSubmit();
        });
    }

    getSingleAssignment = async (assignmentId) => {
        const debugLogger = DebugLogger.getLogger("FormSubmitComponent.getSingleAssignment");
        try {
            debugLogger.log("assignmentId", assignmentId);
            let assignment = await this.context.services.forms.getAssignment(assignmentId);
            await (new Promise((resolve) => {
                this.setState({my_assignments: [assignment]}, resolve);
            }));

            debugLogger.log("assignment", assignment);
            const assignment_uuid = safe_get(this.props, "match.params.assignment_uuid", "");

            debugLogger.log("params.assignment_uuid", assignment_uuid);

            let allowedToAccessSubmission = false;
            if (assignment) {
                if (this.context.isOffline) {
                    allowedToAccessSubmission = true;
                } else if (assignment.assigned_to.uuid === this.props.user.uuid) {
                    allowedToAccessSubmission = true;
                } else if (assignment.completed) {
                    allowedToAccessSubmission = true;
                }
            }

            if (allowedToAccessSubmission) {
                if (assignment_uuid === assignmentId && safe_get(assignment, "completed", false)) {
                    if (!this.context.isOffline) {
                        let team = safe_get(assignment, "team_uuids", ["my_teams"])[0];
                        this.props.history.push(`/${this.props.org_uuid}/home/team/${team}/${assignment.form_type === 3 ? "courses" : "forms"}/submission_view/${assignment.submission_uuid}`)
                    }
                } else {
                    this.setState({
                        isOverDue: moment(safe_get(assignment, "due_date", null)).isBefore(moment().format("YYYY-MM-DD")),
                        submittingAssignment: true,
                        assignment: assignment,
                        selectedAssignment: assignmentId,
                        loadingAssignments: false
                    });
                }
            } else {
                this.setState({
                    permission_error: true,
                    loadingAssignments: false
                });
            }
        } catch (err) {
            console.error('Could not load single assignment:', err);
            this.setState({
                permission_error: true,
                loadingAssignments: false
            });
        }
    }

    getAllAssignments = async () => {
        try {
            let assignments = await this.context.services.forms.getAssignments("?only_open=true");
            this.setState({my_assignments: assignments, loadingAssignments: false});
        } catch (err) {
            console.error('Could not load assignments:', err);
            NotificationAlert('error', '', 'Could not load assignments.');
            this.setState({loadingAssignments: false});
        }
    }

    getAssignments = (teamId) => {
        let team_uuids = [...this.props.teams].map(team => team.uuid || team.team_uuid);
        let team_uuid = safe_get(this.props, "match.params.team_uuid", teamId);
        let assignment_uuid = safe_get(this.props, "match.params.assignment_uuid", "");
        const userIsAdmin = isAdmin(this.props.user);

        if (!team_uuid || team_uuid === "undefined" || !team_uuids.includes(team_uuid)) {
            this.setState({permission_error: userIsAdmin ? false : true, loadingAssignments: false});
            this.initFormSubmit();
        } else if (assignment_uuid) {
            this.getSingleAssignment(assignment_uuid).then(() => {
                this.initFormSubmit();
            });
        } else {
            this.getAllAssignments().then(() => {
                if (this.context.isOffline || hasTeamAccess(this.props.teams, this.props.match.params.team_uuid)) {
                    this.initFormSubmit();
                } else {
                    this.setState({showSelectTeamModal: true});
                }
            });
        }
    }

    draftChangeHandler = async ({detail}) => {
        const {currentId, newId} = detail;

        if (!this.state.submission) return;

        if (this.state.submission._id === currentId) {
            // our current draft was updated...
            if (currentId !== newId) {
                // draft ID has been changed, was either deleted, or remote draft was created
                if (newId === null) {
                    // draft was deleted
                    if (!this.state.submissionConfirmation) {
                        // not showing confirmation page, redirect
                        this.setState({allowNav: true}, () => {
                            this.context.caches.submissionDrafts.cache.onChangeCallbacks.push(() => {
                                this.props.history.push(this.getDefaultNextPath());
                            })
                        })
                    }
                } else {
                    // draft id changed
                    this.setState({allowNav: true}, () => {
                        this.context.caches.submissionDrafts.cache.onChangeCallbacks.push(() => {
                            this.props.history.push(`/${this.props.org_uuid}/home/team/${this.state.selected_team_uuid}/forms/${this.state.form_uuid}/submission/${newId}`);
                        });
                    });
                }
            } else {
                this.context.caches.submissionDrafts.cache.target.removeEventListener('draftChange', this.draftChangeHandler);
                this.getSubmission(currentId);
            }
        }
    }

    getSubmission = async (sub_uuid) => {
        try {
            const debugLogger = DebugLogger.getLogger("FormSubmitComponent.getSubmission");
            debugLogger.log('sub_uuid:', sub_uuid);

            let assignment_uuid = "";
            const submission = await this.context.services.forms.getSubmission(sub_uuid);
            debugLogger.log('submission:', submission);

            this.context.caches.submissionDrafts.cache.target.addEventListener('draftChange', this.draftChangeHandler);

            if (submission.assignment_uuid) {
                await this.getSingleAssignment(submission.assignment_uuid);
                assignment_uuid = submission.assignment_uuid;
            }

            debugLogger.log('assignment_uuid:', assignment_uuid);
            debugLogger.log('this.state.my_assignments:', this.state.my_assignments);

            if (!this.state.selected_team_uuid) {
                this.setState({
                    selected_team_uuid: submission?.team?.uuid,
                });
            }

            if (Object.keys(submission).length === 0) {
                throw new Error('Submission not found');
            }

            let form = safe_get(submission, "form", {});
            let form_type = safe_get(form, "form_type", undefined);
            if (!assignment_uuid) {
                assignment_uuid = safe_get(this.props, "match.params.assignment_uuid", "");
            }

            let isOverDue = this.checkIfAssignmentOverdue(safe_get(submission, "assignment_uuid", assignment_uuid));
            debugLogger.log('isOverDue:', isOverDue);
            

            if (this._isMounted) {
                let submissionConfirmation = false;
                if (submission.$submitted) {
                    // submission has been submitted, but not synced. Force confirmation page view.
                    submissionConfirmation = true;
                }

                if (!submission.draft) {
                    const url = new URL(window.location);

                    if (url.searchParams.get('confirmation') === true) {
                        submissionConfirmation = true;
                    } else {
                        url.searchParams.set('edit_submission', 'true');
                        window.history.replaceState(null, '', url.toString());
                    }
                }

                const submissionIsDraft = submission.draft && !submission.$submitted;

                this.setState({
                    is_draft: submissionIsDraft,
                    edit_mode: !submissionIsDraft,
                    form: form,
                    custom_confirmation_page: safe_get(form, "custom_confirmation_page", false),
                    confirmation_fields: safe_get(form, "confirmation_fields", []),
                    form_uuid: form.form_uuid,
                    submission: submission,
                    selectedAssignment: safe_get(submission, "assignment_uuid", safe_get(this.state, "selectedAssignment", undefined)),
                    edited_at: safe_get(submission, "edited_at", undefined),
                    form_data: safe_get(form, "fields", []),
                    form_fields: this.state.requiredFieldsError ? this.state.form_fields : safe_get(submission, "form.fields", []),
                    form_answers_data: this.preventFormAnswerDataOverwrite ? this.state.form_answers_data : safe_get(submission, "fields", []),
                    form_type: form_type,
                    form_title: safe_get(form, "name", ""),
                    tag_enabled: safe_get(form, "enabled", false),
                    isOverDue: isOverDue,
                    allowNav: !submissionIsDraft,
                    has_been_updated: submission.has_been_updated || submission.$updated,
                    unblockHistory: this.props.history.block(this.onNavBlock),
                    submissionConfirmation,
                }, () => {
                    if (this.state.requiredFieldsError) {
                        this.checkRequiredFields(false);
                    } else {
                        this.initFormFields(form.fields);
                    }
                });
            }
        } catch (err) {
            console.error(err);
            this.setState({submission: undefined, submissionNotFound: true,});
            this.initFormFields(safe_get(this.props, "form.fields", []));
        }
    }

    initFormSubmit() {
        let form = this.props.form_data
        let team_uuid = this.props.match.params.team_uuid
        let sub_uuid = this.props.match.params.submission_uuid;
        let assignment_uuid = this.props.match.params.assignment_uuid;

        if (this.state.isOverDue) {
            this.initFormFields(form.fields);
        } else if (!sub_uuid) {
            this.checkDrafts(async (draft) => {
                if (draft) {
                    this.props.history.replace(`/${this.props.org_uuid}/home/team/${team_uuid}/${form.form_type === 3 ? "courses" : "forms"}/${form.form_uuid}/submission/${draft.submission_uuid}`)
                } else if (this._isMounted) {
                    try {
                        const form_uuid = safe_get(this.props, "form_data.form_uuid", "");
                        const form_type = safe_get(this.props, "form_data.form_type", -1);
                        const submissionId = await this.context.services.forms.createDraft(form_uuid, team_uuid, form_type, assignment_uuid);
                        this.props.history.replace(`/${this.props.org_uuid}/home/team/${team_uuid}/${form.form_type === 3 ? "courses" : "forms"}/${form.form_uuid}/submission/${submissionId}`);
                    } catch (err) {
                        console.error(err);
                        this.setState({
                            loading: false,
                            permission_error: true,
                        }, () => {
                            this.initFormFields(form.fields);
                        });
                    }
                }
            });
        } else if (sub_uuid) {
            this.getSubmission(sub_uuid);
        }
    }

    checkDrafts = (callback) => {
        let self = this;
        let assignment_uuid = this.props.match.params.assignment_uuid;
        get_form_drafts(`?form_types=lms,regular`).then(success => {
            success.json().then(data => {
                const drafts = safe_get(data, 'drafts', []).sort(function (a, b) {
                    return a.edited_at < b.edited_at ? 1 : -1
                });

                let existing_draft = drafts.find((obj) => {
                    return obj.assignment_uuid === assignment_uuid
                })

                callback(existing_draft)
            });
        });
    }

    checkIfAssignmentOverdue = (assignment_uuid) => {
        let isOverDue = false;

        const debugLogger = DebugLogger.getLogger("FormSubmitComponent.checkIfAssignmentOverdue");
        debugLogger.log("assignment_uuid", assignment_uuid);

        debugLogger.log("this.state.my_assignments", this.state.my_assignments);
        let assignment = [...this.state.my_assignments].find((assignment) => {
            return assignment.assignment_uuid === assignment_uuid
        });
        debugLogger.log("assignment", assignment);

        if (assignment) {
            isOverDue = moment(assignment.due_date).isBefore(moment().format("YYYY-MM-DD"))
        }
        debugLogger.log("isOverDue", isOverDue);
        return isOverDue
    }

    handleBeforePrint = () => {
        const beforePrintState = {
            pages: this.state.pages,
            page: this.state.page,
        };

        const pages = generateFormPages(this.state.form.fields, {ignorePageBreaks: true});
        this.setState({
            pages,
            page: 0,
            beforePrintState
        });
    }
    
    handleAfterPrint = () => {
        const {pages, page} = this.state.beforePrintState;

        this.setState({
            pages,
            page,
            beforePrintState: null,
        });
    }

    initFormFields = (fields) => {
        const pages = generateFormPages(fields);

        this.setState({
            pages: pages,
            loading: false
        })
    }

    setSubmissionLock = (isLocked) => {
        // freeze submit button in anticipation of updateAnswers call
        const debugLogger = DebugLogger.getLogger("FormSubmitComponent.setSubmissionLock");
        debugLogger.log("called with isLocked = %o", isLocked);

        clearTimeout(resetSubmissionLockTimeout);
        resetSubmissionLockTimeout = null;
        if (isLocked) {
            // reset submission lock after 2 seconds if we never get the unlock call
            resetSubmissionLockTimeout = setTimeout(() => {
                debugLogger.log("manually resetting submission lock after timeout");
                this.setState({submissionLocked: false});
            }, 2000);
        }

        this.setState({
            submissionLocked: isLocked
        });
    }

    updateAnswers = (answers) => {
        const debugLogger = DebugLogger.getLogger("FormSubmitComponent.updateAnswers");
        debugLogger.log("called with answers = %o", answers);
        clearTimeout(saveTimeout);
        saveTimeout = null;
        this.setState({form_answers_data: answers, pendingDraftSave: true}, () => {
            this.saveDraft(!this.state.is_draft);
        });
    }

    syncDraft = (isCommitted) => {
        this.context.services.forms.syncLocalDraft(this.state.submission.submission_uuid)
        .catch(error => {
            this.handleSyncError(error, isCommitted);
        });
    }

    confirmSaveDraft = () => {
        this.confirmSaveDraftClicked = true;
        this.saveDraft(true, true);
    }

    confirmDiscardDraft = () => {
        this.setState({allowNav: true}, () => {
            this.discard();
        });
    }

    saveDraft(allowNav, syncNow = false) {
        const debugLogger = DebugLogger.getLogger("FormSubmitComponent.saveDraft");
        debugLogger.log("called with allowNav = %o, syncNow = %o", allowNav, syncNow);

        if (!this.state.is_draft) {
            debugLogger.log("not a draft");
            return;
        }
        this.preventFormAnswerDataOverwrite = true;
        debugLogger.log("preventFormAnswerDataOverwrite set to true");

        if (this.state.savingDraft) {
            debugLogger.log("currently saving draft - clearing saveTimeout and waiting 250ms");
            clearTimeout(saveTimeout);
            saveTimeout = setTimeout(() => this.saveDraft(this.confirmSaveDraftClicked ? true : allowNav), 250);
            this.setState({pendingDraftSave: true});
        } else {
            debugLogger.log("setting up saveTimeout");
            clearTimeout(saveTimeout);
            saveTimeout = setTimeout(async () => {
                saveTimeout = null;
                debugLogger.log("inside saveTimeout callback");
                this.setState({pendingDraftSave: false, savingDraft: true, showSavingText: true, showSavedText: false, allowNav}, async () => {
                    debugLogger.log("finished setting state, allowNav = %o", allowNav);

                    try {
                        let body = {
                            commit: false,
                            fields: [...this.state.form_answers_data]
                        }

                        if (this.state.selectedAssignment) {
                            body["assignment_uuid"] = this.state.selectedAssignment
                        }

                        let updatedSubmission = null;

                        try {
                            debugLogger.log("calling updateDraft from forms service");
                            debugLogger.log("body: %o", body);
                            updatedSubmission = await this.context.services.forms.updateDraft(
                                this.state.submission.submission_uuid, 
                                body, 
                                this.state.form_type
                            );
                        } catch (err) {
                            debugLogger.log("error with updateDraft: %o", err);
                            if (err.code === 'does-not-exist') {
                                debugLogger.log("got does-not-exist error");
                                // draft no longer exists, go back to offline dashboard
                                if (this.props.history.length > 0) {
                                    this.props.history.goBack();
                                } else {
                                    this.props.history.replace(this.getDefaultNextPath());
                                }
                            } else {
                                throw err;
                            }
                        }

                        if (syncNow) {
                            this.syncDraft(false);
                        }

                        debugLogger.log("updateDraft succeeded - resetting encounteredConflictOnSave to false");
                        this.encounteredConflictOnSave = false;

                        this.setState({
                            refreshKey: this.state.refreshKey + 1,
                            savingDraft: false,
                            showSavedText: true,
                            showSavingText: false,
                            has_been_updated: true,
                            edited_at: Math.round(Date.now() / 1000),
                            form_fields: this.state.requiredFieldsError ? this.state.form_fields : safe_get(updatedSubmission, "form.fields", this.state.form_fields),
                        }, () => {
                            debugLogger.log("savingDraft set to false");
                            if (this.state.requiredFieldsError) {
                                this.checkRequiredFields(false);
                            }
                        });

                        if (this.state.allowNav) {
                            debugLogger.log("draft save with allowNav, navigate to nextPath");
                            this.props.history.push(this.state.nextPath || this.getDefaultNextPath());
                            NotificationAlert('success', '', "Draft saved.");
                        }
                    } catch (error) {
                        console.error('Could not save draft:', error);
                        this.setState({savingDraft: false, showSavingText: false}, () => {
                            if (error.status === 409 && !this.encounteredConflictOnSave) {
                                // doc update conflict, try to save again
                                this.encounteredConflictOnSave = true;
                                console.log("Retrying save after conflict");
                                this.saveDraft(allowNav);
                            } else {
                                NotificationAlert('error', '', "Could not save draft.");
                            }

                        });
                    }
                });
            }, 500);
        }
    }

    getTeamName() {
        var self = this;
        var team = this.props.teams.find(function (obj) {
            return obj.uuid === self.state.selected_team_uuid
        });
        return safe_get(team, "name", "")
    }

    checkAssignments = () => {
        let form_uuid = this.state.form_uuid;
        let assignments = [...this.state.my_assignments].filter((assignment) => {
            let isOverDue = moment(assignment.due_date).isBefore(moment().format("YYYY-MM-DD"))
            let team_uuids = safe_get(assignment, "team_uuids", []);
            return assignment.form_uuid === form_uuid && team_uuids.includes(this.state.selected_team_uuid) && !assignment.completed && !isOverDue
        });

        if (assignments.length > 0 && this.state.is_draft && !this.state.selectedAssignment) {
            this.setState({
                showAssignmentModal: true,
                assignments: assignments
            });
        } else {
            this.submitForm();
        }
    }

    handleAssignmentConfirm = (selectedAssignment) => {
        let assignment = this.props.open_assignments.find((assignment) => {
            return assignment.form_uuid === this.props.form_data.form_uuid && assignment.assignment_uuid === selectedAssignment
        });

        this.setState({
            showAssignmentModal: false,
            submittingAssignment: true,
            assignment: assignment,
            selectedAssignment: selectedAssignment,
        }, () => {
            this.submitForm();
        });
    }

    checkRequiredFields = (checkAssignments = true) => {
        const [hasBlankRequiredFields, newFormFields] = submissionHasBlankRequiredFields(
            safe_get(this.state, "submission.form.fields", []),
            this.state.form_answers_data
        );
        if (hasBlankRequiredFields) {
            this.setState({
                form_fields: newFormFields,
                requiredFieldsError: true,
                submitting: false,
                allowNav: false,
                refreshKey: this.state.refreshKey + 1
            });
            return;
        }

        if (checkAssignments) {
            this.checkAssignments();
        }
    }

    handleSyncError = async (error, isCommitted = false) => {
        const defaultErrorMessage = isCommitted ? "Could not submit submission." : "Could not save draft.";
        const setErrorMessage = (message) => {
            if (this._isMounted) {
                this.setState({error_message: message});
                scrollToTop("page-head");
            }
        }
        try {
            const message = await getErrorMessageFromResponse(error, defaultErrorMessage);
            setErrorMessage(message);
        } catch (error) {
            setErrorMessage(defaultErrorMessage);
        } finally {
            NotificationAlert("error", "", defaultErrorMessage);
        }
    }

    submitForm() {
        var self = this;

        if (this.state.submission && !this.state.submitting && !this.state.savingDraft) {

            this.setState({
                submitting: true,
                requiredFieldsError: false,
                error_message: undefined,
                allowNav: true
            }, async () => {
                var answer_fields = [];
                answer_fields = [...this.state.form_answers_data];

                let form_type = this.state.form_type
                if (form_type === 0 || form_type === 3) { // REGULAR OR LMS
                    const body = {
                        fields: answer_fields,
                        commit: true
                    };

                    if (this.state.selectedAssignment) {
                        body.assignment_uuid = this.state.selectedAssignment;
                    }

                    try {
                        this.context.caches.submissionDrafts.cache.target.removeEventListener('draftChange', this.draftChangeHandler);
                        await this.context.services.forms.updateDraft(
                            this.state.submission.submission_uuid,
                            body,
                            form_type,
                            {
                                // syncNow if editing a submission
                                syncNow: this.state.edit_mode
                            }
                        );
                    } catch (error) {
                        this.handleSyncError(error, true);
                        this.setState({submitting: false});
                        return;
                    }

                    if (!this.context.isOffline) {
                        updateFormAssignments(this);
                    }

                    if (this.state.edit_mode) {
                        NotificationAlert("success", "", "Submission updated.");
                        this.props.history.push(`/${this.props.org_uuid}/home/team/${this.state.selected_team_uuid}/${form_type === 3 ? "courses" : "forms"}`);
                    } else {
                        scrollToTop("page-head");
                        this.syncDraft(true);
                        this.setState({submitting: false, submissionConfirmation: true});
                    }
                } else if (form_type === 2) { // INCIDENT
                    var body2 = JSON.stringify({
                        submission: {
                            fields: answer_fields,
                            form_uuid: this.props.form_data.form_uuid
                        }
                    });

                    submit_incident_form(this.props.incident_uuid, body2).then(function (success) {
                        updateFormAssignments(self);
                        success.json().then(success => {
                            self.props.submitFormCallback(true, safe_get(success, "submission", {}));
                        })
                    }, function (error) {
                        self.props.submitFormCallback(false, undefined);
                    });
                } else {
                    self.setState({submitting: false});
                }
            });
        } else {
            return
        }

    }

    async discard() {
        this.setState({discarding: true});

        if (!this.state.is_draft) {
            this.setState({allowNav: true}, () => {
                this.props.history.push(this.state.nextPath || this.getDefaultNextPath())
            })
        } else if (this.state.allowNav) {
            if (this.state.form_type === 2) {
                this.props.submitFormCallback(false, undefined)
            } else {
                try {
                    await this.context.services.forms.deleteSubmission(
                        this.state.submission.submission_uuid,
                        this.state.submission.assignment_uuid,
                        this.state.form_type
                    );
                } catch (err) {
                    const errMessage = await getErrorMessageFromResponse(err);
                    if (errMessage !== "Submission Not Found") {
                        NotificationAlert("error", "", "Unable to discard submission.");
                    }
                } finally {
                    this.setState({
                        allowNav: true, has_been_updated: true
                    }, () => {
                        this.props.history.push(this.state.nextPath || this.getDefaultNextPath());
                    });
                }
            }
        } else {
            this.setState({
                discarding: false,
                showSaveDraftModal: true,
            });
        }

    }

    deleteAndGoToDashboard = () => {
        let self = this;
        this.setState({allowNav: true, deleting: true, has_been_updated: true});
        if (this.state.submission && this.state.submission.draft) {
            delete_submission(this.state.submission.submission_uuid).then(function (success) {
                self.props.history.push("/" + self.props.org_uuid + "/home/dashboard");
            }, function (error) {
                NotificationAlert("success", "", "Unable to discard submission.")
            });
        } else {
            self.props.history.push("/" + self.props.org_uuid + "/home/dashboard");
        }

    }

    renderHeaderSubmissionInfo() {
        const {edit_mode, submittingAssignment, selectedAssignment, my_assignments} = this.state;
        const sharedTeams = safe_get(this.state, "submission.shared_teams", []);
        const assignmentTeams = safe_get(this.state, "assignment.teams", []);
        const userTeamUuids = this.props.teams.map(team => team.uuid);
        const filteredAssignmentTeams = assignmentTeams.filter(team => userTeamUuids.includes(team.uuid));

        let submitText = null;
        let teamNames = null;
        let editedByText = null;
        let createdDateText = null;
        let editDateText = null;
        let SubmissionURL = null;

        SubmissionURL = `${window.location.protocol}//${window.location.hostname}/${this.props.org_uuid}/home/team/${this.state.selected_team_uuid}/${this.state.form_type === 3 ? "courses" : "forms"}/${this.props.form_data.form_uuid}/submission`

        if (!edit_mode) {
            submitText = 'Submitting';
            editedByText = `${safe_get(this.state, "submission.edited_by.first_name", this.props.user.first_name)} ${safe_get(this.state, "submission.edited_by.last_name", this.props.user.last_name)}`;
            editDateText = dateFormatterWithTime(safe_get(this.state, "edited_at", Math.round(Date.now() / 1000)));

            if (submittingAssignment && filteredAssignmentTeams.length > 0) {
                teamNames = <TeamListPopover teams={filteredAssignmentTeams} ellipses={false}/>;
            } else if (selectedAssignment && my_assignments.length > 0) {
                const index = my_assignments.findIndex(assignment => assignment.assignment_uuid === selectedAssignment);

                if (index >= 0) {
                    teamNames = <TeamListPopover teamNames={my_assignments[index].team_names} ellipses={false}/>;
                } else {
                    teamNames = this.getTeamName();
                }
            } else {
                teamNames = this.getTeamName();

                if (!teamNames) {
                    teamNames = this.state.submission.team.name;
                }
            }
        } else {
            submitText = 'Submitted';
            editDateText = dateFormatterWithTime(safe_get(this.state, "edited_at", undefined));
            createdDateText = dateFormatterWithTime(safe_get(this.state, "submission.created_at", undefined));
            const submission = this.state.submission ?? {};
            editedByText = isPublicUser(submission.edited_by)
                ? getPublicUserDisplayName(submission, {fieldName: 'edited_by', includeFullDetails: true})
                : formatName(submission.edited_by);

            if (sharedTeams.length > 0) {
                teamNames = <TeamListPopover teams={[...sharedTeams, this.state.submission.team]} ellipses={false}/>;
            } else {
                teamNames = this.state.submission.team.name;
            }
        }

        return (
            <p className="header" style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                <span>{submitText} to {teamNames}{createdDateText ? ` on ${createdDateText}` : ''}.</span>
                { this.state.has_been_updated &&
                    <span> Last edited by {editedByText} on {editDateText}.</span>
                }
            </p>
        )
    }

    isSubmitDisabled = () => {
        const debugLogger = DebugLogger.getLogger("FormSubmitComponent.isSubmitDisabled");
        debugLogger.log('is_draft:', this.state.is_draft);
        debugLogger.log('submitting:', this.state.submitting);
        if (this.state.is_draft) {
            debugLogger.log('savingDraft:', this.state.savingDraft);
            debugLogger.log('submissionLocked:', this.state.submissionLocked);
            debugLogger.log('pendingDraftSave:', this.state.pendingDraftSave);
            return this.state.submitting || this.state.savingDraft || this.state.submissionLocked || this.state.pendingDraftSave;
        } else {
            return this.state.submitting;
        }
    }

    render() {
        if (this.state.loading || this.state.loadingAssignments) {
            return (
                <div className={mainContentContainerClass()}>
                    <div id="page-head" className="no-padding-mobile">
                        <div className="row">
                            <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                                <div id="page-title" style={{padding: '0px'}}>
                                    <div className="panel thin-border"
                                         style={{margin: '0 auto', display: 'flex', alignItems: 'center'}}>
                                        <div className="nav-header-panel"
                                             style={{width: "100%", display: "inline-block"}}>
                                            <div className="team-name-header">
                                                <h3 className={"section-titles admin-page-header"}
                                                    style={{margin: "0px"}}>
                                                    <Skeleton/>
                                                </h3>
                                            </div>
                                            <p className="header" style={{color: "#505050", marginBottom: "0px"}}>
                                                <Skeleton/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="page-content" className="no-padding-mobile">
                        <div className="row">
                            <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                                <div className={"panel thin-border"}>
                                    <div className="panel-body pad-15">
                                        <Skeleton height={35} width={300}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={150}/>
                                        <br/>
                                        <Skeleton height={40}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={250}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={250}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={200}/>
                                        <br/>
                                        <Skeleton height={55}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={250}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15}/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15}/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15}/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15}/>
                    </span>
                                        <Skeleton width={100}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={200}/>
                                        <br/>
                                        <Skeleton height={30}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={200}/>
                                        <br/>
                                        <Skeleton height={30}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        const showScore = this.props.form_data.show_scores_while_editing && this.state.denominator > 0;

        return (
            (<div className={mainContentContainerClass()}>
                {
                    this.state.isOverDue && !this.state.edit_mode &&
                    <Modal
                        title={<span><CloseCircleOutlined style={{marginRight: "5px", color: "#f56946"}}/>Missed Assignment</span>}
                        open={this.state.isOverDue}
                        maskClosable={false}
                        closable={false}
                        onCancel={this.props.cancel}
                        footer={
                            <div style={{height: "33px"}}>
                                <button className="btn btn-modal" onClick={this.deleteAndGoToDashboard}>
                                    {
                                        this.state.deleting ? <ButtonLoading/> : "Back to ZERO"
                                    }
                                </button>
                            </div>
                        }
                    >
                        <p className="zero-dark-grey">This assignment is overdue and can no longer be submitted.</p>
                    </Modal>
                }
                {
                    this.state.permission_error &&
                    <Modal
                        title={<span><CloseCircleOutlined
                            style={{marginRight: "5px", color: "#f56946"}}/>Access Error</span>}
                        open={this.state.permission_error}
                        maskClosable={false}
                        closable={false}
                        onCancel={this.props.cancel}
                        footer={
                            <div style={{height: "33px"}}>
                                <button className="btn btn-modal" onClick={this.deleteAndGoToDashboard}>
                                    {
                                        this.state.deleting ? <ButtonLoading/> : "Back to ZERO"
                                    }
                                </button>
                            </div>
                        }
                    >
                        <p className="zero-dark-grey">You do not have permission to complete this assignment.</p>
                    </Modal>
                }
                {
                    this.state.submissionNotFound &&
                    <Modal
                        title={<span><CloseCircleOutlined style={{marginRight: "5px", color: "#f56946"}}/>Submission not found</span>}
                        open={this.state.submissionNotFound}
                        maskClosable={false}
                        closable={false}
                        onCancel={this.props.cancel}
                        footer={
                            <div style={{height: "33px"}}>
                                <button className="btn btn-modal" onClick={() => {
                                    window.location = "/"
                                }}>
                                    Back to ZERO
                                </button>
                            </div>
                        }
                    >
                        <p className="zero-dark-grey">Cannot locate submission. To find and reopen your draft submission, please go back to ZERO and click on "My Drafts" at the top of the main forms page.</p>
                    </Modal>
                }
                {
                    this.state.showSaveDraftModal &&
                    <ConfirmModal
                        show={this.state.showSaveDraftModal}
                        cancel={() => {
                            this.setState({showSaveDraftModal: false});
                        }}
                        cancelAction={this.confirmDiscardDraft}
                        confirm={this.confirmSaveDraft}
                        title={"Save Draft?"}
                        body={"This form has not been submitted and contains unsaved changes. You can save it as a draft to submit later."}
                        cancelButtonName="Delete draft"
                        confirmButtonName="Save draft"
                    />
                }
                {
                    this.state.showAssignmentModal &&
                    <AssignmentSelectModal
                        show={this.state.showAssignmentModal}
                        assignments={this.state.assignments}
                        cancel={() => {
                            this.setState({showAssignmentModal: false});
                        }}
                        submit={() => {
                            this.setState({showAssignmentModal: false});
                            this.submitForm();
                        }}
                        confirm={this.handleAssignmentConfirm}
                    />
                }
                {
                    this.state.showSelectTeamModal &&
                    <SelectTeamModal
                        show={this.state.showSelectTeamModal}
                        cancel={() => {
                            this.setState({showSelectTeamModal: false})
                        }}
                        confirm={this.handleTeamSelectConfirm.bind(this)}
                    />
                }
                {
                    !this.state.submissionConfirmation &&
                    <div id="page-head" className="no-padding-mobile no-padding-print">
                        <div className="row">
                            <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                                <div id="page-title" style={{padding: '0px'}}>
                                    <div className="panel thin-border"
                                         style={{margin: '0 auto', display: 'flex', alignItems: 'center'}}>
                                        <div className="nav-header-panel no-padding-print" style={{
                                            width: "87%",
                                            borderRight: showScore ? "1px solid #e2e2e2" : "",
                                            borderRadius: "0px",
                                            display: "inline-block"
                                        }}>
                                            <div className="team-name-header">
                                                <h3 className={"section-titles admin-page-header"}
                                                    style={{display: "inline-block", margin: "0px"}}>
                                                    {!this.state.loading && safe_get(this.state, "form.name", "")}
                                                </h3>
                                            </div>
                                            {this.renderHeaderSubmissionInfo()}
                                        </div>

                                        {showScore &&
                                        <div style={{width: "13%", minWidth: "90px", display: "inline-block"}}>
                                            <p className="zero-blue text-center" style={{margin: '0 auto'}}>
                                                <b>Score</b>
                                                <br/>
                                                {`${this.state.numerator} / ${this.state.denominator} (${this.state.score}%)`}

                                                <br/>
                                                {
                                                    this.state.showSavedText && !this.state.edit_mode &&
                                                    <span>Saved</span>
                                                }
                                                {
                                                    this.state.showSavingText && !this.state.edit_mode &&
                                                    <span>Saving...</span>
                                                }
                                            </p>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div id="page-content" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">

                            {
                                this.state.submissionConfirmation &&
                                <FormConfirmation
                                    submissionId={this.state.submission?.submission_uuid ?? this.props.match?.params?.submission_uuid}
                                    formType={this.state.form_type}
                                    onBackButtonClick={() => {
                                        this.props.history.push(this.getDefaultNextPath({useSelectedTeamId: true}));
                                    }}
                                    onReturnToEdit={() => {
                                        this.setState({submissionConfirmation: false});
                                    }}
                                    hasCustomConfirmation={this.state.custom_confirmation_page}
                                    customConfirmationProps={{
                                        form_uuid: this.state.form_uuid,
                                        form_fields: this.state.form_fields,
                                        field_answers: this.state.form_answers_data,
                                        confirmation_fields: this.state.confirmation_fields,
                                        pages: [this.state.confirmation_fields],
                                        page: 0,
                                        is_confirmation: true,
                                        is_submission: true,
                                        toolbar_items: [],
                                    }}
                                />
                            }

                            {
                                !this.state.submissionConfirmation &&
                                <div className={"panel thin-border"}>
                                    <div className="panel-body pad-15">
                                        {
                                            this.state.isOverDue && !this.state.edit_mode && !this.state.loading &&
                                            <Alert
                                                type="error"
                                                message={
                                                    <p className="mar-btm-0">Missed: This assignment is overdue and can
                                                        no longer be submitted. <Link className="zero-light-blue"
                                                                                      to={`/${this.props.org_uuid}/home/dashboard`}>Go
                                                            Back to Dashboard</Link>.</p>
                                                }
                                            />
                                        }
                                        {
                                            !this.state.loading && !this.state.isOverDue && this.state.is_draft && safe_get(this.state, "submission.assignment_uuid", undefined) && safe_get(this.state, 'submission.has_been_updated', false) && this.props.location.search.includes("showPreviousDraftAssignmentAlert=1") &&
                                            <Alert
                                                closable={true}
                                                type="info"
                                                showIcon
                                                message={
                                                    <p className="mar-btm-0">You previously saved a draft for this
                                                        assignment. Your draft is displayed below.</p>
                                                }
                                            />
                                        }
                                        {
                                            this.state.error_message &&
                                            <Alert type="error" message={this.state.error_message} showIcon/>
                                        }
                                        <PageSelectDropdown
                                            pageCount={this.state.pages?.length ?? 0}
                                            currentPage={(this.state.page ?? 0) + 1}
                                            onPageChange={(newPage) => {
                                                this.setState({page: newPage});
                                            }}
                                        />
                                        <div style={{paddingBottom: "0px"}}>

                                            {
                                                this.state.loading &&
                                                <LoadingIndicator/>
                                            }
                                            {
                                                !this.state.loading &&
                                                <div>
                                                    <FormFieldsRenderer
                                                        refreshKey={this.state.refreshKey}
                                                        form_uuid={this.state.form_uuid}
                                                        submission_uuid={safe_get(this.props, "match.params.submission_uuid", "")}
                                                        form_fields={safe_get(this.state, "form_fields", [])}
                                                        field_answers={this.state.form_answers_data}
                                                        pages={this.state.pages}
                                                        page={this.state.page}
                                                        is_submission={true}
                                                        toolbar_items={[]}
                                                        updateAnswers={this.updateAnswers}
                                                        setSubmissionLock={this.setSubmissionLock}
                                                        updateScore={(numerator, denominator, score) => {
                                                            this.setState({
                                                                numerator: numerator,
                                                                denominator: denominator,
                                                                score: score
                                                            })
                                                        }}
                                                    />

                                                    <FormSubmissionControls
                                                        isDiscarding={this.state.discarding}
                                                        onDiscard={this.discard}
                                                        currentPage={this.state.page}
                                                        pages={this.state.pages}
                                                        setPage={page => this.setState({page})}
                                                        onSubmit={this.checkRequiredFields}
                                                        isSubmitDisabled={this.isSubmitDisabled()}
                                                        isSubmitting={this.state.isSubmitting}
                                                    />
                                                </div>

                                            }

                                            {
                                                this.state.requiredFieldsError &&
                                                <span className="error">Please complete required fields.</span>
                                            }

                                        </div>

                                    </div>
                                </div>
                            }
                            {
                                this.state.has_been_updated && this.state.submission && this.state.is_draft && !this.state.submissionConfirmation && !this.state.savingDraft && !this.state.pendingDraftSave && !this.state.submissionLocked &&
                                <button className="ButtonLink btn-link pull-right" style={{paddingRight: "15px"}}
                                        onClick={() => {
                                            this.setState({showSaveDraftModal: true})
                                        }}>Save Draft</button>
                            }
                        </div>

                    </div>
                </div>
            </div>)
        );
    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        current_team: safe_get(store, "teams_helper.team", ""),
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        teams: safe_get(store, "teams_helper.teams", ""),
        open_assignments: safe_get(store, "dashboard.openAssignments", []),
    }
}

export default withRouter(connect(mapStateToProps)(FormSubmitComponent));
