// @ts-check

import ResetGridLink from "components/Shared/DataGrid/ResetGridLink";
import { useSubmissionDataGridContext } from "./SubmissionDataGrid.context";
import { EventNames } from "./SubmissionDataGrid.utils";

const defaultSortModel = [
    {
        colId: "created_at",
        sort: "desc",
    },
];

export default function ResetSubmissionGridLink() {
    const { settings } = useSubmissionDataGridContext();

    return (
        <ResetGridLink
            settings={settings}
            resetTableEventName={EventNames.RESET_TABLE}
            defaultSortModel={defaultSortModel}            
        />
    );
}
