// @ts-check
import { Input } from 'antd';
import { useCallback, useState } from "react";

const {TextArea} = Input;

import AssignUsersWithPopover from "components/Shared/AssignUsersWithPopover";
import Button from "components/Shared/Button";

/**
 * @param {{
 *  availableUsers: any[],
 *  onCreateClicked: (body: string, assignedUsers: any[]) => any,
 * }} props 
 */
export default function AddCommentWithNotifications({availableUsers, onCreateClicked}) {
    const [assignedUsers, setAssignedUsers] = useState([]);
    const userCount = assignedUsers.length;
    const [body, setBody] = useState('');
    const [processingRequest, setProcessingRequest] = useState(false);

    const createDisabled = body.length === 0 || processingRequest;
    const cancelDisabled = processingRequest;

    const handleAssignedUserChange = useCallback((uuids) => {
        const newAssignedUsers = availableUsers.filter(user => uuids.includes(user.uuid));
        setAssignedUsers(newAssignedUsers);
    }, [availableUsers]);

    const handleCancelButton = useCallback(() => {
        setAssignedUsers([]);
        setBody('');
    }, []);

    const handleCreateButton = useCallback(async () => {
        if (createDisabled) return;

        const resp = onCreateClicked(body, assignedUsers);
        if (resp instanceof Promise) {
            setProcessingRequest(true);
            resp.finally(() => {
                handleCancelButton();
                setProcessingRequest(false);   
            });
        } else {
            handleCancelButton();
        }
    }, [body, assignedUsers, onCreateClicked, handleCancelButton]);

    return (
        <div>
            <div className="text-semibold">Add comment</div>
            <div className="zero-dark-grey">When I add a comment, {userCount} {userCount === 1 ? 'person' : 'people'} will be notified. All comments are logged on the activity timeline.</div>
            <AssignUsersWithPopover
                assignedUsers={assignedUsers}
                availableUsers={availableUsers}
                onChange={handleAssignedUserChange}
                respondersPopoverTitle="Notifications"
            />
            <TextArea
                value={body}
                onChange={e => setBody(e.target.value)}
                placeholder="Add your comment..."
                maxLength={5000}
                style={{marginTop: '1rem'}}
                autoSize={{
                    minRows: 4,
                    maxRows: 10
                }}
            />
            <div style={{display: 'flex', gap: '0 0.8rem', marginTop: '0.8rem'}}>
                <Button type="discard" onClick={handleCancelButton} disabled={cancelDisabled}>Cancel</Button>
                <Button type="primary" onClick={handleCreateButton} disabled={createDisabled}>Add comment</Button>
            </div>
        </div>
    );
}
