// @ts-check

import { Spin } from "antd";
import { createSubmissionComment, getSubmissionEditors } from "api/zero-api";
import InfoIconComponent from "components/InfoIconComponent";
import AddCommentWithNotifications from "components/Shared/AddCommentWithNotifications";
import UserPopoverCard from "components/Shared/UserPopoverCard";
import { useAsyncDataLoader } from "hooks/useAsyncDataLoader";
import { getErrorMessageFromResponse, safeProfilePic } from "other/Helper";
import { useCallback, useEffect, useMemo, useState } from "react";

/**
 * @param {{
 *  submissionId: string,
 *  onCommentCreated: () => void,
 * }} props
 */
export default function SubmissionActivityDetails({submissionId, onCommentCreated}) {
    const editorsLoader = useAsyncDataLoader({initialData: []});
    const { status, data: editors } = editorsLoader;
    const [error, setError] = useState('');

    useEffect(() => {
        editorsLoader.load(async () => {
            const res = await getSubmissionEditors(submissionId);
            const editors = await res.json();
            return editors;
        });
    }, []);

    const editorsSection = useMemo(() => {
        switch (status) {
            case 'error':
                return <small className="error">Could not load editors.</small>;
            case 'success':
                return (
                    <>
                        <div className="zero-dark-grey">{editors.length} {editors.length === 1 ? "person" : "people"} can view, edit, and comment.</div>
                        { editors.map(user => (
                                <UserPopoverCard
                                    key={user.uuid}
                                    user={user}
                                >
                                    <span style={{cursor: "pointer"}}>
                                        {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                                            marginRight: "5px",
                                            display: "inline-block"
                                        })}
                                    </span>
                                </UserPopoverCard>
                        )) }
                    </>
                )
            default:
                return <Spin />
        }
    }, [status]);

    const handleCreateComment = useCallback(async (body, assignedUsers) => {
        try {
            setError('');
            const requestBody = {
                body,
                notify_user_uuids: assignedUsers.map(user => user.uuid),
            };
    
            await createSubmissionComment(submissionId, requestBody);
            onCommentCreated?.();
        } catch (err) {
            const errMessage = await getErrorMessageFromResponse(err, 'Could not create comment.');
            console.error(errMessage);
            setError(errMessage);
        }
    }, [submissionId, onCommentCreated]);

    return (
        <div>
            <div className="text-semibold">
                Editors
                <InfoIconComponent
                    position={"bottom"}
                    width={"200px"}
                    text={'Editors are admins and team leads with access to this submission.'}
                />
            </div>
            {editorsSection}
            <hr />
            <AddCommentWithNotifications availableUsers={editors} onCreateClicked={handleCreateComment} />
            { error && <small className="error">{error}</small> }
        </div>
    )
}