import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import {
    getScheduleFrequency, hideFooterMobileApp, isAdmin, mainContentContainerClass, safeProfilePic, safe_get,
    scrollToTop
} from '../../../other/Helper.js';

import {
    archive_schedule, get_forms, get_forms_filters,
    get_schedules, getTeamsList, update_schedule
} from '../../../api/zero-api.js';

import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

import {
    CalendarOutlined,
    ControlOutlined,
    DeleteOutlined, EditOutlined,
    PieChartOutlined, PlusCircleOutlined, ReloadOutlined, UnorderedListOutlined, UserAddOutlined
} from '@ant-design/icons';
import { Dropdown, Menu, Pagination, Popover, Select } from 'antd';

import {IoIosMore as MoreIcon} from 'react-icons/io';
import NotificationAlert from '../../../other/NotificationAlert.js';
import ConfirmModal from '../../Shared/ConfirmModal.js';
import RespondersPopover from '../../Shared/RespondersPopover';
import UserPopoverCard from '../../Shared/UserPopoverCard.js';
import ChangeScheduleStatusModal from './ChangeScheduleStatusModal';
import NewEditScheduleModal from './NewEditScheduleModal';
import RespondersModal from './RespondersModal.js';
import ManageFormsNavigationTabs from '../ManageForms/ManageFormsNavigationTabs.js';

class ManageSchedules extends Component {
    constructor(props) {
        super(props);

        let pathname = safe_get(this.props, "location.pathname", "");

        const [filters, feFilters, page] = this.getFiltersFromQueryParams();

        this.state = {
            total_schedules: 0,
            pagination: page,
            teamsList: [],
            forms: [],
            form_categories: [],
            schedules: [],
            filtered_schedules: Array(9).fill(0),
            loading_schedules: true,
            filters: filters,
            FE_filters: feFilters,
            page_type: pathname.includes("courses") ? "lms" : "regular",
            loading_forms: false,
        };

        this.getSchedules = this.getSchedules.bind(this);

        this.goToForm = this.goToForm.bind(this);
        this.goToUser = this.goToUser.bind(this);

        this.formNameFormatter = this.formNameFormatter.bind(this);
        this.optionsFormatter = this.optionsFormatter.bind(this);

        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.deleteSchedule = this.deleteSchedule.bind(this);

        this.handleModalValues = this.handleModalValues.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            scrollToTop("page-head");

            let pathname = safe_get(this.props, "location.pathname", "")
            this.setState({
                page_type: pathname.includes("courses") ? "lms" : "regular",
            }, () => {
                this.getSchedules();
                this.getForms();
            });
        }
    }

    componentDidMount() {
        let self = this;

        scrollToTop("page-head");
        hideFooterMobileApp();
        this.getSchedules();
        this.getForms();

        if (isAdmin(this.props.user)) {
            getTeamsList()
                .then((teams) => {
                    self.setState({
                        teamsList: teams,
                    });
                })
                .catch((error) => {
                    self.setState({
                        teamsList: self.props.teams
                    })
                });
        } else {
            this.setState({
                teamsList: this.props.teams
            });
        }

    }

    getSchedules(loading = true) {
        var self = this;
        this.setState({loading_schedules: loading}, () => {
            let query_params = [...self.state.filters].join("&");
            get_schedules(`?form_types=${this.state.page_type}&page=${this.state.pagination}&per_page=50&${query_params}`).then(function (success) {
                success.json().then(success => {
                    var schedules = safe_get(success, "schedulers", []).sort(function (a, b) {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    });

                    self.setState({
                        schedules: schedules,
                        filtered_schedules: schedules,
                        loading_schedules: false,
                        edit_schedule: undefined,
                        total_schedules: safe_get(success, "total_schedulers", 50)
                    });

                });
            }, function (error) {
                self.setState({loading_schedules: false});
            });
        });
    }

    getForms = () => {
        var self = this;
        this.setState({loading_forms: true});
        get_forms(`?team_uuid=my_teams&all=true&enabled=true&form_types=${this.state.page_type}&include_fields=false`).then(function (success) {
            success.json().then(success => {
                var forms = safe_get(success, "forms", []).sort(function (a, b) {
                    return a.name.toLowerCase(0) > b.name.toLowerCase() ? 1 : -1
                });

                self.setState({
                    forms: forms,
                    loading_forms: false,
                });
            });
        }, function (error) {
            self.setState({loading_forms: false});
        });

        get_forms_filters().then(success => {
            success.json().then(success => {
                self.setState({
                    form_categories: safe_get(success, "categories", [])
                });
            });
        })
    }

    handleModalValues(form_title, form_type, selected_teams) {
        this.setState({
            // form_title: form_title,
            // form_type: form_type,
            // selected_teams: selected_teams,
            show_options_modal: false
        });

        var query = "?form_title=" + escape(form_title) + "&form_type=" + form_type + "&selected_teams=" + selected_teams
        this.props.history.push("/" + this.props.org_uuid + "/home/manage_forms_templates/new_form" + query);
    }

    onChangePage = (page) => {
        this.updateQueryParams(this.state.filters, page);

        this.setState({
            pagination: page
        }, () => {
            this.getSchedules()
        })
    }

    filterOption = (inputValue, option) => {
        return safe_get(option, "children", "").toLowerCase().includes(inputValue.toLowerCase())
    }

    selectFilter = (value) => {
        let filters = []

        value.forEach(filter => {
            if (filter.includes("form_uuid") || filter.includes("team_uuid") || filter.includes("status") || filter.includes("category_uuid")) {
                filters.push(filter)
            } else {
                filters.push(`search=${filter}`)
            }
        });

        this.updateQueryParams(filters, 1);

        this.setState({FE_filters: value, filters: filters, pagination: 1}, () => {
            this.getSchedules()
        })
    }

    goToForm(form_uuid) {
        this.props.history.push("/" + this.props.org_uuid + "/home/manage_forms_templates/edit_form/" + form_uuid);
    }

    goToUser(user_uuid) {
        this.props.history.push("/" + this.props.org_uuid + "/users/" + user_uuid);
    }

    formNameFormatter = (schedule) => {
        let name = schedule.name
        let repeat_period = schedule.repeat_period

        let custom_start_timestamp = safe_get(schedule, 'custom_start_utc_timestamp', null)
        if (!custom_start_timestamp) {
            let dt = new Date();
            custom_start_timestamp = dt.setUTCHours(10, 0, 0, 0) / 1000;
        }

        let custom_start_time = moment.unix(custom_start_timestamp).format("hA");

        return (
            <div>
                <button className="ButtonLink btn-link" onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/" + this.props.org_uuid + "/home/manage_schedules/analytics/" + schedule.scheduler_uuid);
                }} style={{display: "block", fontWeight: "500", textAlign: "left"}}>
                    {name}
                </button>
                <span className="zero-dark-grey" style={{fontSize: "12px", fontWeight: "400"}}>
          {
              getScheduleFrequency(repeat_period)
          }
                    &nbsp;at {custom_start_time}
        </span>
            </div>
        );
    }

    teamFormatter = (schedule) => {
        let teams = safe_get(schedule, "teams", []);
        let team_names = teams
            .map((team) => team.name)
            .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
            .map((name, index) => <div key={index}>{name}</div>);

        return (
            <Popover content={team_names} placement="topLeft" overlayStyle={{maxWidth: "220px"}}>
        <span className="zero-dark-grey" style={{fontWeight: "400", display: "block"}}>
          {teams.length}
        </span>
            </Popover>
        );
    }

    totalAssignmentsFormatter(schedule) {
        var assigns_count = schedule.assigns_count
        return (
            <span className="zero-dark-grey" style={{fontWeight: "400"}}>
        {assigns_count}
      </span>
        );
    }

    totalCompletedFormatter(schedule) {
        var completed = schedule.assigns_completed
        return (
            <span className="zero-dark-grey" style={{fontWeight: "400"}}>
        {completed}
      </span>
        );
    }

    templateFormatter = (schedule) => {
        return (
            <span className="zero-dark-grey" style={{fontWeight: "400"}}>
        {schedule.form.name}
      </span>
        );
    }

    respondersFormatter = (schedule) => {
        const responders = schedule.subscribers;
        const responderCount = schedule.subscribers_count;

        if (schedule.assigned_to_team) {
            return (
                <RespondersPopover
                    schedule={schedule}
                    selected_responders={responders}
                    updateResponders={this.updateResponders}
                >
                    <p className="zero-dark-grey text-center mar-btm-0 link-hover" style={{fontWeight: "400"}}
                       onClick={() => {
                           this.setState({selectedSchedule: schedule})
                       }}>All Team Members</p>
                </RespondersPopover>
            )
        } else {
            return (
                <div style={{textAlign: "center", whiteSpace: "nowrap"}}>
                    {
                        responders.slice(0, 3).map((user, index) => (
                            <UserPopoverCard key={index} user={user} showRemoveScheduleResponder responders={responders}
                                             schedule={schedule} setSchedule={this.setSchedule}>
                <span style={{cursor: "pointer"}}>
                  {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                      marginRight: "5px",
                      display: "inline-block"
                  })}
                </span>
                            </UserPopoverCard>
                        ))
                    }
                    {
                        responders.length > 3 &&
                        <span>
              <RespondersPopover
                  schedule={schedule}
                  selected_responders={responders}
                  updateResponders={this.updateResponders}
              >
                <div className={"default-avatar-circle bulletin hover-cursor"}
                     style={{marginRight: "5px", display: "inline-block", border: "1px solid #505050"}} onClick={() => {
                    this.setState({selectedSchedule: schedule})
                }}>
                  <p className="initials" style={{color: "#505050"}}>+{responderCount - 3}</p>
                </div>
              </RespondersPopover>
            </span>
                    }
                    {
                        responders.length <= 3 &&
                        <RespondersPopover
                            schedule={schedule}
                            selected_responders={responders}
                            updateResponders={this.updateResponders}
                        >
                            <button className="ButtonLink post-details-content mar-top-5"
                                    style={{verticalAlign: "bottom", height: "26px", width: "26px"}} onClick={() => {
                                this.setState({selectedSchedule: schedule})
                            }}>
                                <UserAddOutlined style={{fontSize: "19px"}}/>
                            </button>
                        </RespondersPopover>
                    }
                </div>
            )
        }

    }

    updateResponders = async (responder_uuids, assigned_to_team) => {
        let body = JSON.stringify({
            name: this.state.selectedSchedule.name,
            enabled: true,
            team_uuids: this.state.selectedSchedule.teams.map(team => team.team_uuid),
            form_uuid: this.state.selectedSchedule.form.form_uuid,
            user_uuids: responder_uuids,
            assigned_to_team: assigned_to_team,
            repeat_period: this.state.selectedSchedule.repeat_period,
            start_date: this.state.selectedSchedule.start_date,
            custom_start_time: this.state.selectedSchedule.custom_start_time,
            custom_start_timezone: this.state.selectedSchedule.custom_start_timezone,
            end_date: this.state.selectedSchedule.end_date
        });

        try {
            const res = await update_schedule(this.state.selectedSchedule.scheduler_uuid, body);
            const data = await res.json();

            NotificationAlert("success", "", "Schedule updated.");

            this.setSchedule(data);
        } catch (err) {
            NotificationAlert("success", "", "Unable to update schedule.");
        }
    }

    optionsFormatter = (schedule) => {
        const deleteIsDisabled = isAdmin(this.props.user) === false;

        const menu = {
            onClick: ({key}) => this.handleMenuClick(key, schedule),
            items: [
                {
                    key: 'edit',
                    icon: <EditOutlined/>,
                    label: 'Edit Schedule',
                },
                {
                    key: 'status',
                    icon: <ControlOutlined/>,
                    label: 'Change Status',
                },
                {
                    key: 'analytics',
                    icon: <PieChartOutlined/>,
                    label: 'View Analytics',
                },
                {
                    type: 'divider',
                },
                {
                    key: 'delete',
                    label: 'Archive Schedule',
                    icon: <DeleteOutlined/>,
                    className: deleteIsDisabled ? "" : "zero-delete-red",
                    disabled: deleteIsDisabled,
                },
            ]
        }

        return (
            <div className="more" style={{marginTop: '6px', color: "grey", lineHeight: 0, height: 'fit-content'}}>
                {
                    <Dropdown menu={menu} trigger={['click']}>
                        <button className="ButtonLink ant-dropdown-link">
                            <MoreIcon style={{height: '20px', width: '20px'}}/>
                        </button>
                    </Dropdown>
                }
            </div>
        );
    }

    handleMenuClick(key, schedule) {
        if (key === "edit") {
            this.setState({edit_schedule: schedule, show_schedule_modal: true});
        } else if (key === "analytics") {
            this.props.history.push("/" + this.props.org_uuid + "/home/manage_schedules/analytics/" + schedule.scheduler_uuid);
        } else if (key === "status") {
            this.setState({showChangeStatusModal: true, edit_schedule: schedule});
        } else if (key === "delete") {
            this.setState({showDeleteScheduleModal: true, edit_schedule: schedule});
        }
    }

    deleteSchedule() {
        var self = this;
        archive_schedule(this.state.edit_schedule.scheduler_uuid).then(function (success) {
            self.getSchedules();
            self.setState({showDeleteScheduleModal: false, edit_schedule: undefined});
            NotificationAlert("success", "", "Schedule archived.")
        }, function (error) {
            NotificationAlert("error", "", "Unable to archive schedule.")
        });
    }

    setSchedule = data => {
        const uuid = safe_get(data, 'scheduler.scheduler_uuid', null);
        if (uuid) {
            const index = this.state.schedules.findIndex(schedule => schedule.scheduler_uuid === uuid)
            if (index !== -1) {
                const schedules = [...this.state.schedules];
                schedules.splice(index, 1, data.scheduler);
                this.setState({schedules, filtered_schedules: schedules});
            }
        }
    }

    updateQueryParams(filters, page) {
        const queryParams = new URLSearchParams(filters.join("&"));
        queryParams.set('page', page);
        const newLocation = `${this.props.location.pathname}?${queryParams.toString()}`;
        this.props.history.replace(newLocation);
    }

    getFiltersFromQueryParams() {
        if (this.props.location.search.length === 0) {
            // no query params, use default filters
            const filters = ["status=active"];
            return [filters, filters, 1];
        }

        const queryParams = new URLSearchParams(this.props.location.search);
        const filters = [];
        const feFilters = [];
        let page = 1;
        for (const [key, value] of queryParams.entries()) {
            if (key === 'page') {
                page = parseInt(value, 10);
                if (Number.isNaN(page)) {
                    page = 1;
                }
                continue;
            }

            filters.push(`${key}=${value}`);
            if (key !== 'search') {
                feFilters.push(`${key}=${value}`);
            } else {
                feFilters.push(value);
            }
        }

        return [filters, feFilters, page];
    }

    render() {
        return (
            <div className={mainContentContainerClass()}>

                {
                    this.state.show_schedule_modal &&
                    <NewEditScheduleModal
                        show={this.state.show_schedule_modal}
                        cancel={() => {
                            this.setState({show_schedule_modal: false, edit_schedule: undefined});
                        }}
                        updateSchedules={this.getSchedules}
                        setSchedule={this.setSchedule}
                        schedule={this.state.edit_schedule}
                        user={this.props.user}
                        user_teams={this.props.teams}
                        page_type={this.state.page_type}
                    />
                }
                {
                    this.state.showChangeStatusModal &&
                    <ChangeScheduleStatusModal
                        show={this.state.showChangeStatusModal}
                        cancel={() => {
                            this.setState({showChangeStatusModal: false, edit_schedule: undefined});
                        }}
                        updateSchedules={this.getSchedules}
                        schedule={this.state.edit_schedule}
                    />
                }
                {
                    this.state.showDeleteScheduleModal &&
                    <ConfirmModal
                        show={this.state.showDeleteScheduleModal}
                        cancel={() => {
                            this.setState({showDeleteScheduleModal: false, edit_schedule: undefined});
                        }}
                        title={"Archive Schedule"}
                        body={"Are you sure you want to archive this schedule? Archiving will remove this schedule from your schedules list, and users will no longer be able to complete assignments on this schedule. Any open assignments will be automatically marked as excused."}
                        confirmButtonName={"Archive schedule"}
                        confirm={this.deleteSchedule}
                    />
                }
                {
                    this.state.showRespondersModal &&
                    <RespondersModal
                        show={this.state.showRespondersModal}
                        cancel={() => {
                            this.setState({showRespondersModal: false});
                        }}
                        schedule={this.state.selectedSchedule}
                        updateSchedules={this.getSchedules}
                    />
                }

                <div id="page-head" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                Manage Schedules
                                            </h3>
                                            <button className="btn btn-primary pull-right hidden-xs" style={{
                                                width: '145px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={() => {
                                                this.setState({show_schedule_modal: true});
                                            }}>
                                                <PlusCircleOutlined/> Create Schedule
                                            </button>
                                            <button className="btn btn-primary pull-right visible-xs" style={{
                                                width: '50px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={() => {
                                                this.setState({show_schedule_modal: true});
                                            }}>
                                                <PlusCircleOutlined/>
                                            </button>
                                        </div>
                                        <p className="header"
                                           style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                            Create and edit schedules.
                                        </p>

                                        <div className="members-search" style={{width: "100%", marginTop: "8px"}}>
                                            {this.state.loading_forms && <Skeleton height={32} />}

                                            {!this.state.loading_forms && <>
                                            <Select
                                                mode="tags"
                                                placeholder={"Filter or search..."}
                                                style={{width: "100%"}}
                                                // filterOption={false}
                                                // onSearch={this.searchSchedule}
                                                filterOption={this.filterOption}
                                                onChange={this.selectFilter}
                                                value={this.state.FE_filters}
                                                allowClear={true}
                                                virtual={false}
                                                // showSearch={true}
                                            >
                                                <Select.OptGroup label={"Schedule Status"}>
                                                    <Select.Option value={"status=active"}>Active</Select.Option>
                                                    <Select.Option value={"status=paused"}>Paused</Select.Option>
                                                </Select.OptGroup>
                                                {
                                                    this.state.form_categories.length > 0 && this.state.page_type !== "lms" &&
                                                    <Select.OptGroup label={"Category"}>
                                                        {
                                                            this.state.form_categories.map((category, index) => (
                                                                <Select.Option key={`catgeory:${index}`}
                                                                               value={"category_uuids=" + category.category_uuid}>{category.category_name}</Select.Option>
                                                            ))
                                                        }
                                                    </Select.OptGroup>
                                                }
                                                <Select.OptGroup
                                                    label={`${this.state.page_type === "lms" ? "Course" : "Template"} Name`}>
                                                    {
                                                        this.state.forms.map((form, index) => (
                                                            <Select.Option key={`form:${index}`}
                                                                           value={"form_uuid=" + form.form_uuid}>{form.name}</Select.Option>
                                                        ))
                                                    }
                                                </Select.OptGroup>
                                                <Select.OptGroup label={"Team Name"}>
                                                    {
                                                        this.state.teamsList.map((team, index) => (
                                                            <Select.Option key={`team:${index}`}
                                                                           value={"team_uuid=" + team.uuid}>{team.name}</Select.Option>
                                                        ))
                                                    }
                                                </Select.OptGroup>
                                            </Select>
                                            {
                                                !this.state.loading_schedules &&
                                                <p className="badge schedules_total">Schedules: {this.state.total_schedules}</p>
                                            }
                                            </>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="page-content" className="no-padding-mobile">
                    <div className="row" style={{maxWidth: "850px", margin: "0 auto"}}>
                        <div className="tab-base" style={{
                            marginBottom: "0px",
                            padding: "0px",
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <ManageFormsNavigationTabs currentTab="schedules" pageType={this.state.page_type}/>
                            <div className="hidden-xs" style={{display: 'flex', alignItems: 'center'}}>

                                <div
                                    className="hover-cursor zero-blue"
                                    style={{lineHeight: 1}}
                                >
                                    {this.props.location.pathname.includes("manage_calendar") ?
                                        <Link to={`/${this.props.org_uuid}/home/manage_schedules`}>
                                            <UnorderedListOutlined className="zero-blue" style={{fontSize: '20px'}}/>
                                        </Link> :

                                        <Link to={`/${this.props.org_uuid}/home/manage_calendar`}>
                                            <CalendarOutlined className="zero-blue" style={{fontSize: '20px'}}/>
                                        </Link>
                                    }
                                </div>
                                <ReloadOutlined className="hover-cursor zero-blue mar-rgt-10 mar-lft-10"
                                                style={{fontSize: '18px'}} onClick={this.getSchedules}/>
                            </div>
                        </div>
                        <div className="tab-content" style={{
                            border: "1px solid #e2e2e2",
                            padding: "4px 4px",
                            float: "left",
                            background: "white",
                            paddingBottom: "35px",
                            width: "100%"
                        }}>
                                <div className="table-responsive" style={{border: "none"}}>
                                    <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                                        <thead style={{color: "#1D2A35"}}>
                                        <tr>
                                            <th></th>
                                            <th className="zero-blue" style={{width: "250px"}}>
                                                {this.state.loading_schedules ? <Skeleton width={130}/> : "Schedule"}
                                            </th>
                                            <th className="zero-blue" style={{width: "75"}}>
                                                {this.state.loading_schedules ? <Skeleton width={45} /> : "Type"}
                                            </th>
                                            <th className="zero-blue" style={{width: "210px"}}>
                                                {this.state.loading_schedules ? <Skeleton
                                                    width={130}/> : this.state.page_type === "lms" ? "Course" : "Template"}
                                            </th>
                                            <th className="zero-blue text-center">
                                                {this.state.loading_schedules ? <Skeleton width={50}/> : "Teams"}
                                            </th>
                                            <th className="zero-blue" style={{width: "210px", textAlign: "center"}}>
                                                {this.state.loading_schedules ? <Skeleton width={130}/> : "Assignees"}
                                            </th>
                                            <th className="zero-blue text-center" style={{width: "125px"}}>
                                                {this.state.loading_schedules ? <Skeleton width={80}/> : "Total Assignments"}
                                            </th>
                                            <th className="zero-blue text-center" style={{width: "125px"}}>
                                                {this.state.loading_schedules ? <Skeleton width={80}/> : "Total Completed"}
                                            </th>
                                        </tr>
                                        </thead>
                                        {
                                            this.state.loading_schedules &&
                                            <tbody>
                                            {
                                                [...Array(10)].map((_item, index) => (
                                                    <tr key={index} className="tr-hover">
                                                        <td><Skeleton width={25}/></td>
                                                        <td style={{width: "250px"}}>
                                                            <Skeleton width={115}/>
                                                            <Skeleton width={70}/>
                                                        </td>
                                                        <td>
                                                            <Skeleton width={50}/>
                                                        </td>
                                                        <td style={{width: "210px"}}><Skeleton width={130}/></td>
                                                        <td className="text-center"><Skeleton width={50}/></td>
                                                        <td style={{width: "210px", textAlign: "center"}}>
                                                            {
                                                                [...Array(4)].map((e, index) => (
                                                                    <span key={index}
                                                                        style={{marginRight: "5px", marginTop: "5px"}}>
                                        <Skeleton key={index} circle={true} width={26} height={26} inline />
                                        </span>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="text-center" style={{width: "125px"}}><Skeleton
                                                            width={80}/></td>
                                                        <td className="text-center" style={{width: "125px"}}><Skeleton
                                                            width={80}/></td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        }
                                        {
                                            !this.state.loading_schedules && this.state.filtered_schedules.length > 0 &&
                                            <tbody>
                                            {
                                                this.state.filtered_schedules.map((schedule, index) => (
                                                    <tr key={index} className="tr-hover">
                                                        <td>{this.optionsFormatter(schedule)}</td>
                                                        <td style={{width: "250px"}}>{this.formNameFormatter(schedule)}</td>
                                                        <td style={{width: "75px"}}>
                                                            <span className="zero-dark-grey">
                                                                { schedule.type === "regular" ? "Regular" : "Shared" }
                                                            </span>
                                                        </td>
                                                        <td style={{width: "210px"}}>{this.templateFormatter(schedule)}</td>
                                                        <td className="text-center">{this.teamFormatter(schedule)}</td>
                                                        <td style={{width: "210px"}}>{this.respondersFormatter(schedule)}</td>
                                                        <td className="text-center"
                                                            style={{width: "125px"}}>{this.totalAssignmentsFormatter(schedule)}</td>
                                                        <td className="text-center"
                                                            style={{width: "125px"}}>{this.totalCompletedFormatter(schedule)}</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        }
                                        {
                                            !this.state.loading_schedules && this.state.filtered_schedules.length === 0 &&
                                            <tbody>
                                            <tr>
                                                <td className="react-bs-table-no-data zero-dark-grey" colSpan="6">
                                                    No schedules.
                                                </td>
                                            </tr>
                                            </tbody>
                                        }

                                    </table>
                                </div>

                                <div style={{marginTop: "10px", marginBottom: "10px"}}>
                                    <Pagination
                                        size={"small"}
                                        showSizeChanger={false}
                                        hideOnSinglePage={true}
                                        pageSize={50}
                                        total={this.state.total_schedules}
                                        current={this.state.pagination}
                                        onChange={this.onChangePage}
                                    />
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
        );
    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        teams: safe_get(store, "teams_helper.teams", [])
    }
}

export default withRouter(connect(mapStateToProps)(ManageSchedules));
