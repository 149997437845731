import React, {Component} from 'react';

import {safe_get} from '../../other/Helper'
import {get_user, change_user_teams, getTeamsList} from '../../api/zero-api.js'

import ButtonLoading from './ButtonLoading';
import Skeleton from 'react-loading-skeleton';

import {Checkbox, Row, Col, Modal} from 'antd'
import '../../assets/css/antd-custom.css';

class ChangeTeamsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamOptions: [],
            defaultTeams: [],
            selectedTeams: [],
            loadingTeamOptions: true,
        };

        this.getAdminTeams = this.getAdminTeams.bind(this);
        this.getTeamLeadTeams = this.getTeamLeadTeams.bind(this);
        this.teamSelect = this.teamSelect.bind(this);
        this.updateTeams = this.updateTeams.bind(this);

    }

    componentDidMount() {
        var self = this;
        get_user(this.props.userToUpdate.uuid).then(function (success) {
            success.json().then(success => {

                var userTeams = [];
                var userCurrentTeams = safe_get(success, "user.teams", []);
                for (var i in userCurrentTeams) {
                    userTeams.push(userCurrentTeams[i].uuid)
                }

                self.setState({defaultTeams: userTeams, selectedTeams: userTeams});
            });
        }, function (error) {

        });

        if (this.props.isAdmin) {
            this.getAdminTeams();
        } else {
            this.getTeamLeadTeams();
        }

    }

    getAdminTeams() {
        getTeamsList()
            .then((teams) => {
                this.setState({teamOptions: teams, loadingTeamOptions: false});
            })
            .catch((error) => {
                this.setState({loadingTeamOptions: false});
            });
    }

    getTeamLeadTeams() {
        var teams = this.props.teams;
        this.setState({teamOptions: teams, loadingTeamOptions: false});
    }

    teamSelect(value) {
        this.setState({selectedTeams: value});
    }

    getNewTeamIds() {
        if (this.props.isAdmin) {
            return this.state.selectedTeams;
        }

        const defaultTeamIds = this.state.defaultTeams;
        const teamOptionsIds = this.state.teamOptions.map(t => t.uuid);
        const hiddenTeams = defaultTeamIds.filter(id => teamOptionsIds.includes(id) === false);

        return [
            ...this.state.selectedTeams,
            ...hiddenTeams
        ]
    }

    updateTeams() {
        var self = this;
        if (!this.state.updatingTeams) {
            this.setState({updatingTeams: true});

            var body = JSON.stringify({
                user_uuid: this.props.userToUpdate.uuid,
                team_uuids: this.getNewTeamIds(),
            });

            change_user_teams(body).then(function (success) {
                self.setState({updatingTeams: false});
                self.props.changeTeamsCallback(true, self.props.userToUpdate.uuid);
            }, function (error) {
                self.setState({updatingTeams: false});
                self.props.changeTeamsCallback(false, error);
            });
        }

    }


    render() {
        return (
            (<Modal
                title={"Change teams"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        {this.props.isAdmin && <button className="ButtonLink pull-left zero-delete-red link-hover"
                                                       onClick={this.props.deactivate}>Deactivate User</button>}
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.updateTeams}
                                disabled={this.state.selectedTeams.length === 0}>
                            {
                                this.state.updatingTeams ? <ButtonLoading/> : "Change"
                            }
                        </button>
                    </div>
                }
            >
                <p className="zero-dark-grey">Change teams
                    for {this.props.userToUpdate.first_name} {this.props.userToUpdate.last_name}:</p>
                {
                    this.state.loadingTeamOptions &&
                    <div className="form-group">
                        <Checkbox.Group style={{width: '100%'}} value={this.state.selectedTeams}
                                        defaultValue={this.state.defaultTeams} onChange={this.teamSelect}>
                            <Row>
                                {
                                    [...Array(16)].map((_item, index) => (
                                        <Col span={12} key={index}>
                                            <Skeleton width={120}/>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Checkbox.Group>
                    </div>
                }
                {
                    !this.state.loadingTeamOptions &&
                    <div className="form-group">
                        <Checkbox.Group style={{width: '100%'}} value={this.state.selectedTeams}
                                        defaultValue={this.state.defaultTeams} onChange={this.teamSelect}>
                            <Row>
                                {
                                    this.state.teamOptions.map((team, index) => (
                                        <Col span={12} key={index}>
                                            <Checkbox key={index} value={team.uuid}>
                                                {team.name}
                                            </Checkbox>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Checkbox.Group>
                    </div>
                }
            </Modal>)
        );
    }
}

export default ChangeTeamsModal;
