// @ts-check
import { Input, Modal } from "antd";
import Button from "components/Shared/Button";
import { isEmpty } from "lodash-es";
import { validateEmail } from "other/Helper";
import { useId, useState } from "react";

/**
 * @param {{
 *  name: string,
 *  email: string,
 *  nameRequired: boolean,
 *  emailRequired: boolean,
 *  onSubmit: (values: {name: string, email: string}) => void,
 * }} props
 * @returns 
 */
export default function PublicUserDetailsModal({name: initialName, email: initialEmail, nameRequired, emailRequired, onSubmit}) {
    const [name, setName] = useState(initialName);
    const [email, setEmail] = useState(initialEmail);
    const [errors, setErrors] = useState(/** @type {{name?: string, email?: string}} */({}));
    const nameFieldId = useId();
    const emailFieldId = useId();

    const onConfirm = () => {
        const newErrors = {};

        if (nameRequired && !name) {
            newErrors.name = "Name is required.";
        }

        if (emailRequired && !email) {
            newErrors.email = "Email is required.";
        } else if (emailRequired && !validateEmail(email)) {
            newErrors.email = "Please provide a valid email address.";
        }

        setErrors(newErrors);

        if (isEmpty(newErrors)) {
            onSubmit({name, email});
        }
    }

    return (
        <Modal
            open
            title="Required Information"
            closable={false}
            maskClosable={false}
            maskTransitionName="maskTransitionName"
            footer={
                <div>
                    <Button type="primary" onClick={onConfirm}>Confirm</Button>
                </div>
            }
        >
            {nameRequired &&
                <div className="form-group">
                    <label className="text-bold" htmlFor={nameFieldId}>Name</label>
                    <Input
                        id={nameFieldId}
                        value={name}
                        maxLength={200}
                        onChange={(e) => setName(e.target.value)}
                        status={errors.name ? "error" : ""}
                    />
                    {errors.name &&
                        <div><small className="error">{errors.name}</small></div>
                    }
                    <label htmlFor={nameFieldId}>Please enter your full name above.</label>
                </div>
            }
            {emailRequired &&
                <div className="form-group">
                    <label className="text-bold" htmlFor={emailFieldId}>Email</label>
                    <Input
                        id={emailFieldId}
                        type="email"
                        value={email}
                        maxLength={400}
                        onChange={(e) => setEmail(e.target.value)}
                        status={errors.email ? "error" : ""}
                    />
                    {errors.email &&
                        <div><small className="error">{errors.email}</small></div>
                    }
                    <label htmlFor={emailFieldId}>Please enter your email address above.</label>
                </div>
            }
        </Modal>
    )
}