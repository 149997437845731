// @ts-check
import { useDataGridSettings } from "components/Bulletin/FeedDataGrid/FeedDataGrid.hooks";
import { createContext, useContext, useMemo, useRef, useState } from "react";

export const SubmissionDataGridContext = createContext(null);

/** @returns {ReturnType<useContextValue>} */
export function useSubmissionDataGridContext() {
    return useContext(SubmissionDataGridContext);
}

export function SubmissionDataGridContextProvider({children}) {
    const ctxValue = useContextValue();

    return (
        <SubmissionDataGridContext.Provider value={ctxValue}>
            {children}
        </SubmissionDataGridContext.Provider>
    )
}

function useContextValue() {
    const [settings, setSettings] = useDataGridSettings("submissions");
    const [activeModal, setActiveModal] = useState(null);
    const [rowCount, setRowCount] = useState(null);
    /** @type {import("react").MutableRefObject<AgGridReactLib.AgGridReact>} */
    const gridRef = useRef();

    return useMemo(
        () => ({
            settings,
            setSettings,
            gridRef,
            activeModal,
            setActiveModal,
            rowCount,
            setRowCount,
        }),
        [settings, setSettings, gridRef, activeModal, setActiveModal, rowCount, setRowCount]
    );
}
