import { randomInt } from 'other/Helper';
import { useCallback, useLayoutEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import QuillImageDropAndPaste, { ImageData }from "quill-image-drop-and-paste";
import { useFileUploadContext } from './FileUpload';

// Quill styles
import 'react-quill/dist/quill.snow.css';
import './ZeroQuill.scss'; // Override Quill styles

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);

/**
 * @param {ZeroQuillProps} props
 * @returns {JSX.Element} 
 */
export function ZeroQuill({height, disableUploads, onImagePaste, ...props}) {
    const reactQuillEl = useRef(null);
    const id = useRef(`quill-bounds-${randomInt(0, 10000)}`);
    const fileUploadContext = useFileUploadContext();

    const {theme, style, modules, ...otherProps} = props;

    useLayoutEffect(() => {
        if (reactQuillEl.current) {
            // remove tab focus from toolbar controls
            const els = document.querySelectorAll(".ql-toolbar *");
            els.forEach(el => el.setAttribute("tabindex", "-1"));

            const editor = reactQuillEl.current.getEditor();

            // prevent tab from being used to indent once you're in the quill editor
            const keyboard = editor.getModule('keyboard');
            delete keyboard.bindings[9];

            if (height) {
                editor.container.style.height = `${height}px`;
            } else {
                editor.container.style.minHeight = '5em';
            }

            addToolbarImageHandler(editor);
        }
    }, [reactQuillEl.current, height]);

    // This callback cannot change between renders
    const imageHandler = useCallback((dataUrl, type, imageData) => {
        if (disableUploads) {
          return;
        }

        if (onImagePaste) {
          onImagePaste?.(imageData.toFile());
        } else if (fileUploadContext !== null) {
          const file = imageData.toFile();
          if (fileUploadContext.beforeUploadHandler(file, [file]) === true) {
            fileUploadContext.handleFileUpload({file});
          }
        }
    }, []);

    function addToolbarImageHandler(editor) {
      if (disableUploads) {
        return;
      }

      editor.getModule("toolbar").addHandler("image", function (clicked) {
        if (clicked) {
          let fileInput = this.container.querySelector(
            "input.ql-image[type=file]"
          );
          if (fileInput == null) {
            fileInput = document.createElement("input");
            fileInput.setAttribute("type", "file");
            fileInput.setAttribute(
              "accept",
              "image/png, image/gif, image/jpeg, image/bmp, image/x-icon"
            );
            fileInput.classList.add("ql-image");
            fileInput.addEventListener("change", function (e) {
              const files = e.target.files;
              let file;
              if (files.length > 0) {
                file = files[0];
                const type = file.type;
                const reader = new FileReader();
                reader.onload = (e) => {
                  const dataUrl = e.target.result;
                  imageHandler(
                    dataUrl,
                    type,
                    new ImageData(dataUrl, type, file.name)
                  );
                  fileInput.value = "";
                };
                reader.readAsDataURL(file);
              }
            });
          }
          fileInput.click();
        }
      });
    }
    return (
        <div id={id.current} className="zero-dark-grey">
            <ReactQuill
                ref={reactQuillEl}
                theme={theme ?? "snow"}
                bounds={`#${id.current}`}
                style={style}
                modules={{
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{'color': []}, {'background': []}],
                        [{'align': []}],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        disableUploads ? ['link'] : ['link', 'video', 'image'],
                        ['clean']
                    ],
                    imageDropAndPaste: {
                        handler: imageHandler
                    },
                    ...modules
                }}
                {...otherProps}
            />
        </div>
    )
}