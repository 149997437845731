// @ts-check

import { FormType } from "other/Constants";
import ZERO_logo from "../../assets/css/img/ZERO-(blue)-small.png";
import SyncInProgressSpinner from "components/Offline/SyncInProgressSpinner";
import { useZeroContext } from "components/ZeroContext";
import ConfirmationFieldsRenderer from "./ConfirmationFieldsRenderer";
import { useCallback, useEffect, useState } from "react";
import { useFormSubmissionEvents } from "hooks/formHooks";
import { useDraftsCurrentlySyncing } from "offline/SubmissionDraftsCache";
import SubmissionSyncErrorAlert from "components/Offline/Forms/SubmissionSyncErrorAlert";
import { isEmpty } from "lodash-es";

const SyncState = {
    INITIAL_STATE: 0,
    IS_SYNCING: 1,
    FINISHED_SYNCING: 2,
    OFFLINE: 3,
    ERROR: 4,
    showSyncing(state) {
        return state < this.FINISHED_SYNCING;
    }
};


/**
 * @param {string} submissionId 
 * @param {ZeroContextServices} services
 */
async function loadSubmission(submissionId, services) {
    try {
        let submission = await services.forms.drafts.get(submissionId);
        if (!isEmpty(submission)) {
            return submission;
        }
        
        if (submissionId.startsWith("offline:")) {
            submission = await services.forms.drafts.get(submissionId.replace("offline:", ""));
        } else {
            submission = await services.forms.drafts.get(`offline:${submissionId}`);
        }

        return isEmpty(submission) ? null : submission;
    } catch (err) {
        console.error(err);
        return null;
    }
}


export default function FormConfirmation({ submissionId: initialSubmissionId, formType, onBackButtonClick, hasCustomConfirmation, customConfirmationProps, onReturnToEdit }) {
    const zeroContext = useZeroContext();
    const {isOffline} = zeroContext;
    const isLms = formType === FormType.LMS;
    const [syncState, setSyncState] = useState(isOffline ? SyncState.OFFLINE : SyncState.INITIAL_STATE);
    const isSyncing = SyncState.showSyncing(syncState);
    const isSyncError = syncState === SyncState.ERROR;
    const subscribe = useFormSubmissionEvents();
    const { subscribe: draftSyncSubscribe } = useDraftsCurrentlySyncing();
    const [submissionId, setSubmissionId] = useState(initialSubmissionId);
    const [submission, setSubmission] = useState(null);
    const { services } = useZeroContext();

    const loadSubmissionCallback = useCallback(() => {
        loadSubmission(submissionId, services).then(setSubmission);
    }, [submissionId, services]);

    useEffect(() => {
        if (submissionId) {
            loadSubmissionCallback();
        }
    }, [submissionId]);

    useEffect(() => {
        return subscribe("draftChange", ({detail}) => {
            const {currentId, newId} = detail;

            if (submissionId === currentId && newId !== null && newId !== currentId) {
                setSubmissionId(newId);
            }
        })
    }, [subscribe, submissionId]);

    useEffect(() => {
        const unsub = subscribe("syncError", ({detail}) => {
            const {id, offlineId} = detail;
            if (id === submissionId || offlineId === submissionId) {
                setSyncState(SyncState.ERROR);
                if (offlineId === null) {
                    setTimeout(() => loadSubmissionCallback(), 250);
                }
            }
        });

        return unsub;
    }, [subscribe, submissionId, loadSubmissionCallback]);

    useEffect(() => {
        const unsubscribe = draftSyncSubscribe(({detail}) => {
            const {id, action} = detail;
            if (id.replace('offline:', '') === submissionId.replace('offline:', '')) {
                setSyncState(currentSyncState => {
                    if (currentSyncState === SyncState.INITIAL_STATE && action === "add") {
                        return SyncState.IS_SYNCING;
                    } else if (currentSyncState === SyncState.IS_SYNCING && action === "remove") {
                        return SyncState.FINISHED_SYNCING;
                    }
                    return currentSyncState;
                })
            }
        })

        return () => {
            unsubscribe();
        }
    }, [draftSyncSubscribe, submissionId]);

    let headerText = "";
    if (isSyncing) {
        headerText = `Submitting ${isLms ? "Course" : "Form"}`;
    } else if (isSyncError) {
        headerText = "Could not sync submission";
    } else {
        headerText = isLms ? "Course Completed" : "Form Submitted"
    }

    /** @type {import("react").ReactNode} */
    let bodyText = "";
    if (syncState === SyncState.OFFLINE) {
        bodyText = (
            <>
                Your form has been saved successfully. It will be submitted automatically once your internet connection is restored.
                <br />
                <br />
                Have a wonderful rest of the day.
            </>
        );
    } else if (isSyncing) {
        bodyText = <>Please stay on this page until the sync is complete.</>;
    } else if (isSyncError) {
        bodyText = (
            <SubmissionSyncErrorAlert
                submission={submission}
                showTemplate
                onSyncClicked={() => {
                    setSyncState(SyncState.IS_SYNCING);
                }}
                onDelete={() => {
                    onBackButtonClick();
                }}
                onEditClicked={onReturnToEdit}
            />
        );
    } else {
        bodyText = <>
            { isLms
                ? "Your course has been completed successfully."
                : "Your form has been submitted successfully."
            }
            <br />
            <br />
            Have a wonderful rest of the day.
        </>
    }

    return (
        <div
            className="panel pad-all thin-border"
            style={{
                maxWidth: "850px",
                margin: "0 auto",
                minHeight: "390px",
                marginTop: "1rem",
            }}
        >
            { !isSyncing &&
                <button className="ButtonLink link-hover zero-light-blue " style={{height: 21}} onClick={onBackButtonClick}>
                    Back to {isLms ? "Courses" : "Forms"}
                </button>
            }
            { isSyncing &&
                <div style={{height: 21}}></div>
            }
            { (isSyncing || isSyncError || !hasCustomConfirmation) &&
                <>
                    <div className="panel-heading article text-center" style={{ height: "auto" }}>
                        <img style={{ height: "100px" }} src={ZERO_logo} alt="ZERO Logo" />
                    </div>
                    <div className="panel-heading article" style={{ height: "auto" }}>
                        <h3 className="directory teams header">{headerText}</h3>

                        { isSyncError ? (
                            <>{bodyText}</>
                        ) : (
                            <p className="zero-blue text-center" style={{ maxWidth: "350px", margin: "0 auto" }}>
                                {bodyText}
                            </p>
                        )}
                    </div>
                </>
            }
            { (!isSyncing && hasCustomConfirmation && !isSyncError) &&
                <ConfirmationFieldsRenderer {...customConfirmationProps} />
            }
            <SyncInProgressSpinner style={{ textAlign: "center", marginTop: "3rem" }} />
        </div>
    );
}
