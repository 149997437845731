// @ts-check

import GridExportButton from 'components/Shared/DataGrid/GridExportButton';
import { useFeedDataGridContext } from "./FeedDataGrid.context";

const EXPORTED_ROW_COUNT = 150;

export default function FeedDataGridExportButton() {
    const { gridRef, setRowCount } = useFeedDataGridContext();

    return (
        <GridExportButton
            gridRef={gridRef}
            setRowCount={setRowCount}
            exportRowCount={EXPORTED_ROW_COUNT}
            fileName="ZERO Posts Export"
        />
    );
}
