// @ts-check

import { useSubmissionDataGridContext } from './SubmissionDataGrid.context';
import GridExportButton from 'components/Shared/DataGrid/GridExportButton';

const EXPORTED_ROW_COUNT = 125;

export default function SubmissionDataGridExportButton() {
    const { gridRef, setRowCount } = useSubmissionDataGridContext();

    return (
        <GridExportButton
            gridRef={gridRef}
            setRowCount={setRowCount}
            exportRowCount={EXPORTED_ROW_COUNT}
            fileName="ZERO Forms Export"
        />
    );
}
